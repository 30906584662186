/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { Spacing } from '../themes';
import Column from './Column';
import Spacer from './Spacer';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
    margin: '0px 20px 20px 20px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  '& .browser-dialog': {
    margin: '0px 20px 20px 20px',
  },
}));

export interface DialogTitleProps {
  id: string;
  onClose: () => void;
}

export function BootstrapHeader(props: DialogTitleProps) {
  const { onClose } = props;
  return (
    <IconButton
      aria-label='close'
      onClick={onClose}
      style={{ marginRight: Spacing.xs, marginTop: Spacing.xs }}>
      <Svgs.ActionSmallCloseDefault />
    </IconButton>
  );
}

interface CustomizedDialogsProps extends React.PropsWithChildren {
  title: string | JSX.Element;
  titleStyle?: React.CSSProperties;
  handleSave: (data?: any) => void;
  data?: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  primaryButtonColor?:
    | 'accent'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'tertiary'
    | undefined;
  primaryButtonText?: string;
  secondaryButtonColor?:
    | 'accent'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'tertiary'
    | undefined;
  secondaryButtonVariant?:
    | 'link'
    | 'text'
    | 'tertiary'
    | 'outlined'
    | 'contained'
    | 'ghost'
    | 'activeGhost'
    | undefined;
  secondaryButtonText?: string;
  swapButtonFunctionality?: boolean;
}

const CustomizedDialogs: React.FC<CustomizedDialogsProps> = (props) => {
  const {
    title,
    titleStyle,
    handleSave,
    open,
    setOpen,
    data,
    primaryButtonColor,
    primaryButtonText,
    secondaryButtonColor,
    secondaryButtonVariant,
    secondaryButtonText,
    swapButtonFunctionality,
  } = props;
  const { isMobile } = useDeviceType();
  const buttonHeight = '38px';

  const handleClose = () => {
    setOpen(false);
  };
  const onSave = () => {
    if (data) {
      handleSave(data);
    } else {
      handleSave();
    }
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        style={{ borderRadius: '10px', padding: '20px' }}
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}>
        {!isMobile && <BootstrapHeader id='customized-dialog-title' onClose={handleClose} />}
        <Spacer height={isMobile ? 'xxs' : 'sm'} />
        <DialogContent>
          {typeof title === 'string' ? (
            isMobile ? (
              <div style={{ textAlign: 'center' }}>
                <Typography variant='p18Bold' color='secondary.main' style={titleStyle}>
                  {title}
                </Typography>
              </div>
            ) : (
              <Typography variant='p24Bold' color='secondary.main' style={titleStyle}>
                {title}
              </Typography>
            )
          ) : (
            title
          )}

          <Spacer height={isMobile ? 'xs' : 'sm'} />
          {props.children}
        </DialogContent>
        {isMobile ? (
          <DialogActions>
            <Column style={{ width: '100%' }}>
              <Button
                style={{ height: buttonHeight }}
                color='primary'
                autoFocus
                onClick={swapButtonFunctionality ? handleClose : onSave}>
                {primaryButtonText ? primaryButtonText : `Yes`}
              </Button>
              <Spacer height='xxs' />
              <Button
                style={{ height: buttonHeight }}
                color='secondary'
                variant='outlined'
                onClick={swapButtonFunctionality ? onSave : handleClose}>
                {secondaryButtonText ? secondaryButtonText : `Cancel`}
              </Button>
            </Column>
          </DialogActions>
        ) : (
          <DialogActions className='browser-dialog'>
            <Button
              style={{ height: buttonHeight }}
              color={secondaryButtonColor ?? 'primary'}
              variant={secondaryButtonVariant ?? 'outlined'}
              onClick={swapButtonFunctionality ? onSave : handleClose}>
              <Typography variant='p16'>
                {secondaryButtonText ? secondaryButtonText : `Cancel`}
              </Typography>
            </Button>
            <Button
              style={{ height: buttonHeight }}
              color={primaryButtonColor ?? 'primary'}
              autoFocus
              onClick={swapButtonFunctionality ? handleClose : onSave}>
              <Typography variant='p16'>{primaryButtonText ? primaryButtonText : `Yes`}</Typography>
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
};

export default CustomizedDialogs;
