import { Button, Card, Typography } from '@mui/material';
import { Svgs } from '../assets/svg';
import { appStore, googlePlay, logo3NSmall, qrCodeScanMe } from '../assets/png';
import { Column, Row, Spacer } from '../components';
import { Link, useNavigate } from 'react-router-dom';
import { SignUpLoginWizard, signUpLoginWizardKey } from '../services/signUpLogin';
import { useDeviceType } from '../hooks/useDeviceType';
import { mobileMargin } from '../App';
import { LicenseWizard, licenseWizardKey } from '../services/license';
import { SignUpProgressWizard, signUpProgressWizardKey } from '../services/signUpProgress';
import { useService } from '@aesop-fables/containr-react';
import { Api, Hooks } from '@3nickels/data-modules';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';

const Congrats = () => {
  const navigate = useNavigate();
  const organizationApi = useService<Api.OrganizationApi>(Api.ApiKeys.Organization);
  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);
  const signUpProgressWizard = useService<SignUpProgressWizard>(signUpProgressWizardKey);
  const licenseWizard = useService<LicenseWizard>(licenseWizardKey);
  const { isMobile } = useDeviceType();

  const organizationData = Hooks.useOrganizationData();

  const navToOnboarding = () => {
    signUpProgressWizard.setState(false);
    signUpLoginWizard.signUp();
    licenseWizard.clearCache();
    navigate('/');
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Column style={{ margin: isMobile ? mobileMargin : '15px 100px' }}>
        <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
          {organizationData ? (
            organizationData.logoUri ? (
              <img
                src={organizationApi.getLogo()}
                alt={organizationData?.name}
                style={{ height: '60%' }}
              />
            ) : (
              <Typography variant='p25Bold' color='secondary'>
                {organizationData?.name}
              </Typography>
            )
          ) : (
            <Card variant='ghost' />
          )}
          <Link style={{ height: '70%' }} to='/'>
            <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
          </Link>
        </Row>
        <Column style={{ alignItems: 'center' }}>
          <Typography variant='p45Bold' color='primary'>
            Congratulations!
          </Typography>
          <Spacer height='sm' />
          <Typography variant='h2' color='secondary'>
            We have sent you a confirmation email.
          </Typography>
          <Spacer height='sm' />
          <Typography variant='p18Bold' color='secondary'>
            Just download the 3Nickels mobile app, and log in to get started.
          </Typography>
          <Spacer height='sm' />
          <Button onClick={navToOnboarding} color='secondary' variant='contained'>
            Let's Get Started
          </Button>
          <Spacer height='md' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Column style={{ width: '50%', justifyContent: 'space-between' }}>
              <img
                src={qrCodeScanMe}
                alt='qr code'
                style={{
                  maxWidth: '115px',
                  minWidth: '50px',
                }}
              />
            </Column>
            <Column style={{ width: '40%', alignItems: 'center', justifyContent: 'center' }}>
              <a href='https://apps.apple.com/us/app/3nickels/id1544362343'>
                <img src={appStore} alt='appStore' height='50px' />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.guidedchoice.iiinickels'>
                <img src={googlePlay} alt='googlePlay' height='50px' />
              </a>
            </Column>
          </Row>
          <Spacer height='sm' />
          <Svgs.ArtThumbsUp />
          <Spacer height='lg' />
        </Column>
      </Column>
    </PageContentWithBackground>
  );
};

export default Congrats;
