import { Card, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../../../assets/svg';
import { Button } from '../../../../components/buttons/Button';
import { accentBase } from '../../../../themes/colors';
import { Domain, formatWholeDollars, Hooks } from '@3nickels/data-modules';
import { Colors } from '../../../../themes';
import { ChartPieVariableRadius } from '../../../../components/ChartPieVariableRadius';
import { Row } from '../../../../components';
import { AdviceDebtIncomePercentageTypeEnum } from './Types';
import { getAdjustedNetRealEstateIncome } from '../../../budget-details/Types';

interface IncomeChartSectionProps {
  noDebts: boolean;
  results: Domain.DebtIncomePercentageData | undefined;
}

export const IncomeChartSection: React.FC<IncomeChartSectionProps> = ({ noDebts, results }) => {
  const navigate = useNavigate();
  const { income, otherIncome, spouseIncome, spouseOtherIncome, unearnedIncome } =
    Hooks.useFinancialInfo() ?? {};
  const housing = Hooks.useCombinedRealEstateData();
  const realEstateIncome = housing.reduce(
    (partialSum, house) => partialSum + getAdjustedNetRealEstateIncome(house, 'annual'),
    0
  );

  const monthlyBeforeTaxIncome =
    ((income ?? 0) +
      (otherIncome ?? 0) +
      (spouseIncome ?? 0) +
      (spouseOtherIncome ?? 0) +
      (unearnedIncome ?? 0) +
      realEstateIncome) /
    12;
  const monthlyDebtPayments =
    (results?.debtToIncomeRatio ?? 0) * ((monthlyBeforeTaxIncome ?? 0) / 100);
  const leftoverIncome = (monthlyBeforeTaxIncome ?? 0) - monthlyDebtPayments;

  const data = [
    {
      id: 0,
      label: 'My Debt',
      value: (results?.debtToIncomeRatio ?? 0) * ((monthlyBeforeTaxIncome ?? 0) / 100),
    },
    { id: 1, label: 'My Leftover Income', value: leftoverIncome },
  ];
  const colors = [Colors.accentBase, Colors.secondaryBase];
  const debtSupported =
    (results?.debtToIncomeRatio ?? 0) < AdviceDebtIncomePercentageTypeEnum.DebtToIncome;

  return noDebts ? (
    <Card className='no-debts-how-much-debt'>
      <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
        <Svgs.ArtCreditCard3 style={{ width: '25%' }} />
        <Typography variant='p16' sx={{ color: accentBase, width: '45%' }}>
          Please add a debt in the Accounts section before using this tool.
        </Typography>
        <Button
          label={'Go to Accounts'}
          color='primary'
          style={{ width: '25%' }}
          onClick={() => navigate('/account-details')}
        />
      </Grid>
    </Card>
  ) : results ? (
    <Grid
      item
      container
      direction='row'
      sx={{
        height: '210px',
        alignItems: 'center',
      }}>
      <Row
        style={{
          height: '210px',
          alignItems: 'center',
          justfyContent: 'space-between',
        }}>
        <ChartPieVariableRadius
          data={data.map((slice) => ({
            x: slice.label ?? '',
            y: slice.value ?? 0,
            color: colors[slice.id],
          }))}
          padAngle={6}
          staticRadius={140}
          innerRadius={80}
          innerLabelStyle={[
            {
              fill: !debtSupported ? Colors.accentBase : Colors.secondaryBase,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
            },
            {},
            { fill: Colors.primaryBase, fontFamily: 'Roboto' },
          ]}
          tooltipText={({ slice }) => [
            `${slice.data.xName}:`,
            '\u200B',
            `${formatWholeDollars(slice.value)}/mo`,
          ]}
          tooltipStyles={{
            fontSize: 22,
          }}
        />

        <Grid ml={6} container direction='column'>
          <Grid container alignItems='center'>
            <Grid
              mr='15px'
              style={{ height: 20, width: 20, backgroundColor: colors[0], borderRadius: 10 }}
            />
            <Typography sx={{ display: 'inline' }} color='secondary' variant='h3'>
              {data[0].label}
            </Typography>
          </Grid>
          <Grid mt={2} container alignItems='center'>
            <Grid
              mr='15px'
              style={{ height: 20, width: 20, backgroundColor: colors[1], borderRadius: 10 }}
            />
            <Typography sx={{ display: 'inline' }} color='secondary' variant='h3'>
              {data[1].label}
            </Typography>
          </Grid>
        </Grid>
      </Row>
    </Grid>
  ) : (
    <></>
  );
};
