import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Box, Grid, Link, Typography } from '@mui/material';
import GroupPanels, { Group } from '../../components/GroupPanels';
import { Svgs } from '../../assets/svg';
import { Spacing } from '../../themes';
import { useAvailableProducts } from '../../hooks/pricebook/useAvailableProducts';
import { PricebookBundle } from '../../models/PricebookData';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from './AccountSettingsLayout';
import { ConfirmDeleteAccountModal, WarningDeleteAccountModal } from './DeleteAccountModals';

export const resolveShowManagePayments = (
  orgPaysPricebook: PricebookBundle | undefined,
  addOns: boolean,
  orgOnlyOffersFreePricebook: boolean,
  subscriptionInfoData: Domain.SubscriptionInfo[] | undefined
) => {
  const orgWithNoAddOns = orgPaysPricebook && !addOns;
  const orgWithAddOns = orgPaysPricebook && addOns;
  const noSubscriptionHistory = !subscriptionInfoData || subscriptionInfoData.length === 0;
  if (orgWithNoAddOns) {
    return false;
  } else if (orgOnlyOffersFreePricebook) {
    return false;
  } else if (noSubscriptionHistory && !orgWithAddOns) {
    return false;
  } else {
    return true;
  }
};

const AccountSettingsView: React.FC = () => {
  const { t } = useTranslation();
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const authMethods = Hooks.useTwoFactorMethods();
  const { orgOnlyOffersFreePricebook, orgPaysPricebook } = useAvailableProducts();
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const addOns = false;
  const showManagePayments = resolveShowManagePayments(
    orgPaysPricebook,
    addOns,
    orgOnlyOffersFreePricebook,
    subscriptionInfoData
  );
  const showPasswordAnd2fa = useMemo(() => (authMethods?.length ?? 0) > 0, [authMethods]);

  const accountSettingsOptions: Group[] = [];

  accountSettingsOptions.push(
    {
      textKey: 'ManageSubscription',
      subTextKey: 'ManageSubscriptionSubtext',
      icon: <Svgs.DisplaySmallCustomCreditCard2 />,
      url: '/settings/manage-subscription',
    },
    {
      textKey: 'Alerts',
      subTextKey: 'AlertsSubtext',
      icon: <Svgs.DisplaySmallCustomAlerts />,
      url: '/settings/alerts',
    }
  );
  if (showPasswordAnd2fa) {
    accountSettingsOptions.push(
      {
        textKey: 'UpdatePassword',
        subTextKey: 'UpdatePasswordSubtext',
        icon: <Svgs.DisplaySmallCustomLock />,
        url: '/settings/update-password',
      },
      {
        textKey: 'TwoFactorAuth',
        subTextKey: 'TwoFactorAuthenticationSubtext',
        icon: <Svgs.DisplaySmallCustomShield />,
        url: '/settings/two-factor-authentication',
      }
    );
  }

  accountSettingsOptions.push(
    {
      textKey: 'Legal',
      subTextKey: 'LegalSubtext',
      icon: <Svgs.DisplaySmallCustomScale />,
      url: '/settings/legal',
    },
    {
      textKey: 'IconAuthors',
      subTextKey: 'IconAuthorsSubtext',
      icon: <Svgs.DisplaySmallCustomBook />,
      url: '/settings/icon-authors',
    }
  );

  if (showManagePayments) {
    accountSettingsOptions.splice(1, 0, {
      textKey: 'ManagePayments',
      subTextKey: 'ManagePaymentsSubtext',
      icon: <Svgs.DisplaySmallCustomWallet />,
      url: '/settings/manage-payments',
    });
  }

  return (
    <Box>
      <WarningDeleteAccountModal
        modalOpen={warningModalOpen}
        setModalOpen={setWarningModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
      />
      <ConfirmDeleteAccountModal modalOpen={confirmModalOpen} setModalOpen={setConfirmModalOpen} />
      <Grid container flexDirection='column'>
        <Typography variant='p40Bold' component='h1' color='primary'>
          {t('Settings')}
        </Typography>
        <Grid container pt={Spacing.xxxs}>
          <GroupPanels variant='Popover' groups={accountSettingsOptions} />
        </Grid>
        <Grid
          container
          mt='50px'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'>
          <Typography variant='p16' color='secondary'>
            {t('LookingToCloseYourAccount')}
          </Typography>
          <Link sx={{ textDecoration: 'none ' }}>
            <Typography
              mt='20px'
              fontFamily='Montserrat'
              fontWeight='600'
              color='error.main'
              variant='p14'
              onClick={() => setWarningModalOpen(true)}>
              {t('RemoveMyAccount')}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: false,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(AccountSettingsView, meta);
