/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import { useNavigate, Link as DomLink } from 'react-router-dom';
import { Column, Row, Spacer } from '../components';
import { logo3NSmall, universityAdvisor } from '../assets/png';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import LearnMoreAdviceDrawer from './LearnMoreAdviceDrawer';
import LearnMoreAdviceAndThreeNickelsUDrawer from './LearnMoreAdviceAndThreeNickelsUDrawer';
import { useService } from '@aesop-fables/containr-react';
import { useSubject } from '@aesop-fables/scrinium';
import { AvailablePricebooks } from '../data/pricebook';
import { PricebookBundle } from '../models/PricebookData';
import { mobileMargin } from '../App';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import LearnMoreGuidedChoiceDrawer from './LearnMoreGuidedChoiceDrawer';
import { LicenseData } from '../models/LicenseData';
import React from 'react';
import { LicenseWizard, licenseWizardKey } from '../services/license';
import { SignUpProgressWizard, signUpProgressWizardKey } from '../services/signUpProgress';
import { ApiKeys } from '../api/apis/ApiKeys';
import { PricebookApi } from '../api/apis/PricebookApi';
import { Api, Hooks } from '@3nickels/data-modules';
import { Margins } from '../themes';
import BulletedList from '../components/BulletedList';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { API_URL, domain } from '../configuration/Environment';
import { useTermsDocs } from '../hooks/useTermsDocs';
import InfoCard from '../components/InfoCard';
import { useTranslation } from 'react-i18next';

const Welcome = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const { t } = useTranslation();
  const signUpProgressWizard = useService<SignUpProgressWizard>(signUpProgressWizardKey);
  const organizationData = Hooks.useOrganizationData();
  const organizationMetadata = Hooks.useOrganizationMetadata();

  const licenseWizard = useService<LicenseWizard>(licenseWizardKey);

  const docs = useTermsDocs();
  const pricebookData = useSubject(AvailablePricebooks);

  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const pricebookApi = useService<PricebookApi>(ApiKeys.Pricebook);

  const [tosLink, setTosLink] = useState<string>();
  const [memberPaidOptions, setMemberPaidOptions] = useState<PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<PricebookBundle[]>([]);
  const [selectedPricebook, setSelectedPricebook] = useState<PricebookBundle | undefined>(
    undefined
  );
  const memOptions = memberPaidOptions.length;
  const orgOptions = orgPaidOptions.length;
  const isOnlyOneOption = memOptions === 1 || orgOptions === 1; // only one of these should be >0 at once

  const promotion = pricebookData?.filter((bundle) =>
    bundle.prices.find((price) => price.introductionPeriodInDays > 1)
  );
  const introPeriod = promotion
    ?.map((bundle) => bundle.prices)
    .map((price) => price.map((arr) => arr.introductionPeriodInDays).find((promo) => promo > 1));
  const introPeriodInMonths = introPeriod?.map((promo) => Math.floor((promo ?? 0) / 30));
  const specialPromoOffer = pricebookData?.find(
    (p) => p.pricebook.metadata?.subHeader === 'Special Promo Offer!*'
  );
  const signupPageText = organizationMetadata?.signupPageText;

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  useEffect(() => {
    const getTermsLink = async () => {
      if (docs) {
        const termsObject = docs.find((doc) => doc.docDescription === 'Terms of Service');
        if (!termsObject) {
          return;
        }
        const url = termsApi.getDocString(termsObject.id);
        setTosLink(url);
      }
    };
    getTermsLink();
  }, [docs, termsApi]);

  useEffect(() => {
    const getPricebookOptions = async () => {
      if (pricebookData) {
        const member = pricebookData.filter((bundle) => bundle.pricebook.paidBy === 'USER');
        const org = pricebookData.filter((bundle) => bundle.pricebook.paidBy === 'ORG');
        setMemberPaidOptions(member);
        setOrgPaidOptions(org);
      }
    };

    getPricebookOptions();
  }, [pricebookData]);

  useEffect(() => {
    if (!memberPaidOptions || !orgPaidOptions) {
      return;
    }
    if (isOnlyOneOption) {
      if (memOptions === 1) {
        setSelectedPricebook(memberPaidOptions[0]);
      } else {
        setSelectedPricebook(orgPaidOptions[0]);
      }
    }
  }, [isOnlyOneOption, memOptions, memberPaidOptions, orgPaidOptions]);

  const onSubmit = async (values: LicenseData) => {
    signUpProgressWizard.setState(true);
    if (!selectedPricebook) {
      return;
    }

    try {
      const licenseData: LicenseData = {
        ...values,
        paymentParty: selectedPricebook.pricebook.paidBy,
        package: selectedPricebook.pricebook.productType,
      };
      licenseWizard.setState({ licenseData, pricebook: selectedPricebook });
      await pricebookApi.selectPricebook(selectedPricebook.pricebook.id);
      if (selectedPricebook.pricebook.productType === 'GUIDED_CHOICE') {
        window.location.href = `https://app.${domain}/retail/setup-account`;
      } else {
        navigate('/create-account');
      }
    } catch (error) {
      console.error('Failed to select pricebook with id: ' + selectedPricebook.pricebook.id);
    }
  };

  const onSetUpTime = () => {
    window
      .open(
        'https://outlook.office365.com/owa/calendar/beta@guidedchoice.com/bookings/s/ag4FCPYR0USkWp4Rf49o6Q2',
        '_blank'
      )
      ?.focus();
  };

  const onCancel = () => {
    window.location.replace('https://3nickels.com');
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column style={{ margin: isTablet || isMobile ? mobileMargin : '15px 100px' }}>
              <Row
                style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
                {organizationData ? (
                  organizationData.logoUri ? (
                    <img
                      src={`${API_URL}organization/logo`}
                      alt={organizationData?.name}
                      style={{ height: '60%' }}
                    />
                  ) : (
                    <Typography variant='p25Bold' color='secondary'>
                      {organizationData?.name}
                    </Typography>
                  )
                ) : (
                  <Card variant='ghost' />
                )}
                {(!organizationData?.logoUri || !isMobile) && (
                  <DomLink style={{ height: '70%' }} to='/'>
                    <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
                  </DomLink>
                )}
              </Row>
              <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Column style={{ width: isMobile || isTablet ? '100%' : '66%' }}>
                  <Typography variant='h1' color='secondary'>
                    Sign Up: Welcome!
                  </Typography>
                  {signupPageText && (
                    <Column>
                      <Spacer height='xs' />
                      <Typography variant='p16' color='secondary'>
                        {signupPageText}
                      </Typography>
                    </Column>
                  )}
                  <Spacer height='xs' />
                  <Link variant='h3' href='/'>
                    Already have an account? Log in
                  </Link>
                  {(isMobile || isTablet) && (
                    <>
                      <Spacer height='xs' />
                      <InfoCard
                        onClick={onSetUpTime}
                        icon={<img src={universityAdvisor} width='60px' />}>
                        <Typography color='secondary' variant='p16' maxWidth='185px' ml='20px'>
                          {t('HaveAnyQuestions?SetUpATime')}
                        </Typography>
                      </InfoCard>
                    </>
                  )}
                </Column>
                {!isMobile && !isTablet && (
                  <InfoCard
                    onClick={onSetUpTime}
                    icon={<img src={universityAdvisor} width='60px' />}>
                    <Typography color='secondary' variant='p16' maxWidth='185px' ml='20px'>
                      {t('HaveAnyQuestions?SetUpATime')}
                    </Typography>
                  </InfoCard>
                )}
              </Row>
              <Spacer height='xs' />
              <ProductOptions
                memberPaidOptions={memberPaidOptions}
                orgPaidOptions={orgPaidOptions}
                pricebookMetadata={pricebookMetadata}
                selectedPricebook={selectedPricebook}
                setSelectedPricebook={
                  memOptions > 1 || orgOptions > 1 ? setSelectedPricebook : undefined
                }
              />
              <Spacer height='xxxs' />
              {!specialPromoOffer && (
                <>
                  <Typography className='link-wrapper' variant='p14' color='secondary'>
                    Please see our{' '}
                    <Link href={tosLink} target='_blank'>
                      Terms of Service
                    </Link>{' '}
                    {memberPaidOptions.length > 0 && (
                      <>
                        and{' '}
                        <Link href='/cancellation-policy' target='_blank'>
                          Cancellation and Refund Policy
                        </Link>{' '}
                      </>
                    )}
                    for details. Have any questions? We’re available M-F 8am-5pm PST at (800)
                    774-7459, or anytime at{' '}
                    <Link href={'mailto:help@3nickels.com'}>help@3nickels.com</Link>.
                  </Typography>
                </>
              )}
              {introPeriodInMonths?.length ?? 0 > 1 ? (
                <>
                  {!isTablet && <Spacer height='xxxs' />}
                  <Typography variant='p14' color='secondary'>
                    * After the period of free access ends, Advice subscription auto-renews at
                    $14.99/month
                  </Typography>
                </>
              ) : (
                introPeriodInMonths?.map((x, index) => (
                  <>
                    {!isTablet && <Spacer height='xxxs' />}
                    <Typography key={index} variant='p14' color='secondary'>
                      * After {x} months, Advice subscription auto-renews at $14.99/month
                    </Typography>
                  </>
                ))
              )}
              {specialPromoOffer && (
                <>
                  <Typography className='link-wrapper' variant='p14' color='secondary'>
                    * Terms and conditions apply. Promotional pricing discount is off standard
                    monthly pricing. We reserve the right to validate eligibility and to modify or
                    end this promotional offer at any time. Please see our{' '}
                    <Link href={tosLink} target='_blank'>
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link href='/cancellation-policy' target='_blank'>
                      Cancellation and Refund Policy
                    </Link>{' '}
                    for full details.
                  </Typography>
                </>
              )}
              {isTablet || isMobile ? (
                <>
                  <Spacer height='sm' />
                  <Column style={{ justifyContent: 'space-between' }}>
                    <Button
                      disabled={isOnlyOneOption ? false : !selectedPricebook}
                      style={{ width: '100%' }}
                      onClick={handleSubmit}
                      color='secondary'>
                      Sign Up
                    </Button>
                    <Spacer height='xs' />
                    <Button
                      style={{ width: '100%' }}
                      onClick={onCancel}
                      variant='outlined'
                      color='secondary'>
                      Cancel
                    </Button>
                  </Column>
                </>
              ) : (
                <>
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Button
                      style={{ minWidth: 'fit-content' }}
                      className='md'
                      onClick={onCancel}
                      variant='outlined'
                      color='secondary'>
                      Cancel
                    </Button>
                    <Button
                      disabled={isOnlyOneOption ? false : !selectedPricebook}
                      style={{ minWidth: 'fit-content' }}
                      className='md'
                      onClick={handleSubmit}
                      color='secondary'>
                      Sign Up
                    </Button>
                  </Row>
                </>
              )}

              <Spacer height='lg' />
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

interface ProductOptionsProps {
  memberPaidOptions: PricebookBundle[];
  orgPaidOptions: PricebookBundle[];
  pricebookMetadata: any;
  selectedPricebook?: PricebookBundle;
  setSelectedPricebook?: React.Dispatch<React.SetStateAction<PricebookBundle | undefined>>;
}

export const ProductOptions: React.FC<ProductOptionsProps> = ({
  memberPaidOptions,
  orgPaidOptions,
  pricebookMetadata,
  selectedPricebook,
  setSelectedPricebook,
}) => {
  const { isTablet, isMobile } = useDeviceType();

  return (
    <>
      {memberPaidOptions.length > 0 &&
        (isTablet || isMobile ? (
          <Column style={{ justifyContent: 'space-between' }}>
            {memberPaidOptions.map((bundle) => (
              <React.Fragment key={bundle.pricebook.id}>
                <BundleCard
                  key={bundle.pricebook.id}
                  pricebookBundle={bundle}
                  pricebookMetadata={pricebookMetadata}
                  selected={bundle === selectedPricebook}
                  setSelectedPricebook={setSelectedPricebook}
                />
                <Spacer height='sm' />
              </React.Fragment>
            ))}
          </Column>
        ) : (
          <Row style={{ flexWrap: 'wrap' }}>
            {memberPaidOptions.map((bundle, index) => (
              <BundleCard
                style={{
                  marginBottom: '20px',
                  marginRight:
                    isTablet || isMobile || index + 1 >= memberPaidOptions.length ? '' : '100px',
                }}
                key={bundle.pricebook.id}
                pricebookBundle={bundle}
                pricebookMetadata={pricebookMetadata}
                selected={bundle === selectedPricebook}
                setSelectedPricebook={setSelectedPricebook}
              />
            ))}
          </Row>
        ))}
      {orgPaidOptions.length > 0 &&
        (isTablet || isMobile ? (
          <Column style={{ justifyContent: 'space-between' }}>
            {orgPaidOptions.map((bundle) => (
              <React.Fragment key={bundle.pricebook.id}>
                <BundleCard
                  pricebookBundle={bundle}
                  pricebookMetadata={pricebookMetadata}
                  selected={bundle === selectedPricebook}
                  setSelectedPricebook={setSelectedPricebook}
                />
                <Spacer height='sm' />
              </React.Fragment>
            ))}
          </Column>
        ) : (
          <Row style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {orgPaidOptions.map((bundle, index) => (
              <React.Fragment key={bundle.pricebook.id}>
                <BundleCard
                  style={{
                    marginBottom: '20px',
                    marginRight:
                      isTablet || isMobile || index + 1 >= memberPaidOptions.length ? '' : '100px',
                  }}
                  pricebookBundle={bundle}
                  pricebookMetadata={pricebookMetadata}
                  selected={bundle === selectedPricebook}
                  setSelectedPricebook={setSelectedPricebook}
                />
                <Spacer height='sm' />
              </React.Fragment>
            ))}
          </Row>
        ))}
    </>
  );
};

interface BundleCardProps {
  pricebookBundle: PricebookBundle;
  pricebookMetadata: any;
  selected: boolean;
  current?: boolean;
  setSelectedPricebook?: React.Dispatch<React.SetStateAction<PricebookBundle | undefined>>;
  style?: React.CSSProperties;
}

export const BundleCard: React.FC<BundleCardProps> = ({
  pricebookBundle,
  pricebookMetadata,
  selected,
  current,
  setSelectedPricebook,
  style,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isMobile, isTablet } = useDeviceType();
  const selectedStyle = selected && setSelectedPricebook;
  const pricebook = pricebookBundle.prices[0];
  const freeTrial30Day = pricebookBundle.pricebook.metadata?.freeTrial30Day === 'true';
  const orgPays = pricebookBundle.pricebook.paidBy === 'ORG';
  const subHeader = pricebookBundle.pricebook.metadata?.subHeader;
  const price = pricebook.price / 100.0;

  const metadata = useMemo(() => {
    if (
      typeof pricebookMetadata === 'undefined' ||
      Object.keys(pricebookMetadata ?? {}).length === 0
    )
      return;
    return pricebookMetadata.find((metadata: any) => metadata.id === pricebookBundle.pricebook.id);
  }, [pricebookBundle, pricebookMetadata]);

  return (
    <>
      <Card
        style={{
          width: isTablet || isMobile ? '100%' : '45%',
          maxWidth: isTablet || isMobile ? '' : '538px',
          minWidth: isTablet || isMobile ? undefined : '430px',
          ...style,
        }}
        variant={selectedStyle ? 'selected' : undefined}
        color='primary'>
        {pricebookBundle.pricebook.productType === 'NICKELS' && (
          <LearnMoreAdviceDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
            pricebookMetadata={metadata}
          />
        )}
        {pricebookBundle.pricebook.productType === 'NICKELSU' && (
          <LearnMoreAdviceAndThreeNickelsUDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
            pricebookMetadata={metadata}
          />
        )}
        {pricebookBundle.pricebook.productType === 'GUIDED_CHOICE' && (
          <LearnMoreGuidedChoiceDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
            pricebookMetadata={metadata}
          />
        )}
        <CardContent className='packageCard' style={{ height: '100%' }}>
          {isTablet || isMobile ? (
            <Column style={{ alignItems: 'center' }}>
              {selectedStyle ? (
                <>
                  <Svgs.DisplayLargeCustomCheckmark
                    style={{ width: '125px', height: 'auto', padding: '32px 0px' }}
                  />
                </>
              ) : (
                <>
                  <img src={pricebookBundle.pricebook.logoUrl} alt='Product Logo' height='50px' />
                  <Spacer height='sm' />
                  <img
                    src={pricebookBundle.pricebook.artUrl}
                    alt='Product Art'
                    style={{ height: '90px' }}
                  />
                  <Spacer height='xs' />
                </>
              )}
              <Typography variant='p20SemiBold' color={selectedStyle ? `white` : `secondary`}>
                {pricebookBundle.pricebook.pricebookName}
              </Typography>
              {subHeader && (
                <>
                  <Spacer height='xxs' />
                  <Typography variant='p14Bold' color='primary'>
                    {subHeader}
                  </Typography>
                </>
              )}
              <Spacer height='xxs' />
              <Column style={{ justifyContent: 'space-between', width: '100%' }}>
                <BulletedList
                  children={pricebookBundle.pricebook.description}
                  style={{ ...Margins.mt_xxxs }}
                  variant='body1'
                  color={selectedStyle ? `white` : `secondary`}
                />
              </Column>
              <Spacer height='xs' />
              <Card variant={selectedStyle ? 'selected' : 'innerCard'} style={{ width: '100%' }}>
                <CardContent className='centered-inner'>
                  <Column style={{ alignItems: 'center' }}>
                    {metadata ? (
                      <Typography variant='p16Bold' color='primary' textAlign='center'>
                        {metadata.innerCard}
                      </Typography>
                    ) : orgPays ? (
                      <Typography variant='p16Bold' color='primary'>
                        paid by your <br /> organization
                      </Typography>
                    ) : (
                      <>
                        <Row
                          key={pricebook.id}
                          style={{ alignItems: 'baseline', justifyContent: 'center' }}>
                          <Typography
                            variant='p20SemiBold'
                            color={selectedStyle ? `white` : `secondary`}>
                            {freeTrial30Day ? '30 days free' : `$${price}`}
                            {pricebook.intervalType !== 'NONE' && !freeTrial30Day && (
                              <Typography variant='h3'>/{pricebook.intervalType}</Typography>
                            )}
                          </Typography>
                        </Row>
                        <Spacer height='xxxs' />
                        <Typography
                          variant='p12SemiBold'
                          color={selectedStyle ? `white` : `primary`}>
                          {freeTrial30Day
                            ? `then $14.99/mo`
                            : price === 0
                            ? `free`
                            : pricebook.intervalType === 'MONTH'
                            ? `auto-renews monthly`
                            : pricebook.intervalType === 'YEAR'
                            ? `auto-renews annually`
                            : `one-time payment`}
                        </Typography>
                      </>
                    )}
                  </Column>
                </CardContent>
              </Card>
              {setSelectedPricebook && (
                <>
                  <Spacer height='xs' />
                  <Button
                    disabled={current}
                    onClick={() => {
                      if (setSelectedPricebook) {
                        setSelectedPricebook((prevState) =>
                          prevState !== pricebookBundle ? pricebookBundle : undefined
                        );
                      }
                    }}
                    style={{ width: '100%' }}
                    color={'primary'}
                    variant={selected ? `outlined` : undefined}>
                    {selected ? `Selected` : `Select`}
                  </Button>
                </>
              )}
              <Spacer height='xs' />
              <Button
                onClick={() => setDrawerOpen(true)}
                style={{ width: '100%' }}
                color='primary'
                className={selectedStyle ? 'activeLearnMore' : 'learnMore'}>
                Learn more
              </Button>
            </Column>
          ) : (
            <Row style={{ justifyContent: 'space-between', height: '100%' }}>
              {/* Left side */}
              <Column style={{ width: '45%', justifyContent: 'space-between' }}>
                <Column>
                  <Typography variant='p20SemiBold' color={selectedStyle ? `white` : `secondary`}>
                    {pricebookBundle.pricebook.pricebookName}
                  </Typography>
                  {subHeader && (
                    <>
                      <Spacer height='xxs' />
                      <Typography variant='p14Bold' color='primary'>
                        {subHeader}
                      </Typography>
                    </>
                  )}
                  <Spacer height='s15' />
                  <BulletedList
                    children={pricebookBundle.pricebook.description}
                    variant='p14'
                    color={selectedStyle ? `white` : `secondary`}
                  />
                </Column>
                <Spacer height='xs' />
                <Column>
                  {setSelectedPricebook && (
                    <Button
                      disabled={current}
                      onClick={() => {
                        if (setSelectedPricebook) {
                          setSelectedPricebook((prevState) =>
                            prevState !== pricebookBundle ? pricebookBundle : undefined
                          );
                        }
                      }}
                      color={'primary'}
                      variant={selected ? `outlined` : undefined}>
                      {selected ? `Selected` : `Select`}
                    </Button>
                  )}
                  <Spacer height='xxxs' />
                  <Button
                    onClick={() => setDrawerOpen(true)}
                    color='primary'
                    className={selectedStyle ? 'activeLearnMore' : 'learnMore'}>
                    Learn more
                  </Button>
                </Column>
              </Column>
              {/* Right side */}
              <Column
                style={{ width: '50%', alignItems: 'center', justifyContent: 'space-between' }}>
                {selectedStyle ? (
                  <Svgs.DisplayLargeCustomCheckmark
                    style={{ width: '112px', height: 'auto', flexGrow: 1 }}
                  />
                ) : (
                  <>
                    <img
                      src={pricebookBundle.pricebook.logoUrl}
                      alt='Product Logo'
                      height='auto'
                      width='100%'
                    />
                    <img src={pricebookBundle.pricebook.artUrl} alt='Product Art' height='90' />
                  </>
                )}
                <Card
                  variant={selectedStyle ? 'selected' : 'innerCard'}
                  style={{
                    width: '70%',
                    minWidth: 'fit-content',
                  }}>
                  <CardContent className='centered-inner'>
                    <Column style={{ alignItems: 'center' }}>
                      {metadata ? (
                        <Typography variant='p16Bold' color='primary' textAlign='center'>
                          {metadata.innerCard}
                        </Typography>
                      ) : orgPays ? (
                        <Typography variant='p16Bold' color='primary'>
                          paid by your <br /> organization
                        </Typography>
                      ) : (
                        <>
                          <Row
                            key={pricebook.id}
                            style={{ alignItems: 'baseline', justifyContent: 'center' }}>
                            <Typography
                              variant='p20SemiBold'
                              color={selectedStyle ? `white` : `secondary`}>
                              {freeTrial30Day ? '30 days free' : `$${price}`}
                              {pricebook.intervalType !== 'NONE' && !freeTrial30Day && (
                                <Typography variant='h3'>/{pricebook.intervalType}</Typography>
                              )}
                            </Typography>
                          </Row>
                          <Spacer height='xxxs' />
                          <Typography
                            variant='p12SemiBold'
                            color={selectedStyle ? `white` : `primary`}>
                            {freeTrial30Day
                              ? `then $14.99/mo`
                              : price === 0
                              ? `free`
                              : pricebook.intervalType === 'MONTH'
                              ? `auto-renews monthly`
                              : pricebook.intervalType === 'YEAR'
                              ? `auto-renews annually`
                              : `one-time payment`}
                          </Typography>
                        </>
                      )}
                    </Column>
                  </CardContent>
                </Card>
              </Column>
            </Row>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Welcome;
