import { Box } from '@mui/material';
import React from 'react';
import { useLifestyleProductsByCategory } from '../../../hooks/useLifestyleProductsByCategory';
import { snowscape } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import LifestyleToolsCard from '../LifestyleToolsCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import TaxBenefitsDonationsCard from './cards/TaxBenefitsDonationsCard';
import IntroToGivingCard from './cards/IntroToGivingCard';
import SearchForCharityCard from './cards/SearchForCharityCard';
import GiveToYourChurchCard from './cards/GiveToYourChurchCard';

const GiftsToolsStartView: React.FC = () => {
  const lifestyleProducts = useLifestyleProductsByCategory();

  const calculators: Group[] = [
    {
      textKey: 'tax-benefits-of-donations',
      url: 'tax-benefits-of-donations',
      content: <TaxBenefitsDonationsCard />,
    },
  ];

  const resources: Group[] = [
    {
      textKey: 'introduction-to-giving',
      url: 'introduction-to-giving',
      content: <IntroToGivingCard />,
    },
    {
      textKey: 'search-for-charity',
      url: 'search-for-charity',
      content: <SearchForCharityCard />,
    },
    {
      textKey: 'give-to-your-church',
      url: 'give-to-your-church',
      content: <GiveToYourChurchCard />,
    },
  ];

  const explore: Group[] =
    (lifestyleProducts?.length ?? 0) > 0
      ? [
          {
            textKey: 'lifestyle',
            url: 'lifestyle',
            content: <LifestyleToolsCard />,
          },
        ]
      : [];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplaySmallCustomGiftsPrimary />}
        label='Gifts'
        calculators={calculators}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={snowscape} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(GiftsToolsStartView, meta);
