import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Api, Data } from '@3nickels/data-modules';
import { SvgIcon, Tab, Toolbar, AppBar, Stack, Badge, Box } from '@mui/material';
import { type BoxProps } from '@mui/material/Box';
import { type BadgeProps } from '@mui/material';
import { Notifications, Person } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { AlertMenu } from '../../components/header/AlertMenu';
import { type Profile } from '../../components/header/ProfileMenu';
import { Colors, Percentages } from '../../themes';
import Images from '../../components/images';
import { Tabs } from '../../components/Tabs';
import { useContentView } from './ContentViewContext';
import { ToastBar } from './ToastBar';
import { DataCache, injectDataCache, ISubject, useSubject } from '@aesop-fables/scrinium';
import { map, Observable } from 'rxjs';

export type HeaderProps = {
  elevation?: number;
  children?: React.ReactNode;
  leftChildren?: React.ReactNode;
} & BoxProps;

class PersonProfileProjection implements ISubject<Profile> {
  constructor(@injectDataCache(Data.People.personStorageKey) private readonly cache: DataCache<Data.People.PersonCompartments>) {}
  
  createObservable(): Observable<Profile> {
    return this.cache.observe$<Api.PersonRest>('person').pipe(
      map((personData) => ({
        firstName:
      (personData?.preferredName?.length ?? 0) > 0
        ? personData?.preferredName ?? ''
        : personData?.firstName ?? '',
      })),
    );
  }
}

function useProfile() {
  const profile = useSubject<Profile>(PersonProfileProjection);
  return profile ?? { firstName: '' };
}


export const Header: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const [alertAnchorEl, setAlertAnchorEl] = React.useState<undefined | HTMLElement>(undefined);

  const {
    header: { hideProfile, selectedTab },
  } = useContentView();

  const handleAlerts = (event: React.MouseEvent<HTMLElement>) => {
    setAlertAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const cascadedProps: HeaderProps = {
    height: 'min-content',
    width: Percentages.Full,
    gridArea: 'header',
    gridColumn: 'span 2',
    ...props,
  };

  return (
    <Box {...cascadedProps}>
      <AppBar
        className='app-header'
        sx={{ backgroundColor: Colors.tertiaryBase }}
        elevation={props.elevation ?? 1}
        position='fixed'>
        <Toolbar>
          <Stack flexDirection='row' justifyContent='flex-start' p={4}>
            <Link to='/'>
              <SvgIcon inheritViewBox component={Images.NickelsLogoSvg} fontSize='large' />
            </Link>
          </Stack>
          {props.leftChildren && props.leftChildren}
          <Stack flexDirection='row' justifyContent='flex-end' marginLeft='auto' minHeight='50px'>
            {!props.children && (
              <Tabs value={selectedTab ?? false} variant='standard'>
                <Tab
                  key={'alerts'}
                  value='alerts'
                  label='Alerts'
                  icon={
                    <StyledBadge
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={[].length} // notifications length
                      color='error'>
                      <Notifications />
                    </StyledBadge>
                  }
                  iconPosition='end'
                  onClick={handleAlerts}
                />
                {!hideProfile && (
                  <Tab
                    key={'profile'}
                    value='profile'
                    label={profile.firstName}
                    icon={<Person />}
                    iconPosition='end'
                    onClick={handleProfile}
                  />
                )}
              </Tabs>
            )}
            {props.children && props.children}
          </Stack>
        </Toolbar>
        <AlertMenu notifications={[]} anchorEl={alertAnchorEl} setAnchorEl={setAlertAnchorEl} />
      </AppBar>
      <ToastBar />
    </Box>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    minWidth: '14px',
    height: '14px',
    fontSize: '10px',
    fontWeight: 'bold',
    right: 2,
    top: 5,
    backgroundColor: '#9a5238',
  },
}));
