import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { useAvailableProducts } from '../../../hooks/pricebook/useAvailableProducts';
import {
  DefaultPricebook,
  PricebookBundle,
  PricebookProductTypeEnum,
} from '../../../models/PricebookData';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import { getSubscriptionStatus } from '../manage-payments/SubscriptionSection';
import PlanCard, { getFootnoteElement } from './PlanCard';
import PlanFeaturesTable from './PlanFeaturesTable';
import Footnote from './Footnote';
import { getRecentPlan } from './ManageSubscriptionView';

function getCurrentPlan(
  subscription: Domain.SubscriptionInfo | undefined,
  inAdviceFreeTrial: boolean,
  threeNickelsProducts: PricebookBundle[] | undefined
): PricebookBundle | undefined {
  const advicePricebook =
    threeNickelsProducts?.find(
      (p) => p.pricebook.productType === PricebookProductTypeEnum.NICKELS && p.prices[0].price > 0
    ) ?? DefaultPricebook;
  const freePricebook =
    threeNickelsProducts?.find(
      (p) =>
        p.pricebook.productType === PricebookProductTypeEnum.NICKELS &&
        p.prices[0].price === 0 &&
        p.pricebook.paidBy === Domain.PaidByEnum.USER
    ) ?? DefaultPricebook;
  const status = subscription && getSubscriptionStatus(subscription);
  if (subscription && !status?.expired) {
    if (subscription.origin === Domain.PaymentOriginEnum.STRIPE) {
      const pricebook = threeNickelsProducts?.find(
        (p) => p.pricebook.id === subscription.pricebookId
      );
      return pricebook ?? DefaultPricebook;
    } else {
      return advicePricebook;
    }
  } else if (inAdviceFreeTrial) {
    return undefined; // Return undefined so user can see action button to subscribe
  } else {
    return freePricebook;
  }
}

const ChangePlanView: React.FC = () => {
  const { t } = useTranslation();
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const legalDocs = Hooks.useTermsAndConditions();
  const freeTrialStatus = Hooks.useFreeTrialStatus();
  const inAdviceFreeTrial = freeTrialStatus ? freeTrialStatus.active : false;
  const { threeNickelsProducts, orgDoesNotOfferFreePricebook } = useAvailableProducts();
  const organizationMetadata = Hooks.useOrganizationMetadata();
  const termsApi = useService<Api.ITermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptionInfoData) return [];
    return subscriptionInfoData.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptionInfoData]);
  const subscription = subscriptionsByStartDateDescending?.[0];
  const currentPlan = getCurrentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);
  const recentPlan = getRecentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);
  if (threeNickelsProducts) {
    threeNickelsProducts.sort((a, b) => {
      if (a === recentPlan) {
        return -1;
      }
      if (b === recentPlan) {
        return 1;
      }
      if (a.prices[0].price === 0) {
        return 1;
      }
      if (b.prices[0].price === 0) {
        return -1;
      }
      return a.pricebook.id - b.pricebook.id;
    });
  }

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  let footnoteCounter = 0;
  const footnoteTexts: string[] = [];

  return (
    <Box>
      <>
        <Grid container flexDirection='column'>
          <Typography mb={3} variant='p30Bold' component='h1' color='primary'>
            {t('ChangePlan')}
          </Typography>
        </Grid>
        <Grid container direction='row' spacing={3}>
          {threeNickelsProducts &&
            threeNickelsProducts.map((plan) => {
              const { footnoteCounter: counter, footnoteText } = getFootnoteElement(
                footnoteCounter,
                plan,
                inAdviceFreeTrial
              );
              if (footnoteText) {
                footnoteCounter = counter;
                footnoteTexts.push(footnoteText);
              }
              return (
                <Grid item key={plan.pricebook.id}>
                  <PlanCard
                    plan={plan}
                    currentPlan={currentPlan}
                    pricebookMetadata={pricebookMetadata}
                    subscriptionInfo={subscription}
                    threeNickelsProducts={threeNickelsProducts}
                    orgDoesNotOfferFreePricebook={orgDoesNotOfferFreePricebook}
                    inAdviceFreeTrial={inAdviceFreeTrial}
                    footnoteIndex={footnoteText ? footnoteCounter : undefined}
                  />
                </Grid>
              );
            })}
        </Grid>
        <Stack spacing={1} mt={3}>
          {footnoteTexts.map((text, index) => (
            <Grid container direction='row' key={index}>
              <Footnote index={index} text={text} termsApi={termsApi} legalDocs={legalDocs} />
            </Grid>
          ))}
        </Stack>
        {threeNickelsProducts && <PlanFeaturesTable products={threeNickelsProducts} />}
      </>
    </Box>
  );
};

const meta = {
  showBack: false,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(ChangePlanView, meta);
