import { Grid } from '@mui/material';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import MortgageRatesForm from './MortgageRatesForm';
import { useForm } from 'react-hook-form';
import MortgageRatesResults from './MortgageRatesResults';
import { cleanWholeNumberStr, formatPercent } from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';

const mortgageRatesSchema = Yup.object({
  taxFilingState: Yup.string()
    .required('Required')
    .test('taxFilingState', 'Required', (state) =>
      Object.values(Domain.UsStatesEnum).some((option) => option === state)
    ),
  currentLoanBalance: Yup.string().notRequired(),
  downPaymentDollar: Yup.string().when('refinance', {
    is: 'true',
    then: (schema) =>
      schema.required('Required').test({
        name: 'down payment less than value',
        test: function (downPaymentDollar, { createError, path, parent }) {
          const { homeValue, refinance } = parent;
          const cleanHomeValue = cleanWholeNumberStr(homeValue);
          const cleanDownPayment = cleanWholeNumberStr(downPaymentDollar);
          if (refinance) return true;
          if (cleanDownPayment <= cleanHomeValue) return true;

          return createError({ message: `Maximum: $${cleanHomeValue}`, path });
        },
      }),
  }),
  downPaymentPercent: Yup.string().notRequired(),
  homeValue: Yup.string()
    .required('Required')
    .test('homeValue', 'Required', (homeValue) => {
      const cleanNumber = cleanWholeNumberStr(homeValue);
      return cleanNumber > 0;
    }),
  loanType: Yup.string().notRequired(),
  refinance: Yup.string()
    .required('Required')
    .test('refinance', 'Required', (refinance) => {
      return refinance === 'true' || refinance === 'false';
    }),
  termType: Yup.string().notRequired(),
  creditScore: Yup.string().notRequired(),
});

const MortgageRatesCalculatorView: React.FC = () => {
  const { setLoading } = useLoading();
  const selfData = Hooks.usePersonData();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Domain.MortgageRateResultData | undefined>();
  const [noResult, setNoResult] = useState(false);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.MortgageRatesFormData>(
    SavedFormsKeyEnum.MortgageRates
  );
  const mortgageRatesFormData: Domain.MortgageRatesFormData = useMemo(() => {
    return {
      loanType: formData?.loanType ?? Domain.LoanTypeEnum.Conventional,
      refinance: formData?.refinance,
      homeValue: formData?.homeValue,
      currentLoanBalance: formData?.currentLoanBalance,
      downPaymentDollar: formData?.downPaymentDollar,
      downPaymentPercent: formData?.downPaymentPercent,
      taxFilingState: formData?.taxFilingState ?? selfData?.taxFilingState,
      termType: formData?.termType ?? Domain.MortgageTermTypeEnum['30-Year Fixed'],
    };
  }, [selfData?.taxFilingState, formData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatInputs = (values: any): Domain.MortgageRatesFormData => {
    const formData: Domain.MortgageRatesFormData = {
      currentLoanBalance: cleanWholeNumberStr(values.currentLoanBalance ?? 0),
      downPaymentDollar: cleanWholeNumberStr(values.downPaymentDollar ?? 0),
      downPaymentPercent: cleanWholeNumberStr(values.downPaymentPercent ?? 0),
      homeValue: cleanWholeNumberStr(values.homeValue),
      loanType: values.loanType,
      refinance: values.refinance === 'true',
      taxFilingState: values.taxFilingState,
      termType: values.termType,
    };

    return formData;
  };

  const onSubmit = async (values: Domain.MortgageRatesFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.MortgageRates,
        formData: cleanedValues,
      });
      const { data } = await commands.execute(
        Data.Housing.Commands.CalculateAdviceMortgageRate,
        Domain.mapMortgageRatesFormDataToHouseMortgageRateRest(cleanedValues)
      );
      if (data.rate) {
        setResults(Domain.mapHouseMortgageRateResultRestToMortgageRateResultData(data));
        setNoResult(false);
      } else {
        setResults(undefined);
        setNoResult(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm<Domain.MortgageRatesFormData>({
    resolver: yupResolver(mortgageRatesSchema),
    defaultValues: mortgageRatesFormData,
  });

  if (typeof formData === 'undefined') return null;

  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <ToolTemplate
        header={
          <ToolHeader
            title={
              typeof results === 'undefined' && !noResult
                ? 'StartByAddingAFewDetails'
                : 'HeresYourAverageRate'
            }
          />
        }
        inputColumn={
          <MortgageRatesForm
            mortgageRatesFormData={mortgageRatesFormData}
            formMethods={methods}
            onSubmit={onSubmit}
          />
        }
        outputColumn={<MortgageRatesResults results={results ?? {}} />}
        boxItems={[
          {
            boxTitle: 'AverageRate',
            boxResult:
              typeof results?.rate !== 'undefined' ? formatPercent(results.rate) : undefined,
            noResult,
          },
        ]}
      />
    </Grid>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(MortgageRatesCalculatorView, meta);
