import { withSignUpLoginWizard } from '../services/signUpLogin';
import { withPricebookData } from '../data/pricebook';
import { withPaymentData } from '../data/payment';
import { withLicenseWizard } from '../services/license';
import { withSignUpProgressWizard } from '../services/signUpProgress';
import { withAccountSettingsWizard } from '../services/accountSettings';
import { useNickelsApis } from '../api/useNickelsApis';
import {
  IServiceContainer,
  IServiceModule,
  Scopes,
  createServiceModule,
} from '@aesop-fables/containr';
import { IAppStorageModule } from '@aesop-fables/scrinium';
import { Authentication, Configuration, Data, Logging } from '@3nickels/data-modules';
import { useRedirectHandlers } from '../redirects';
import { getApiEnvFromDomain } from './Environment';
import { usePaymentServices } from '../services/payment';
import { usePricebookServices } from '../services/pricebook';
import { AuthCookieLoaded } from '../services/twoFactor';
import { ActiveAppStateLogPredicate, AppStateRegistry } from '../AppState';
import { useUserEventServiceModule } from '../services/userEvents';

export const bootstrap = (overrides: IServiceModule[] = []): IServiceContainer => {
  // Configuration
  const options: Configuration.BootstrappingOptions = {
    api: { environment: getApiEnvFromDomain() },
    alerts: { interval: 60000, predicate: ActiveAppStateLogPredicate },
  };
  const modules: IServiceModule[] = [
    Authentication.useWebSessionAuth,
    useNickelsApis,
    useUserEventServiceModule,
    usePaymentServices,
    usePricebookServices,
    withLicenseWizard,
    withSignUpProgressWizard,
    withSignUpLoginWizard,
    withAccountSettingsWizard,
    useRedirectHandlers,
    useWebOverrides,
    Logging.useLogging({
      bufferInterval: 5000,
      maxLogsPerRequest: 100,
      predicate: ActiveAppStateLogPredicate,
    }),
    ...overrides,
  ];

  const storageModules: IAppStorageModule[] = [withPricebookData, withPaymentData];

  return Configuration.bootstrap(options, modules, storageModules);
};

const useWebOverrides = createServiceModule('web', (services) => {
  services.remove(Authentication.AuthenticationServices.IsAuthenticated);
  services.autoResolve(
    Authentication.AuthenticationServices.IsAuthenticated,
    Authentication.IsAuthenticatedSubject,
    Scopes.Singleton
  );

  services.remove(Data.TwoFactor.TwoFactorServices.Predicates.ReadyToLoad);
  services.autoResolve(
    Data.TwoFactor.TwoFactorServices.Predicates.ReadyToLoad,
    AuthCookieLoaded,
    Scopes.Singleton
  );

  services.importRegistry(AppStateRegistry);
});
