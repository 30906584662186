import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  type SelectProps,
} from '@mui/material';
import { theme } from '../../themes/ThemeWrapper';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import '../../themes/tippy-light-nickels.css';
import { HelpOutline } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

type SelectPropsNoLabel = Omit<SelectProps, 'label'>;
export type SelectInputProps<T> = {
  label?: string;
  helperText?: string;
  showHelperText?: boolean;
  helpContext?: React.ReactNode;
  prefixContent?: React.ReactNode;
  prefixWidth?: number;
  name: keyof T;
} & SelectPropsNoLabel;

export default function SelectInput<T>(props: SelectInputProps<T>) {
  const { t } = useTranslation();
  const methods = useFormContext();

  const text = props.label ? t(props.label) : '';
  const { helpContext, helperText, ...selectProps } = props;
  const currentValue = methods.watch(props.name);

  return (
    <Grid display='flex' flexDirection='column'>
      <Grid
        item
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        display='flex'
        mb='6px'>
        <InputLabel
          shrink={false}
          disableAnimation
          required={props.required}
          htmlFor={props.name}
          error={props.error}>
          <Typography variant='p16Bold' color='primary'>
            {text}
          </Typography>
        </InputLabel>
        {helpContext && (
          <Grid item display='flex' justifyContent='flex-end'>
            <Tippy placement='right' content={helpContext} theme='light-nickels-theme'>
              <HelpOutline style={{ color: theme.palette.primary.main }} />
            </Tippy>
          </Grid>
        )}
      </Grid>
      <Grid className={typeof props.prefixContent !== undefined ? 'prefixed-select-input' : ''}>
        {props.prefixContent && (
          <Grid item width={`${props.prefixWidth}%`}>
            {props.prefixContent}
          </Grid>
        )}
        <Grid
          item
          width={
            typeof props.prefixWidth !== undefined ? `${100 - (props.prefixWidth ?? 0)}%` : '100%'
          }>
          <FormControl size='small' fullWidth error={props.error}>
            <Select
              readOnly={props.readOnly}
              size='small'
              id={props.name}
              disableUnderline
              displayEmpty
              variant='filled'
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: '33%', overflowY: 'scroll' },
                },
              }}
              value={currentValue ?? ''}
              inputProps={{
                className:
                  currentValue === '' ||
                  typeof currentValue === 'undefined' ||
                  currentValue === null
                    ? 'select-placeholder'
                    : undefined,
                ...props.inputProps,
                ...(methods && methods.register
                  ? { ...methods.register(props.name as string) }
                  : {}),
              }}
              {...selectProps}
              onChange={
                props.name
                  ? (e, child) => {
                      const newValue = e.target.value;
                      methods.setValue(
                        props.name.toString(),
                        newValue === '' ? undefined : newValue,
                        {
                          shouldDirty: true,
                        }
                      );
                      methods.trigger(props.name.toString());
                      props.onChange && props.onChange(e, child);
                    }
                  : undefined
              }>
              <MenuItem className='placeholder' disabled value=''>
                {t(props.placeholder ?? '')}
              </MenuItem>
              {props.children}
            </Select>
            {props.error && (
              <FormHelperText error={props.error}>{helperText && t(helperText)}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
