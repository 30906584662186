import React from 'react';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { TableContainer, Table, TableBody, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TransactionIcon } from './TransactionIcon';
import SkeletonSpendingSummaryTransactionsTable, {
  SkeletonTransaction,
} from './skeletons/SkeletonSpendingSummaryTransactionsTable';
import { Svgs } from '../../assets/svg';
import { Button } from '../../components/buttons/Button';
import { formatDecimalDollars } from '../../helpers/utilityFunctions';

export interface SpendingsummaryTransactionsTableProps {
  transactions: Api.Transaction[];
}

const SpendingSummaryTransactionsTable: React.FC<SpendingsummaryTransactionsTableProps> = ({
  transactions,
}) => {
  const {
    loadingTransactions,
    loadingMoreTransactions,
    setLoadingMoreTransactions,
    transactionsTimeframe,
    transactionsCategories,
    transactionsAccountIds,
    transactionsSearchString,
    transactionsAvailable,
  } = Hooks.useTransactions();
  const commands = Hooks.useCommands();

  const loadMoreTransactions = async () => {
    try {
      setLoadingMoreTransactions(true);
      await commands.execute(Data.Transactions.Commands.LoadNextTransactions, {
        timeframe: transactionsTimeframe,
        categories: transactionsCategories,
        accountIds: transactionsAccountIds,
        searchString: transactionsSearchString,
      });
    } finally {
      setLoadingMoreTransactions(false);
    }
  };

  if (loadingTransactions) {
    return (
      <Grid>
        <TransactionTableHeader />
        <SkeletonSpendingSummaryTransactionsTable />
      </Grid>
    );
  }

  if (transactions.length === 0) {
    return (
      <Grid>
        <TransactionTableHeader />
        <EmptyTransactionsTable />
      </Grid>
    );
  }

  return (
    <Grid>
      <TransactionTableHeader />
      <TableContainer className='search-results-container'>
        <Table className='search-results-table'>
          <TableBody className='search-results-table-body'>
            {transactions.map((transaction) => {
              const outflow = (transaction.amount ?? 0) > 0;
              return (
                <Grid className='search-results-row'>
                  <Grid className='description-section'>
                    <TransactionIcon category={transaction.primaryCategory} />
                    <Typography className='date' variant='p14' color='primary'>
                      {dayjs(transaction.date).format('M/D/YY')}
                    </Typography>
                    <Typography className='description' variant='p16Bold' color='secondary'>
                      {transaction.merchantName ?? transaction.name}
                    </Typography>
                  </Grid>
                  <Grid className='amount-section'>
                    <Typography className='category' variant='p16' color='secondary'>
                      {_.startCase(_.toLower(transaction.primaryCategory?.toString()))}
                    </Typography>
                    {outflow ? (
                      <Typography className='amount' variant='p16' color='accent'>
                        -{formatDecimalDollars(transaction.amount ?? 0)}
                      </Typography>
                    ) : (
                      <Typography className='amount' variant='p16' color='secondary'>
                        {formatDecimalDollars(Math.abs(transaction.amount ?? 0))}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {loadingMoreTransactions && <SkeletonTransaction />}
            {transactionsAvailable && !loadingMoreTransactions && (
              <Button
                style={{ marginTop: '10px' }}
                fullWidth
                variant='ghost'
                color='primary'
                label='Load More Transactions'
                onClick={loadMoreTransactions}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const TransactionTableHeader: React.FC = () => {
  return (
    <TableContainer
      className='search-results-container'
      style={{ background: 'transparent', border: 'none' }}>
      <Table className='search-results-table'>
        <TableBody className='search-results-table-body' style={{ padding: '0px' }}>
          <Grid className='search-results-row' py='0px !important' mt='10px'>
            <Grid className='description-section'>
              <Svgs.DisplaySmallCustomWallet visibility='hidden' />
              <Typography className='date' variant='p14' color='primary'>
                Date
              </Typography>
              <Typography className='description' variant='p14' color='primary'>
                Description
              </Typography>
            </Grid>
            <Grid className='amount-section' display='flex' width='50%'>
              <Typography variant='p14' color='primary'>
                Category
              </Typography>
              <Typography variant='p14' color='primary'>
                Amount
              </Typography>
            </Grid>
          </Grid>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EmptyTransactionsTable: React.FC = () => {
  return (
    <TableContainer className='search-results-container'>
      <Table className='search-results-table'>
        <TableBody
          className='search-results-table-body'
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='p16' color='secondary'>
            We couldn't find any transactions that match your filters.
          </Typography>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpendingSummaryTransactionsTable;
