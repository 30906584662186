import { Card, CardContent, Typography } from '@mui/material';
import { Row, Spacer } from '../components';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { useTranslation } from 'react-i18next';

interface PasswordReqProps {
  hasMinChar: boolean;
  hasANumber: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasSpecialChar: boolean;
}

export const PasswordRequirements: React.FC<PasswordReqProps> = ({
  hasMinChar,
  hasANumber,
  hasUpperCase,
  hasLowerCase,
  hasSpecialChar,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceType();

  return (
    <Card variant='innerCard'>
      <CardContent className='pad20'>
        {isMobile || isTablet ? (
          <>
            <Row>
              {hasMinChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? 'secondary' : 'error.main'}>
                {t('PasswordRulesLength')}
              </Typography>
            </Row>
            <Spacer height='xxs' />
            <Row>
              {hasANumber ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasANumber ? 'secondary' : 'error.main'}>
                {t('PasswordRulesNumeric')}
              </Typography>
            </Row>
            <Spacer height='xxs' />
            <Row>
              {hasUpperCase ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasUpperCase ? 'secondary' : 'error.main'}>
                {t('PasswordRulesUpperCase')}
              </Typography>
            </Row>
            <Spacer height='xxs' />
            <Row>
              {hasLowerCase ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasLowerCase ? 'secondary' : 'error.main'}>
                {t('PasswordRulesLowerCase')}
              </Typography>
            </Row>
            <Spacer height='xxs' />
            <Row>
              {hasSpecialChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasSpecialChar ? 'secondary' : 'error.main'}>
                {t('PasswordRulesSpecialChar')}
              </Typography>
            </Row>
          </>
        ) : (
          <Row>
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              {hasMinChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? 'secondary' : 'error.main'}>
                {t('PasswordRulesLength')}
              </Typography>
            </Row>
            <Spacer width='xxs' />
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              {hasANumber ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasANumber ? 'secondary' : 'error.main'}>
                {t('PasswordRulesNumeric')}
              </Typography>
            </Row>
            <Spacer width='xxs' />
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              {hasUpperCase ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasUpperCase ? 'secondary' : 'error.main'}>
                {t('PasswordRulesUpperCase')}
              </Typography>
            </Row>
            <Spacer width='xxs' />
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              {hasLowerCase ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasLowerCase ? 'secondary' : 'error.main'}>
                {t('PasswordRulesLowerCase')}
              </Typography>
            </Row>
            <Spacer width='xxs' />
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              {hasSpecialChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasSpecialChar ? 'secondary' : 'error.main'}>
                {t('PasswordRulesSpecialChar')}
              </Typography>
            </Row>
          </Row>
        )}
      </CardContent>
    </Card>
  );
};
