import { Domain } from '@3nickels/data-modules';
import { useSubject } from '@aesop-fables/scrinium';
import { PricebookBundle, PricebookProductTypeEnum } from '../../models/PricebookData';
import { PricebookServices } from '../../data/pricebook';
import { useMemo } from 'react';

export function useAvailableProducts() {
  const availableProductsData = useSubject<PricebookBundle[]>(
    PricebookServices.AvailablePricebooks
  );

  const threeNickelsProducts = availableProductsData?.filter(
    (p) => p.pricebook.productType !== PricebookProductTypeEnum.GUIDED_CHOICE
  );

  const sortedProducts = useMemo(() => {
    if (!threeNickelsProducts) return [];
    return [...threeNickelsProducts].sort((a, b) => {
      if (a.prices[0].price === 0) return 1;
      if (b.prices[0].price === 0) return -1;
      return a.pricebook.id - b.pricebook.id;
    });
  }, [threeNickelsProducts]);

  const multipleProducts = (threeNickelsProducts?.length ?? 0) > 1;

  const orgOnlyOffersFreePricebook =
    !multipleProducts &&
    threeNickelsProducts?.[0].prices[0].price === 0 &&
    threeNickelsProducts?.[0].pricebook.paidBy === Domain.PaidByEnum.USER;

  const freePricebooks = threeNickelsProducts?.filter((p) => p.prices[0].price === 0);
  const orgDoesNotOfferFreePricebook = freePricebooks?.length === 0;

  const orgPaysPricebook = threeNickelsProducts?.find(
    (p) => p.pricebook.paidBy === Domain.PaidByEnum.ORG
  );

  return {
    availableProductsData,
    threeNickelsProducts,
    sortedProducts,
    multipleProducts,
    orgOnlyOffersFreePricebook,
    orgDoesNotOfferFreePricebook,
    orgPaysPricebook,
  };
}
