import { Button, Card, Divider, FormGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logo3NSmall } from '../assets/png';
import {
  Column,
  DatePicker,
  PasswordRequirements,
  Row,
  Spacer,
  VerticalFormInput,
} from '../components';
import { ProfileData } from '../models/ProfileData';
import { Form } from 'react-final-form';
import { useState } from 'react';
import { SignUpLoginWizard, signUpLoginWizardKey } from '../services/signUpLogin';
import dayjs from 'dayjs';
import { mobileMargin } from '../App';
import { useDeviceType } from '../hooks/useDeviceType';
import { AxiosError } from 'axios';
import { useService } from '@aesop-fables/containr-react';
import { Api, Hooks } from '@3nickels/data-modules';
import AppleLogin from '../login/AppleLogin';
import GoogleLogin from '../login/GoogleLogin';
import { useMessage } from '../hooks/useMessage';

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]).{8,32}$/;

export interface PasswordConfirmData {
  password?: string;
  rePassword?: string;
}
const CreateAccount = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const { showMessage } = useMessage();

  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasANumber, setHasANumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);

  const organizationApi = useService<Api.OrganizationApi>(Api.ApiKeys.Organization);
  const organizationData = Hooks.useOrganizationData();

  const navBack = () => {
    navigate(-1);
  };

  const onSubmit = async (values: ProfileData) => {
    const { firstName, lastName, birthdate, emailAddress, password } = values;
    try {
      await signUpLoginWizard.setAccountInfoAndAttemptSignUp(
        firstName ?? '',
        lastName ?? '',
        birthdate ?? '',
        emailAddress ?? '',
        password ?? ''
      );
      navigate('/secure-account');
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.status === 400) {
        showMessage('Invalid email: If account exists, please try logging in', 'error');
      }
    }
  };

  const onValidatePasswords = (values: PasswordConfirmData) => {
    const errors: PasswordConfirmData = {};
    setHasMinChar(false);
    setHasANumber(false);
    setHasUpperCase(false);
    setHasLowerCase(false);
    setHasSpecialChar(false);

    if (values.password) {
      if (values.password.length > 7 && values.password.length < 33) {
        setHasMinChar(true);
      }

      const numResult = values.password.match(/[0-9]/g);
      if (numResult !== null) Array.from(numResult);
      if (Array.isArray(numResult) && numResult.length > 0) {
        setHasANumber(true);
      }

      const upperCaseResult = values.password.match(/[A-Z]/g);
      if (upperCaseResult !== null) Array.from(upperCaseResult);
      if (Array.isArray(upperCaseResult) && upperCaseResult.length > 0) {
        setHasUpperCase(true);
      }

      const lowerCaseResult = values.password.match(/[a-z]/g);
      if (lowerCaseResult !== null) Array.from(lowerCaseResult);
      if (Array.isArray(lowerCaseResult) && lowerCaseResult.length > 0) {
        setHasLowerCase(true);
      }

      const specialCharResult = values.password.match(/[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]/g);
      if (specialCharResult !== null) Array.from(specialCharResult);
      if (Array.isArray(specialCharResult) && specialCharResult.length > 0) {
        setHasSpecialChar(true);
      }
    }

    if (values.password && !passwordRegex.test(values.password)) {
      errors.password = 'Please follow the password requirements below';
    } else if (values.password && passwordRegex.test(values.password)) {
      errors.password = undefined;
    }
    if (values.password !== values.rePassword) {
      errors.rePassword = 'Password does not match';
    } else if (values.password === values.rePassword) {
      errors.rePassword = undefined;
    }
    return errors;
  };

  const onValidateEmail = (value: string) => {
    if (!/\S+@\S+\.\S+/.test(value)) {
      return 'Invalid Email';
    }
  };

  return (
    <Form onSubmit={onSubmit} validate={(values) => onValidatePasswords(values)}>
      {({ handleSubmit }) => (
        <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
          <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
            {organizationData ? (
              organizationData.logoUri ? (
                <img
                  src={organizationApi.getLogo()}
                  alt={organizationData?.name}
                  style={{ height: '60%' }}
                />
              ) : (
                <Typography variant='p25Bold' color='secondary'>
                  {organizationData?.name}
                </Typography>
              )
            ) : (
              <Card variant='ghost' />
            )}
            <img src={logo3NSmall} alt='logo' style={{ height: '70%' }} />
          </Row>

          <Typography variant='p30Bold' color='primary'>
            Create Your Account
          </Typography>
          <Spacer height='xs' />
          <Typography variant='h2' color='secondary'>
            Let’s set up your 3Nickels account.
          </Typography>
          <Spacer height='xs' />
          {isMobile || isTablet ? (
            <>
              <Column style={{ justifyContent: 'center' }}>
                <AppleLogin />
                <Spacer height='xs' />
                <GoogleLogin />
              </Column>
              <Spacer height='xs' />
              <Divider color='secondary' flexItem>
                <Typography variant='p18Bold' color='secondary'>
                  OR
                </Typography>
              </Divider>
              <Spacer height='xs' />
              <FormGroup
                style={{
                  alignSelf: 'center',
                  width: isMobile || isTablet ? '90%' : '70%',
                  maxWidth: isMobile ? '' : '650px',
                }}>
                <VerticalFormInput<ProfileData>
                  fieldName='firstName'
                  label='First Name'
                  placeholder='First Name'
                  required
                  autoFocus
                  maxLength={32}
                />
                <Spacer height='sm' />
                <VerticalFormInput<ProfileData>
                  fieldName='lastName'
                  label='Last Name'
                  placeholder='Last Name'
                  required
                  maxLength={32}
                />
                <Spacer height='sm' />
                <DatePicker<ProfileData>
                  fieldName='birthdate'
                  label='Date of Birth'
                  placeholder='MM/DD/YYYY'
                  helpText={<HelpText />}
                  validate={onValidateBirthdate}
                  required
                />
                <Spacer height='sm' />
                <VerticalFormInput<ProfileData>
                  fieldName='emailAddress'
                  label='Email Address'
                  placeholder='Email Address'
                  validate={onValidateEmail}
                  required
                />
                <Spacer height='sm' />
                <VerticalFormInput<ProfileData>
                  fieldName='password'
                  label='Enter New Password'
                  placeholder='Enter New Password'
                  inputStyle={{ width: '100%' }}
                  required
                />
                <Spacer height='sm' />
                <PasswordRequirements
                  hasMinChar={hasMinChar}
                  hasSpecialChar={hasSpecialChar}
                  hasANumber={hasANumber}
                  hasUpperCase={hasUpperCase}
                  hasLowerCase={hasLowerCase}
                />
                <Spacer height='sm' />
                <VerticalFormInput<ProfileData>
                  fieldName='rePassword'
                  label='Re-enter New Password'
                  placeholder='Re-enter Password'
                  inputStyle={{ width: '100%' }}
                  required
                />
              </FormGroup>
              <Spacer height='lg' />
              <Column style={{ justifyContent: 'space-between' }}>
                <Button onClick={handleSubmit} color='secondary'>
                  Next: Secure Your Account
                </Button>
                <Spacer height='xs' />
                <Button onClick={navBack} variant='outlined' color='secondary'>
                  Cancel
                </Button>
              </Column>
            </>
          ) : (
            <>
              <Row>
                <FormGroup
                  style={{
                    alignSelf: 'center',
                    width: isMobile || isTablet ? '90%' : '70%',
                    maxWidth: isMobile ? '' : '650px',
                  }}>
                  <Row style={{ width: '100%' }}>
                    <Column style={{ width: '100%' }}>
                      <VerticalFormInput<ProfileData>
                        fieldName='firstName'
                        label='First Name'
                        placeholder='First Name'
                        inputStyle={{ width: '100%' }}
                        required
                        autoFocus
                        maxLength={32}
                      />
                    </Column>
                    <Spacer height='sm' />
                    <Column style={{ marginLeft: '15px', width: '100%' }}>
                      <VerticalFormInput<ProfileData>
                        fieldName='lastName'
                        label='Last Name'
                        placeholder='Last Name'
                        inputStyle={{ width: '100%' }}
                        required
                        maxLength={32}
                      />
                    </Column>
                  </Row>
                  <Spacer height='sm' />
                  <DatePicker<ProfileData>
                    fieldName='birthdate'
                    label='Date of Birth'
                    placeholder='MM/DD/YYYY'
                    helpText={<HelpText />}
                    validate={onValidateBirthdate}
                    required
                  />
                  <Spacer height='sm' />
                  <VerticalFormInput<ProfileData>
                    fieldName='emailAddress'
                    label='Email Address'
                    placeholder='Email Address'
                    validate={onValidateEmail}
                    required
                  />
                  <Spacer height='sm' />
                  <VerticalFormInput<ProfileData>
                    fieldName='password'
                    label='Enter New Password'
                    placeholder='Enter New Password'
                    inputStyle={{ width: '100%' }}
                    required
                  />
                  <Spacer height='sm' />
                  <PasswordRequirements
                    hasMinChar={hasMinChar}
                    hasSpecialChar={hasSpecialChar}
                    hasANumber={hasANumber}
                    hasUpperCase={hasUpperCase}
                    hasLowerCase={hasLowerCase}
                  />
                  <Spacer height='sm' />
                  <VerticalFormInput<ProfileData>
                    fieldName='rePassword'
                    label='Re-enter New Password'
                    placeholder='Re-enter Password'
                    inputStyle={{ width: '100%' }}
                    required
                  />
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Button onClick={navBack} variant='outlined' color='secondary'>
                      Cancel
                    </Button>
                    <Button onClick={handleSubmit} color='secondary'>
                      Next: Secure Your Account
                    </Button>
                  </Row>
                </FormGroup>
                <Divider
                  style={{ margin: '0px 75px' }}
                  color='secondary'
                  orientation='vertical'
                  flexItem>
                  <Typography variant='p18Bold' color='secondary'>
                    OR
                  </Typography>
                </Divider>
                <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='p18Bold' color='secondary'>
                    Sign up another way
                  </Typography>
                  <Spacer height='sm' />
                  <AppleLogin />
                  <Spacer height='xs' />
                  <GoogleLogin />
                </Column>
              </Row>
            </>
          )}
          <Spacer height='lg' />
        </Column>
      )}
    </Form>
  );
};

export const onValidateBirthdate = (value: string) => {
  let error = '';
  if (!value) {
    return error;
  }

  if (value && dayjs().subtract(12, 'y').isBefore(dayjs(value), 'day')) {
    error = 'Must be 12 years old or older';
  } else if (value && dayjs().subtract(109, 'y').isAfter(dayjs(value), 'day')) {
    error = 'Must be 109 years old or younger';
  }

  return error;
};

const HelpText = () => {
  return (
    <Column style={{ padding: '20px' }}>
      <Typography variant='p16Bold' color='secondary'>
        Date of Birth
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>Used to optimize savings and withdrawals</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>Certain ages, like 59 1/2 and 70 1/2, are important for tax reasons.</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        Sounds bizarre? Well, thank our complicated tax laws.
      </Typography>
      <Spacer height='xxl' />
    </Column>
  );
};

export default CreateAccount;
