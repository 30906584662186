/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Backdrop, Grid } from '@mui/material';
import { Loading } from '../../hooks/useLoading';
import { IncomeReadySubject } from '../../subjects/AppReadySubject';
import { useSubject } from '@aesop-fables/scrinium';
import Box from '@mui/material/Box';
import BudgetDetailsManager from './BudgetDetailsManager';
import { Tabs } from '../../components/Tabs';
import { BudgetDetailsLayoutMeta } from './BudgetDetailsLayout';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { Data, Hooks } from '@3nickels/data-modules';

const BudgetDetailsHomeWrapper: React.FC = () => {
  const incomeReady = useSubject<boolean>(IncomeReadySubject);

  if (typeof incomeReady === 'undefined' || incomeReady === false) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }
  return <BudgetDetailsHome />;
};

export const BudgetDetailsHome: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const userConfig = Hooks.useUserConfig();
  const { accountId } = Hooks.usePersonData() ?? {};
  const commands = Hooks.useCommands();

  useEffect(() => {
    if (accountId && !userConfig?.budgetDetailsReviewed) {
      commands.executeMutation(new Data.Users.Mutations.CompleteBudgetDetails(), null);
    }
  }, [userConfig?.budgetDetailsReviewed, accountId, commands]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        labels={['Monthly', 'Annual']}
        value={value}
        onChange={handleChange}
        aria-label='basic tabs example'
      />
      <Grid paddingTop={3}>
        <BudgetDetailsManager mode={value === 0 ? 'monthly' : 'annual'} />
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: true,
  showNext: false,
  headerVariant: 'home',
} satisfies LayoutMeta<BudgetDetailsLayoutMeta>;

export default withLayoutMeta(BudgetDetailsHomeWrapper, meta);
