import { Svgs } from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from './ToolsCard';

const LifestyleToolsCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomLegacy />}
      label={t('Lifestyle')}
      content={t('IdeaForYourLifeNeeds')}
    />
  );
};

export default LifestyleToolsCard;
