import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Typography, useTheme } from '@mui/material';
import 'tippy.js/themes/light.css'; // must import any theme you wish to use with Tippy
import 'tippy.js/dist/tippy.css'; // optional
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import { FormInputProps } from './FormInput';
import { useField } from 'react-final-form';
import React from 'react';
import Column from './Column';
import Row from './Row';
import dayjs from 'dayjs';

interface DatePickerProps<T> extends FormInputProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputStyle?: any;
  label?: string;
  helpText?: React.ReactNode;
  initialValue?: string;
  required?: boolean;
  validate?: (x: string) => undefined | string;
}

// TODO datepicker field displays error border before touched
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const DatePicker = <T extends unknown>(props: DatePickerProps<T>) => {
  const theme = useTheme();
  const { label, helpText, fieldName, initialValue, required, validate } = props;

  // const { input, meta } = useField(fieldName as string, {
  //   type: 'string',
  //   initialValue,
  //   validate,
  // });

  const { input, meta } = useField<string>(fieldName.toString(), {
    initialValue,
    validate: (value: string) =>
      !required || value ? (validate ? validate(value) : undefined) : 'Required',
    subscription: { value: true, touched: true, error: true },
  });
  const datePickerVal = input.value ? dayjs(input.value) : null;

  return (
    <Column>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}>
        {label ? (
          <>
            <Typography variant='p14Bold' color='primary'>
              {label}
            </Typography>
          </>
        ) : null}
        {helpText ? (
          <>
            <Column style={{ alignSelf: 'flex-end' }}>
              <Tippy placement='right' content={helpText} theme='light'>
                <HelpOutline style={{ color: theme.palette.primary.main }} />
              </Tippy>
            </Column>
          </>
        ) : null}
      </Row>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <DesktopDatePicker
            format='MM/DD/YYYY'
            views={['year', 'month', 'day']}
            slotProps={{
              textField: {
                size: 'small',
                helperText: meta.touched ? meta.error : undefined,
                error: meta.touched ? meta.error : undefined,
              },
            }}
            {...input}
            value={datePickerVal}
            onChange={(newVal) => {
              input.onChange(newVal ? dayjs(newVal).format('YYYY-MM-DD') : undefined);
            }}
          />
        </Stack>
      </LocalizationProvider>
    </Column>
  );
};

export default DatePicker;
