import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, IconButton, Link, Typography } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { MutatorState } from '@aesop-fables/scrinium';
import { PaymentMethodsData } from '../../../api/apis/PaymentApi';
import { convertToTitleCase } from '../../../helpers/utilityFunctions';
import { Modal } from '../../../components';

interface PaymentMethodsSectionProps {
  paymentMethodsData: PaymentMethodsData[] | undefined;
  defaultPaymentMethod: string | undefined;
  setDefaultPaymentMethod: MutatorState<string>;
  removePaymentMethod: MutatorState<string>;
  setActiveMethod: React.Dispatch<React.SetStateAction<PaymentMethodsData | undefined>>;
}

const PaymentMethodsSection: React.FC<PaymentMethodsSectionProps> = ({
  paymentMethodsData,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  removePaymentMethod,
  setActiveMethod,
}) => {
  const { t } = useTranslation();

  if (!paymentMethodsData || paymentMethodsData.length === 0)
    return (
      <Card className='card-row' sx={{ padding: '10px', marginTop: '20px' }}>
        <Grid container display='flex' alignItems='center' justifyContent='center'>
          <Typography variant='p16' color='secondary'>
            {t('YouDontHaveAnySavedPaymentMethods')}
          </Typography>
        </Grid>
      </Card>
    );

  paymentMethodsData.sort((a, b) => {
    if (a.id === defaultPaymentMethod) {
      return -1;
    } else if (b.id === defaultPaymentMethod) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <>
      <Grid
        container
        mt={2}
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        padding='0px 5px'>
        <Grid item xs={0.5} paddingLeft={1} />
        <Grid item xs={6}>
          <Typography variant='p14' color='primary'>
            {t('Name')}
          </Typography>
        </Grid>
        <Grid item xs={4.5}>
          <Typography variant='p14' color='primary'>
            {t('Type')}
          </Typography>
        </Grid>
        <Grid item xs={1} paddingRight={1} />
      </Grid>
      {paymentMethodsData.map((paymentMethod) => {
        return (
          <MethodCard
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            defaultPaymentMethod={defaultPaymentMethod}
            setDefaultPaymentMethod={setDefaultPaymentMethod}
            removePaymentMethod={removePaymentMethod}
            setActiveMethod={setActiveMethod}
          />
        );
      })}
    </>
  );
};

interface MethodCardProps {
  paymentMethod: PaymentMethodsData;
  defaultPaymentMethod: string | undefined;
  setDefaultPaymentMethod: MutatorState<string>;
  removePaymentMethod: MutatorState<string>;
  setActiveMethod: React.Dispatch<React.SetStateAction<PaymentMethodsData | undefined>>;
}

const MethodCard: React.FC<MethodCardProps> = ({
  paymentMethod,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  removePaymentMethod,
  setActiveMethod,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const isDefault = paymentMethod.id === defaultPaymentMethod;

  const onSetAsDefault = async () => {
    setActiveMethod(paymentMethod);
    await setDefaultPaymentMethod.action(paymentMethod.id);
  };

  const onDeletePaymentMethod = async () => {
    setActiveMethod(paymentMethod);
    await removePaymentMethod.action(paymentMethod.id);
  };

  return (
    <>
      <Modal
        title={t('RemovePaymentMethod') as string}
        primaryButtonText='No, Keep Method'
        secondaryButtonText='Yes, Remove Method'
        swapButtonFunctionality
        open={modalOpen}
        setOpen={setModalOpen}
        handleSave={onDeletePaymentMethod}>
        <Typography variant='p16' color='secondary'>
          {t('ConfirmRemovePaymentMethod')}
        </Typography>
        <Typography mt={2} variant='p16Bold' color='secondary'>
          {convertToTitleCase(paymentMethod.type)} ending in {paymentMethod.lastDigits}
        </Typography>
        <Typography variant='p16Bold' color='secondary'>
          {convertToTitleCase(paymentMethod.type)}
        </Typography>
      </Modal>
      <Card className='card-row' sx={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
        <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={0.5} paddingLeft={1}>
            {!isDefault && (
              <IconButton
                className='show-on-hover'
                sx={{ opacity: 0, padding: 0 }}
                onClick={() => {
                  setModalOpen(true);
                }}
                disableRipple>
                <Svgs.IpmIconDelete />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant='p16Bold' color='secondary'>
              {convertToTitleCase(paymentMethod.type)} ending in {paymentMethod.lastDigits}
              {isDefault && (
                <Typography ml={3} variant='p16Bold' color='primary' sx={{ display: 'inline' }}>
                  {t('Default')}
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4.5}>
            <Typography variant='p16' color='secondary'>
              {convertToTitleCase(paymentMethod.type)}
            </Typography>
          </Grid>
          <Grid item xs={1} paddingRight={1} style={{ textAlign: 'end' }}>
            {!isDefault && (
              <Link variant='p12' noWrap onClick={onSetAsDefault}>
                {t('SetAsDefault')}
              </Link>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default PaymentMethodsSection;
