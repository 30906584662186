import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { Loading } from '../../../../../hooks/useLoading';
import { Spacing } from '../../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../../AccountDetailsLayout';
import { CollegeSavingsPlanBasicsEditViewWrapper as CollegeSavingsPlanBasicsEditView } from '../basics/CollegeSavingsPlanBasicsEditView';
import { CollegeSavingsPlanDetailsEditViewWrapper as CollegeSavingsPlanDetailsEditView } from '../details/CollegeSavingsPlanDetailsEditView';
import CollegeSavingsPlanBasicsCard from '../basics/CollegeSavingsPlanBasicsCard';
import CollegeSavingsPlanDetailsCard from '../details/CollegeSavingsPlanDetailsCard';
import { SummaryView } from '../../../../../components/SummaryHeader';
import { useMessage } from '../../../../../hooks/useMessage';

declare type CollegeSavingsPlanSummaryDrawerType =
  keyof Data.CollegeSavingsPlans.CollegeSavingsPlanWizardRegistry;

const CollegeSavingsPlanSummaryView: React.FC = () => {
  const data = Hooks.useCollegeSavingsPlanWizardData();
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { wizard } = Hooks.useCollegeSavingsPlanWizard();
  const plaidWizard = Hooks.usePlaidWizard();
  const [isStarted, params] = useObservable(combineLatest([wizard.isStarted$, wizard.params$])) ?? [
    undefined,
    undefined,
  ];
  const [open, setOpen] = useState(false);
  const includeSpouse = Hooks.useIncludeSpouse();
  const { id } = useParams();
  const { collegeSavingsPlans, spouseCollegeSavingsPlans } = Hooks.useCollegeSavingsPlanData();
  const accounts = useMemo(
    () =>
      includeSpouse && spouseCollegeSavingsPlans
        ? [...collegeSavingsPlans, ...spouseCollegeSavingsPlans]
        : collegeSavingsPlans,
    []
  );
  const deleteMutation = useMutation(
    new Data.CollegeSavingsPlans.Mutations.DeleteCollegeSavingsPlan()
  );
  const account = useMemo(
    () => accounts.find((x) => x.id === params?.id) as Domain.CollegeSavingsPlan,
    [accounts]
  );
  const currentBalance = data.basic.balance ?? 0;

  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    }
  }, [isStarted]);

  const openDrawer = (key?: CollegeSavingsPlanSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };

  const linkAccountHandler = useCallback(() => {
    plaidWizard.prepareAccountForManualLink(
      account,
      Domain.FinancialAccountTypeEnum.CollegeSavingsAccount
    );
  }, [plaidWizard, accounts]);

  const removeAccountHandler = useCallback(() => {
    deleteMutation.action(account.id ?? 0); // no need to actually wait since the datacache will still be updated
    navigate('/account-details');
    showMessage('College Savings Plan removed!');
  }, [account]);

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={currentBalance}
      onLinkAccount={linkAccountHandler}
      linkableAccount={account}
      onRemoveAccount={removeAccountHandler}
      accountType={Domain.FinancialAccountTypeEnum.CollegeSavingsAccount}
      accountSubType={
        data.basic.accountType && Domain.collegeSavingsAccountLabels[data.basic.accountType]
      }>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <CollegeSavingsPlanBasicsCard
            planBasics={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <CollegeSavingsPlanDetailsCard
            planDetails={data.details}
            onEdit={() => openDrawer('details')}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(
            undefined as unknown as keyof Data.CollegeSavingsPlans.CollegeSavingsPlanWizardRegistry
          );
        }}
      />
      <WizardFooter nextLabel='Done' onDone={() => navigate('/account-details')} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard } = Hooks.useCollegeSavingsPlanWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key as keyof Data.CollegeSavingsPlans.CollegeSavingsPlanWizardRegistry;
  if (key === 'basic') {
    return <CollegeSavingsPlanBasicsEditView editing onBack={onBack} />;
  } else if (key === 'details') {
    return <CollegeSavingsPlanDetailsEditView editing onBack={onBack} />;
  }

  return null;
};

export interface CollegeSavingsPlanSummaryCollegeSavingsPlandProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(CollegeSavingsPlanSummaryView, meta);
