import { MenuItem, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Domain } from '@3nickels/data-modules';
import { UseFormReturn } from 'react-hook-form';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import SelectInput from '../../../../components/form/SelectInput';
import FormContent from '../../../../components/form/FormContent';
import SliderPercent from '../../../../components/form/SliderPercent';
import { Button } from '../../../../components/buttons/Button';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import HomePurchasePriceHelp from './help/HomePurchasePriceHelp';
import DownPaymentHelp from './help/DownPaymentHelp';
import EstimatedHomeValueHelp from './help/EstimatedHomeValueHelp';
import SliderInputTypeToggle from '../../../../components/SliderInputTypeToggle';

export type MortgageRatesFormProps = {
  formMethods: UseFormReturn;
  mortgageRatesFormData?: Domain.MortgageRatesFormData;
  onSubmit: (values: Domain.MortgageRatesFormData) => void;
};

const MortgageRatesForm: React.FC<MortgageRatesFormProps> = ({
  formMethods,
  mortgageRatesFormData,
  onSubmit,
}) => {
  const { formState, watch, handleSubmit, getValues, setValue } = formMethods;
  const [refinance, setRefinance] = useState<boolean | undefined>();
  const [downPaymentPercent, setDownPaymentPercent] = useState(true);
  const [maxDownPaymentDollar, setMaxDownPaymentDollar] = useState(1000000);

  useEffect(() => {
    if (typeof mortgageRatesFormData?.refinance !== 'undefined') {
      setValue('refinance', mortgageRatesFormData.refinance ? 'true' : 'false');
      setRefinance(mortgageRatesFormData.refinance);
    }

    if (typeof mortgageRatesFormData?.loanType !== 'undefined') {
      setValue('loanType', mortgageRatesFormData.loanType);
    }

    if (typeof mortgageRatesFormData?.termType !== 'undefined') {
      setValue('termType', mortgageRatesFormData.termType);
    }
  }, [mortgageRatesFormData]);

  useEffect(() => {
    if (
      !formState.isDirty &&
      mortgageRatesFormData?.taxFilingState &&
      mortgageRatesFormData.taxFilingState !== getValues('taxFilingState')
    ) {
      setValue('taxFilingState', mortgageRatesFormData.taxFilingState);
    }
    // only set initially when form is dirty
  }, [formState, mortgageRatesFormData]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'refinance') {
        const newVal = values.refinance;
        setRefinance(newVal === 'true');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // update downPaymentDollar
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (downPaymentPercent && (name === 'downPaymentPercent' || name === 'homeValue')) {
        const homeValue = cleanWholeNumberStr(values.homeValue);
        const downPaymentPercent = cleanWholeNumberStr(values.downPaymentPercent);
        const downPaymentDollar = (homeValue * downPaymentPercent) / 100;
        setValue('downPaymentDollar', downPaymentDollar);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, downPaymentPercent]);

  // update downPaymentPercent
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (!downPaymentPercent && (name === 'downPaymentDollar' || name === 'homeValue')) {
        const homeValue = cleanWholeNumberStr(values.homeValue);
        if (homeValue > 0) {
          const downPaymentDollar = cleanWholeNumberStr(values.downPaymentDollar);
          const downPaymentPercent = (downPaymentDollar / homeValue) * 100;
          if (downPaymentDollar > homeValue) {
            setValue('downPaymentDollar', homeValue);
          }
          setValue('downPaymentPercent', downPaymentPercent);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, downPaymentPercent]);

  // update maxDownPaymentDollar
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'homeValue') {
        const homeValue = cleanWholeNumberStr(values.homeValue);
        setMaxDownPaymentDollar(homeValue);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, downPaymentPercent]);

  return (
    <FormContent formProviderProps={formMethods}>
      <form
        style={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}
        onSubmit={handleSubmit(onSubmit)}>
        <Grid className='tool-form-container'>
          <SelectInput<Domain.MortgageRatesFormData>
            error={formState.errors.taxFilingState !== undefined}
            helperText={formState.errors.taxFilingState?.message?.toString()}
            defaultValue={mortgageRatesFormData?.taxFilingState}
            name='taxFilingState'
            label='WhichStateDoYouLiveIn'
            placeholder='ChooseState'>
            {Domain.UsStatesListItems.map((x) => (
              <MenuItem value={x.key} key={x.key}>
                {x.value}
              </MenuItem>
            ))}
          </SelectInput>
          <ToggleRadioButtonGroup<Domain.MortgageRatesFormData>
            error={formState.errors.refinance !== undefined}
            helperText={formState.errors.refinance?.message?.toString()}
            name='refinance'
            label='WhatTypeOfRate'
            row
            defaultValue={
              typeof mortgageRatesFormData?.refinance === 'undefined'
                ? ''
                : mortgageRatesFormData?.refinance?.toString()
            }
            items={[
              { label: 'Purchase', value: 'false' },
              { label: 'Refinance', value: 'true' },
            ]}
          />
          <SliderPercent<Domain.MortgageRatesFormData>
            error={formState.errors.homeValue !== undefined}
            helperText={formState.errors.homeValue?.message?.toString()}
            defaultValue={mortgageRatesFormData?.homeValue}
            name='homeValue'
            label={refinance ? 'EstimatedHomeValue' : 'HomePurchasePrice'}
            type='dollar'
            min={100000}
            initialMax={1000000}
            step={10000}
            helpContext={refinance ? <EstimatedHomeValueHelp /> : <HomePurchasePriceHelp />}
          />
          <Grid display={!refinance ? 'none' : ''}>
            <SliderPercent<Domain.MortgageRatesFormData>
              error={formState.errors.currentLoanBalance !== undefined}
              helperText={formState.errors.currentLoanBalance?.message?.toString()}
              defaultValue={mortgageRatesFormData?.currentLoanBalance}
              name='currentLoanBalance'
              label='CurrentLoanBalance'
              type='dollar'
              min={100000}
              initialMax={1000000}
              step={10000}
            />
          </Grid>
          <Grid display={refinance ? 'none' : ''}>
            <SliderInputTypeToggle
              onToggle={() => setDownPaymentPercent((prev) => !prev)}
              PercentInput={
                <SliderPercent<Domain.MortgageRatesFormData>
                  error={formState.errors.downPaymentPercent !== undefined}
                  helperText={formState.errors.downPaymentPercent?.message?.toString()}
                  defaultValue={mortgageRatesFormData?.downPaymentPercent}
                  name='downPaymentPercent'
                  label='DownPayment'
                  helpContext={<DownPaymentHelp />}
                />
              }
              DollarInput={
                <SliderPercent<Domain.MortgageRatesFormData>
                  error={formState.errors.downPaymentDollar !== undefined}
                  helperText={formState.errors.downPaymentDollar?.message?.toString()}
                  defaultValue={mortgageRatesFormData?.downPaymentDollar}
                  name='downPaymentDollar'
                  label='DownPayment'
                  type='dollar'
                  max={maxDownPaymentDollar}
                  initialMax={maxDownPaymentDollar}
                  step={10000}
                  helpContext={<DownPaymentHelp />}
                />
              }
            />
          </Grid>
          <SelectInput<Domain.MortgageRatesFormData>
            error={formState.errors.loanType !== undefined}
            helperText={formState.errors.loanType?.message?.toString()}
            defaultValue={mortgageRatesFormData?.loanType}
            name='loanType'
            label='DesiredLoanType'
            placeholder='LoanType'>
            {Domain.loanTypes.map((x) => (
              <MenuItem value={x.key} key={x.key}>
                {x.value}
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput<Domain.MortgageRatesFormData>
            error={formState.errors.termType !== undefined}
            helperText={formState.errors.termType?.message?.toString()}
            defaultValue={mortgageRatesFormData?.termType}
            name='termType'
            label='DesiredLoanProgram'
            placeholder='LoanProgram'>
            {Domain.mortgageTermTypes.map((x) => (
              <MenuItem value={x.key} key={x.key}>
                {x.value}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Button type='submit' style={{ width: '45%', alignSelf: 'end' }} label='Calculate' />
      </form>
    </FormContent>
  );
};

export default MortgageRatesForm;
