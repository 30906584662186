import { Api, Hooks } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { Colors, Spacing } from '../../../themes';
import GroupPanels from '../../../components/GroupPanels';
import { Button } from '../../../components/buttons/Button';
import { useService } from '@aesop-fables/containr-react';
import { useLoading } from '../../../hooks/useLoading';

const CourseCertificatePanel: React.FC = () => {
  const course = Hooks.useUniversityCourse();
  const api = useService<Api.IUniversityApi>(Api.ApiKeys.University);
  const { setLoading } = useLoading();

  const downloadCertificate = async () => {
    try {
      setLoading(true);
      const response = await api.getGraduationCertificate();
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'guide.pdf');
      document.body.appendChild(link);
      link.click();
      window.open(url);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GroupPanels
      variant='Collapsible'
      groups={[
        {
          textKey: '3NickelsUCertificate',
          style: {
            background: `linear-gradient(${
              course?.progress === 100 ? Colors.secondaryLight : Colors.primaryLight
            } 0 0) bottom left / ${course?.progress ?? 0}% 8px no-repeat, white`,
          },
          content: (
            <Grid
              container
              padding={Spacing.xxxs + 1}
              display='flex'
              flexDirection='column'
              rowGap='10px'
              alignItems='center'
              justifyContent='space-between'
              textAlign='center'>
              <Svgs.DisplayMediumCustomRibbon />
              {course?.progress === 100 ? (
                <>
                  <Typography variant='p16Bold' color='secondary'>
                    Congratulations on completing the course!
                  </Typography>
                  <Button
                    variant='ghost'
                    color='primary'
                    label='ViewMyCertificate'
                    onClick={downloadCertificate}
                  />
                </>
              ) : (
                <Typography variant='p16Bold' color='secondary'>
                  Complete the course to get your personalized certificate!
                </Typography>
              )}
              <Typography variant='p12' color='primary' fontWeight='bold' mt='10px'>
                {Math.round(course?.progress ?? 0)}% complete
              </Typography>
            </Grid>
          ),
          url: '/',
        },
      ]}
    />
  );
};

export default CourseCertificatePanel;
