import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Api, Data, Domain, Hooks } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import { Svgs } from '../../../../assets/svg';
import ToolHeader from '../../../../components/ToolHeader';
import { useLoading } from '../../../../hooks/useLoading';
import FormContent from '../../../../components/form/FormContent';
import HowMuchHouseCalculatorForm from './HowMuchHouseCalculatorForm';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import HowMuchHouseCalculatorResults from './HowMuchHouseCalculatorResults';
import { useMessage } from '../../../../hooks/useMessage';
import { useSubject } from '@aesop-fables/scrinium';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';

const formSchema = Yup.object({
  cashForDownPayment: Yup.string().required('Required'),
  interestRate: Yup.string().required('Required'),
  mortgageTermType: Yup.string().required('Required'),
});

const cleanHouseAffordValues = (values: Api.AdviceHouseAffordabilityRest) => {
  return {
    cashForDownPayment: Number((values?.cashForDownPayment?.toString() ?? '').replace(/[$,]/g, '')),
    interestRate: values?.interestRate ? Number(values?.interestRate) : undefined,
    mortgageTermType: values?.mortgageTermType,
  };
};

const HowMuchHouseCalculatorView: React.FC = () => {
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Api.HouseAffordabilityResultRest | undefined>(undefined);
  const [pdfParams, setPdfParams] = useState<Api.AdviceHouseAffordabilityRest | undefined>(
    undefined
  );
  const savings = useSubject(Data.Savings.TotalSavingsSubject);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Api.AdviceHouseAffordabilityRest>(
    SavedFormsKeyEnum.HowMuchHouse
  );

  const defaultValues: Api.AdviceHouseAffordabilityRest = useMemo(
    () =>
      formData
        ? { ...formData, cashForDownPayment: savings?.totalSavings }
        : {
            cashForDownPayment: Number(
              (results?.downPaymentDollars?.toString() ?? '').replace(/[$,]/g, '')
            ),
            interestRate: results?.interestRate ? Number(results?.interestRate) : undefined,
            mortgageTermType: results?.mortgageTermType,
          },
    [results, formData]
  );

  const methods = useForm<Api.AdviceHouseAffordabilityRest>({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (!results && typeof savings?.totalSavings !== 'undefined') {
      onSubmit({ ...formData, cashForDownPayment: savings?.totalSavings });
    }
  }, [savings]);

  const onSubmit = async (values: Api.AdviceHouseAffordabilityRest) => {
    setLoading(true);

    try {
      const cleanedValues = cleanHouseAffordValues(values);
      setPdfParams(cleanedValues);

      const result = await commands.execute(
        Data.Housing.Commands.CalculateAdviceAffordability,
        cleanedValues
      );
      const formDataToSave = cleanedValues.interestRate
        ? cleanedValues
        : {
            cashForDownPayment: Number(
              (result?.downPaymentDollars?.toString() ?? '').replace(/[$,]/g, '')
            ),
            interestRate: result?.interestRate ? Number(result?.interestRate) : undefined,
            mortgageTermType: result?.mortgageTermType,
          };
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.HowMuchHouse,
        formData: formDataToSave,
      });
      methods.setValue(
        'mortgageTermType',
        result?.mortgageTermType ?? Domain.MortgageTermTypeEnum['30-Year Fixed']
      );
      setResults(result);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    if (!pdfParams) {
      showMessage('We weren’t banking on that happening...please try again later.', 'error');
      return;
    }

    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Housing.Commands.CalculateAdviceAffordabilityPdf,
        pdfParams
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'HowMuchHouseCalculatorResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const header = (
    <ToolHeader
      icon={<Svgs.ArtHouseLarge />}
      title='HeresHowMuchHouseAfford'
      onDownload={onDownload}
    />
  );

  if (typeof formData === 'undefined') return null;
  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <ToolTemplate
            header={header}
            inputColumn={
              <HowMuchHouseCalculatorForm
                methods={methods}
                onSubmit={onSubmit}
                results={results}
                defaultValues={defaultValues}
              />
            }
            outputColumn={<HowMuchHouseCalculatorResults results={results} />}
            boxItems={[
              {
                boxTitle: 'TotalHomePrice',
                boxResult: results ? formatWholeDollars(results?.homePrice ?? 0) : undefined,
              },
            ]}></ToolTemplate>
        </Grid>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HowMuchHouseCalculatorView, meta);
