import ContentView from '../layout/Content';
import { useContentView } from '../layout/ContentViewContext';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { Breadcrumbs } from '../../components';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { cleanWholeNumberStr } from '../../helpers/utilityFunctions';

export declare type ToolsLayoutMeta = {
  background?: React.ReactNode;
};

const getBreadcrumbNameMap = (charity?: Domain.CharityData): { [key: string]: string } => {
  return {
    '/tools': 'Tools',
    '/tools/budget': 'Budget',
    '/tools/budget/cut-spending': '25 Easy Ways to Cut Spending',
    '/tools/budget/lifestyle': 'Lifestyle',
    '/tools/car': 'Car',
    '/tools/car/how-much-car': 'How Much Car Can I Afford?',
    '/tools/car/finance-or-pay-cash': 'Should I Finance or Pay Cash for My Car?',
    '/tools/car/car-buying-checklist': 'Car-Buying Checklist',
    '/tools/car/collision-insurance-breakeven': 'Collision Insurance Breakeven',
    '/tools/car/most-popular-cars': 'Most Popular Cars',
    '/tools/car/auto-insurance-need-to-know': 'What You Need to Know About Auto Insurance',
    '/tools/car/lifestyle': 'Lifestyle',
    '/tools/college': 'College',
    '/tools/college/cost-of-college': 'Cost of College',
    '/tools/college/college-savings-charts': 'College Savings Charts',
    '/tools/college/college-savings-plans': 'Types of College Savings Plans',
    '/tools/college/lifestyle': 'Lifestyle',
    '/tools/credit-card': 'Credit Card',
    '/tools/credit-card/credit-card-gotchas': 'Credit Card Gotcha’s',
    '/tools/credit-card/lifestyle': 'Lifestyle',
    '/tools/debt': 'Debt',
    '/tools/debt/real-cost-of-debt': 'The Real Cost of Debt',
    '/tools/debt/debt-consolidation-tool': 'Debt Consolidation Tool',
    '/tools/debt/how-much-debt': 'How Much Debt Can Your Income Support?',
    '/tools/debt/lifestyle': 'Lifestyle',
    '/tools/gifts': 'Gifts',
    '/tools/gifts/introduction-to-giving': 'Introduction to Giving',
    '/tools/gifts/give-to-your-church': 'Give to Your Church (Tithe.ly)',
    '/tools/gifts/tax-benefits-of-donations': 'Tax Benefits of Donations',
    '/tools/gifts/search-for-charity': 'Search for a Charity',
    [`/tools/gifts/search-for-charity/${charity?.orgId}`]: `${charity?.charityName}`,
    '/tools/gifts/lifestyle': 'Lifestyle',
    '/tools/goals': 'Goals',
    '/tools/goals/inflation-calculator': 'Inflation Calculator',
    '/tools/goals/lifestyle': 'Lifestyle',
    '/tools/house': 'House',
    '/tools/house/rent-or-buy': 'Should I Rent or Buy?',
    '/tools/house/how-much-house': 'How Much House Can I Afford?',
    '/tools/house/home-sale-net-proceeds': 'Home Sale Net Proceeds',
    '/tools/house/mortgage-rates': 'Mortgage Rates',
    '/tools/house/house-buying-checklist': 'House-Buying Checklist',
    '/tools/house/mortgage-refinance-checklist': 'Mortgage Refinance Checklist',
    '/tools/house/mortgage-refinance-calculator': 'Mortgage Refinance Calculator',
    '/tools/house/cost-of-living-comparison': 'Cost of Living Comparison',
    '/tools/house/house-selling-checklist': 'House-Selling Checklist',
    '/tools/house/lifestyle': 'Lifestyle',
    '/tools/investment': 'Investment',
    '/tools/investment/basic-investing-terms': 'Basic Investing Terms',
    '/tools/investment/investment-account-checklist': 'Investment Account Checklist',
    '/tools/investment/shop-investment-accounts': 'Shop Investment Accounts',
    '/tools/investment/lifestyle': 'Lifestyle',
    '/tools/loans': 'Loans',
    '/tools/loans/personal-loan-calculator': 'Personal Loan Calculator',
    '/tools/loans/personal-loan-checklist': 'Personal Loan Checklist',
    '/tools/loans/lifestyle': 'Lifestyle',
    '/tools/medical': 'Medical',
    '/tools/medical/types-of-health-insurance': 'Types of Health Insurance',
    '/tools/medical/health-insurance-checklist': 'Health Insurance Checklist',
    '/tools/medical/lifestyle': 'Lifestyle',
    '/tools/retirement': 'Retirement',
    '/tools/retirement/ira-fee-checker': 'IRA Fee Checker',
    '/tools/retirement/why-save-now': "Why Save Now?…Even If You're In Your 20's",
    '/tools/retirement/lifestyle': 'Lifestyle',
  };
};

const ToolsLayout: React.FC = () => {
  const location = useLocation();
  const { meta, setMeta } = useLayoutMeta<ToolsLayoutMeta>();
  const { sideNav } = useContentView();
  const { id: charityId } = useParams();
  const dispatcher = Hooks.useObservableQueryDispatcher();
  const [charityData, setCharityData] = useState<Domain.CharityData | undefined>(undefined);

  const pathnameSegments = location.pathname.split('/');
  const breadcrumbNameMap = getBreadcrumbNameMap(charityData);

  useEffect(() => {
    const getCharityDetails = async (id: number) => {
      const charity = await dispatcher.execute(Data.Charity.FindCharityDetails, id);
      setCharityData(charity);
    };

    if (typeof charityId !== 'undefined') {
      getCharityDetails(cleanWholeNumberStr(charityId));
    }
  }, [charityId]);

  return (
    <ContentView
      sideNavMenuItems={sideNav('Tools')}
      background={meta.background}
      leftChildren={
        <Breadcrumbs pathnameSegments={pathnameSegments} breadcrumbNameMap={breadcrumbNameMap} />
      }>
      <Outlet context={{ setMeta }} />
    </ContentView>
  );
};

export default ToolsLayout;
