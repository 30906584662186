/* tslint:disable */
import OnboardingView from '../pages/onboarding/OnboardingView';
import AccountDetailsLayout from '../pages/account-details/AccountDetailsLayout';
import PersonalDetailsLayout from '../pages/personal-details/PersonalDetailsLayout';
import { RouteObject } from 'react-router';
import BudgetDetailsLayout from '../pages/budget-details/BudgetDetailsLayout';
import HolisticFinancialAdviceLayout from '../pages/holistic-financial-advice/HolisticFinancialAdviceLayout';
import { PersonalDetailsRoutes } from './PersonalDetailsRoutes';
import { AccountDetailsRoutes } from './AccountDetailsRoutes';
import { BudgetDetailsRoutes } from './BudgetDetailsRoutes';
import { HolisticFinancialAdviceRoutes } from './HolisticFinancialAdviceRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { LoaderFunction } from 'react-router-dom';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { wireUpLoaders } from './utils';
import { incomeReadyLoader } from './loaders/IncomeReadyLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { commonRoutes } from './CommonRoutes';
import { accountDetailsLoader, linkAccountLoader } from './loaders/AccountDetailsLoader';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';
import UniversityLayout from '../pages/university/UniversityLayout';
import UniversityOverview from '../pages/university/UniversityOverview';
import UniversityLessonView from '../pages/university/UniversityLessonView';
import { universityLoader } from './loaders/UniversityLoader';
import { UserEventLogger } from './UserEventLogger';

const adviceOnboardingRoutes: RouteObject[] = [
  {
    path: '',
    element: <UserEventLogger />,
    children: [
      {
        index: true,
        path: '/',
        element: <OnboardingView />,
      },
      {
        path: 'personal-details',
        element: <PersonalDetailsLayout />,
        children: PersonalDetailsRoutes,
      },
      {
        path: 'account-details',
        element: <AccountDetailsLayout headerVariant='addAccount' />,
        children: AccountDetailsRoutes,
      },
      {
        path: 'budget-details',
        element: <BudgetDetailsLayout />,
        children: BudgetDetailsRoutes,
      },
      {
        path: 'holistic-advice',
        element: <HolisticFinancialAdviceLayout />,
        children: HolisticFinancialAdviceRoutes,
      },
      {
        path: 'university',
        element: <UniversityLayout />,
        children: [
          { index: true, element: <UniversityOverview /> },
          {
            path: ':title',
            element: <UniversityLessonView />,
          },
        ],
      },
      ...commonRoutes,
    ],
  },
];

export function createAdviceOnboardingRoutes(container: IServiceContainer) {
  const loaders = new Map<string, LoaderFunction>();
  // load advice on homescreen
  loaders.set('/', async () => {
    await Promise.all([appReadyLoader(container)]);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });
  loaders.set('personal-details', () => Promise.all([incomeReadyLoader(container)]));
  // reload advice in holistic-advice
  loaders.set('account-details', () =>
    Promise.all([incomeReadyLoader(container), accountDetailsLoader(container)])
  );
  loaders.set('account-details/link-account', ({ request }) =>
    linkAccountLoader(request, container)
  );
  loaders.set('budget-details', () => Promise.all([incomeReadyLoader(container)]));
  loaders.set('university', () => universityLoader(container));
  loaders.set('settings/update-password', () => authTokenCookieLoader(container));

  return wireUpLoaders(adviceOnboardingRoutes, loaders);
}
