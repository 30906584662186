import { json } from 'react-router-dom';
import { IServiceContainer } from '@aesop-fables/containr';
import { ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { Data } from '@3nickels/data-modules';
import { waitFor } from '../utils';

export async function authTokenCookieLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const isReady$ = resolver.resolveSubjectByKey<boolean>(Data.TwoFactor.TwoFactorServices.Predicates.ReadyToLoad);
  await waitFor(isReady$);
  return json('auth-token-ready');
}
