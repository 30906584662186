/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Hooks } from '@3nickels/data-modules';
import { Box, Grid, Typography } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';
import { Button } from '../../../../components/buttons/Button';
import GroupPanels, { Group } from '../../../../components/GroupPanels';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { GoalsLayoutMeta } from '../../GoalsLayout';

const GivingCollegeView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wizard } = Hooks.useCollegeGoalWizard();

  const collegeOptions: Group[] = [
    {
      textKey: 'CreateACollegeSavingsGoal',
      subTextKey: 'ReadyToStartSavingNow?',
      icon: <Svgs.DisplaySmallCustomGoals />,
      onClick: async () => {
        await wizard.start({});
        navigate('/goals/college/basics');
      },
    },
    {
      textKey: 'ExploreCollegeTools',
      subTextKey: 'WantToFirstGetAnIdea?',
      icon: <Svgs.IconColllege />,
      onClick: () => navigate('/tools/college'),
    },
  ];

  return (
    <Box>
      <Grid container flexWrap='nowrap' justifyContent='space-between' mt='10px'>
        <Grid item>
          <Typography color='primary' component='h1' variant='h1'>
            {t('GivingCollegeTuition')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('WhenYouWouldLikeToHelpWithCollege')}
          </Typography>
        </Grid>
        <Svgs.ArtUni3N width={210} height={'auto'} />
      </Grid>
      <Grid container flexDirection='column'>
        <Typography color='secondary' variant='p18Bold'>
          {t('WhereWouldYouLikeToStart?')}
        </Typography>
      </Grid>
      <Grid className='college-giving-goal-panels'>
        <GroupPanels groups={collegeOptions} />
      </Grid>
      <Grid item sm={2} mt={Spacing.xxs}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  title: 'Giving',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(GivingCollegeView, meta);
