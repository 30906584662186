import { Box } from '@mui/material';
import React from 'react';
import { useLifestyleProductsByCategory } from '../../../hooks/useLifestyleProductsByCategory';
import { park } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import CostOfCollegeCard from './cards/CostOfCollegeCard';
import CollegeSavingsPlansCard from './cards/CollegeSavingsPlansCard';
import CollegeSavingsChartsCard from './cards/CollegeSavingsChartsCard';
import LifestyleToolsCard from '../LifestyleToolsCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';

const CollegeToolStartView: React.FC = () => {
  const lifestyleProducts = useLifestyleProductsByCategory();

  const calculators: Group[] = [
    {
      textKey: 'cost-of-college',
      url: 'cost-of-college',
      content: <CostOfCollegeCard />,
    },
  ];

  const resources: Group[] = [
    {
      textKey: 'college-savings-plans',
      url: 'college-savings-plans',
      content: <CollegeSavingsPlansCard />,
    },
    {
      textKey: 'college-savings-charts',
      url: 'college-savings-charts',
      content: <CollegeSavingsChartsCard />,
    },
  ];

  const explore: Group[] =
    (lifestyleProducts?.length ?? 0) > 0
      ? [
          {
            textKey: 'lifestyle',
            url: 'lifestyle',
            content: <LifestyleToolsCard />,
          },
        ]
      : [];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.IconCollege2 />}
        label='College'
        calculators={calculators}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={park} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CollegeToolStartView, meta);
