/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Card, Grid, Link, styled, Typography } from '@mui/material';
import { Api, Domain } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { PricebookBundle, PricebookProductTypeEnum } from '../../../models/PricebookData';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import {
  formatDateMMDDYYYY,
  formatDecimalDollars,
  formatWholeDollars,
} from '../../../helpers/utilityFunctions';
import { campingBanner_v1 } from '../../../assets/png';
import { Button } from '../../../components/buttons/Button';
import { Colors } from '../../../themes';
import LearnMoreAdviceDrawer from '../../../sign-up/LearnMoreAdviceDrawer';
import LearnMoreAdviceAndThreeNickelsUDrawer from '../../../sign-up/LearnMoreAdviceAndThreeNickelsUDrawer';
import LearnMoreFreeDrawer from '../../../sign-up/LearnMoreFreeDrawer';
import { getSubscriptionStatus } from '../manage-payments/SubscriptionSection';
import UnsubscribeModal from './UnsubscribeModal';

export function getFootnoteElement(
  footnoteCounter: number,
  plan: PricebookBundle,
  inAdviceFreeTrial: boolean,
  freeTrialStatus?: Api.FreeTrialRest | undefined,
  userNeverSubscribedToExclusivePricebook?: boolean,
  manageSubscription?: boolean
) {
  let footnoteText: string | null = null;
  const orgPays = plan.pricebook.paidBy === Domain.PaidByEnum.ORG;
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const promotion = plan.prices.find((price) => price.introductionPeriodInDays > 1);
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30);
  const freeTrialDaysLeft = freeTrialStatus?.expirationDate
    ? Math.ceil(
        (new Date(freeTrialStatus.expirationDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
      )
    : 0;

  if (!(free || orgPays)) {
    footnoteCounter++;
    if (promotion) {
      footnoteText = `Initially a one-time payment. After the ${introPeriodInMonths}-month promotional period,
          Advice subscription auto-renews at $14.99/month.`;
    } else if (inAdviceFreeTrial && manageSubscription) {
      footnoteText = `${freeTrialDaysLeft} ${
        freeTrialDaysLeft === 1 ? 'day' : 'days'
      } left of free trial, subscription not required. Option to start an auto-renewing
          subscription of $14.99/month during or after 32-day free trial.`;
    } else if (userNeverSubscribedToExclusivePricebook) {
      footnoteText = `You’re not currently subscribed. If started, this subscription automatically renews at $14.99/month and will continue to auto-renew until you cancel.`;
    } else {
      footnoteText = `This subscription automatically renews every month and will continue to auto-renew until
          you cancel.`;
    }
  }

  return { footnoteCounter, footnoteText };
}

function getDrawerAndDescription(
  plan: PricebookBundle,
  pricebookMetadata: any,
  drawerOpen: boolean,
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const nickelsU = plan.pricebook.productType === PricebookProductTypeEnum.NICKELSU;

  let drawer: React.ReactNode | null = null;
  let description: string | null = null;

  if (free) {
    drawer = (
      <LearnMoreFreeDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        pricebookBundle={plan}
        pricebookMetadata={pricebookMetadata}
      />
    );
    description = 'Checklists and calculators to cover the basics.';
  } else if (nickelsU) {
    drawer = (
      <LearnMoreAdviceAndThreeNickelsUDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        pricebookBundle={plan}
        pricebookMetadata={pricebookMetadata}
      />
    );
    description = 'One-on-one coaching, financial education, and advice.';
  } else {
    drawer = (
      <LearnMoreAdviceDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        pricebookBundle={plan}
        pricebookMetadata={pricebookMetadata}
      />
    );
    description = 'Expert advice on your whole financial picture.';
  }

  return { drawer, description };
}

function getIntervalText(intervalType: Domain.PaymentIntervalEnum) {
  switch (intervalType) {
    case Domain.PaymentIntervalEnum.DAY:
      return 'day';
    case Domain.PaymentIntervalEnum.WEEK:
      return 'wk';
    case Domain.PaymentIntervalEnum.MONTH:
      return 'mo';
    case Domain.PaymentIntervalEnum.YEAR:
      return 'yr';
    default:
      '';
  }
}

function getSubtextsAndActionButton(
  plan: PricebookBundle,
  paymentApi: PaymentApi,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  subscriptionInfo: Domain.SubscriptionInfo | undefined,
  manageSubscription: boolean | undefined,
  inAdviceFreeTrial: boolean,
  freeTrialStatus: Api.FreeTrialRest | undefined,
  isCurrentPlan: boolean,
  orgDoesNotOfferFreePricebook: boolean | undefined,
  userNeverSubscribedToExclusivePricebook: boolean | undefined
) {
  const orgPays = plan.pricebook.paidBy === Domain.PaidByEnum.ORG;
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const promotion = plan.prices.find((price) => price.introductionPeriodInDays > 1);
  const nickelsU = plan.pricebook.productType === PricebookProductTypeEnum.NICKELSU;
  const inNickelsUPromo = subscriptionInfo?.status === 'trialing' && nickelsU;
  const resubscribe = subscriptionInfo?.status === 'cancelled' && isCurrentPlan;
  const unsubscribe =
    orgDoesNotOfferFreePricebook &&
    (subscriptionInfo?.status === 'active' || subscriptionInfo?.status === 'trialing') &&
    isCurrentPlan;
  const downgrade = free && !isCurrentPlan;
  const freeTrialDaysLeft = freeTrialStatus?.expirationDate
    ? Math.ceil(
        (new Date(freeTrialStatus.expirationDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
      )
    : 0;

  let paymentSubtext: string | null = null;
  let descriptionSubtext: string | null = null;
  let descriptionSubtextColor = 'secondary';
  let actionButton: React.ReactNode | null = null;

  if (manageSubscription) {
    if (inNickelsUPromo) {
      paymentSubtext = 'after promo period';
      descriptionSubtext = `Next payment of ${formatDecimalDollars(
        subscriptionInfo?.price / 100
      )} on ${formatDateMMDDYYYY(subscriptionInfo?.expirationDate)}`;
    } else if (inAdviceFreeTrial) {
      paymentSubtext = 'after free trial';
      descriptionSubtext = `${freeTrialDaysLeft} ${
        freeTrialDaysLeft === 1 ? 'day' : 'days'
      }  left of free trial`;
    } else if (subscriptionInfo?.status === 'active' && !orgPays) {
      descriptionSubtext = `Next payment of ${formatDecimalDollars(
        subscriptionInfo?.price / 100
      )} on ${formatDateMMDDYYYY(subscriptionInfo?.expirationDate)}`;
    } else if (subscriptionInfo) {
      const status = getSubscriptionStatus(subscriptionInfo);
      if (status.cancelled || status.expired) {
        const text = status.expired ? 'Expired' : 'Expires';
        descriptionSubtext = `${text} ${formatDateMMDDYYYY(subscriptionInfo?.expirationDate)}`;
        descriptionSubtextColor = 'error.main';
      }
    } else if (userNeverSubscribedToExclusivePricebook) {
      descriptionSubtext = 'Not currently subscribed';
      descriptionSubtextColor = 'error.main';
    }
  } else {
    if (promotion) {
      paymentSubtext = 'one-time payment';
    }
    if (unsubscribe || downgrade) {
      actionButton = (
        <Button
          style={{ width: '70%', marginTop: '10px' }}
          color='primary'
          variant='outlined'
          label={unsubscribe ? 'Unsubscribe' : 'Downgrade'}
          onClick={() => setModalOpen(true)}></Button>
      );
    } else if (resubscribe || !isCurrentPlan) {
      actionButton = (
        <Button
          style={{
            width: '70%',
            marginTop: '10px',
          }}
          color='primary'
          variant='contained'
          label={resubscribe ? 'Resubscribe' : 'Select Plan'}
          onClick={async () => {
            const stripeUrl = await paymentApi.createSession(plan.pricebook.id);
            window.location.replace(stripeUrl.data);
          }}></Button>
      );
    }
  }

  if (orgPays) {
    descriptionSubtext = 'Your organization is paying for your subscription.';
    descriptionSubtextColor = 'secondary';
    paymentSubtext = null;
    actionButton = null;
  }

  return { paymentSubtext, descriptionSubtext, descriptionSubtextColor, actionButton };
}

const Banner = styled('div')({
  position: 'absolute',
  left: 0,
  bottom: 0,
  height: '15%',
  width: '100%',
  backgroundColor: Colors.primaryLightest,
  zIndex: 1,
});

interface PlanCardProps {
  plan: PricebookBundle;
  pricebookMetadata: any;
  subscriptionInfo: Domain.SubscriptionInfo | undefined;
  threeNickelsProducts: PricebookBundle[] | undefined;
  inAdviceFreeTrial: boolean;
  freeTrialStatus?: Api.FreeTrialRest | undefined;
  footnoteIndex?: number;
  manageSubscription?: boolean;
  currentPlan?: PricebookBundle | undefined;
  orgDoesNotOfferFreePricebook?: boolean | undefined;
  userNeverSubscribedToExclusivePricebook?: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  pricebookMetadata,
  subscriptionInfo,
  threeNickelsProducts,
  inAdviceFreeTrial,
  freeTrialStatus,
  footnoteIndex,
  manageSubscription,
  currentPlan,
  orgDoesNotOfferFreePricebook,
  userNeverSubscribedToExclusivePricebook,
}) => {
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const isCurrentPlan = plan.pricebook.id === currentPlan?.pricebook.id;
  const orgPays = plan.pricebook.paidBy === Domain.PaidByEnum.ORG;
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const nickelsU = plan.pricebook.productType === PricebookProductTypeEnum.NICKELSU;
  const promotion = plan.prices.find((price) => price.introductionPeriodInDays > 1);

  const metadata = useMemo(() => {
    if (
      typeof pricebookMetadata === 'undefined' ||
      Object.keys(pricebookMetadata ?? {}).length === 0
    )
      return;
    return pricebookMetadata.find((metadata: any) => metadata.id === plan.pricebook.id);
  }, [plan, pricebookMetadata]);

  const { drawer, description } = getDrawerAndDescription(
    plan,
    metadata,
    drawerOpen,
    setDrawerOpen
  );

  const { paymentSubtext, descriptionSubtext, descriptionSubtextColor, actionButton } =
    getSubtextsAndActionButton(
      plan,
      paymentApi,
      setModalOpen,
      subscriptionInfo,
      manageSubscription,
      inAdviceFreeTrial,
      freeTrialStatus,
      isCurrentPlan,
      orgDoesNotOfferFreePricebook,
      userNeverSubscribedToExclusivePricebook
    );
  const usePriceAfterPromo = manageSubscription && nickelsU && promotion;
  const index = usePriceAfterPromo ? 1 : 0;
  const price =
    plan.prices[index].price === 1499
      ? formatDecimalDollars(plan.prices[index].price / 100)
      : formatWholeDollars(plan.prices[index].price / 100);
  const intervalText = getIntervalText(plan.prices[index].intervalType);

  return (
    <Card className='plan-card' style={{ position: 'relative', overflow: 'hidden' }}>
      {drawer}
      {subscriptionInfo && (
        <UnsubscribeModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          subscription={subscriptionInfo}
          threeNickelsProducts={threeNickelsProducts}
        />
      )}
      <Grid container style={{ position: 'relative', zIndex: 2, minHeight: '210px' }}>
        <Grid xs={6} container direction='column' justifyContent='space-between' rowGap={1}>
          <Typography variant='p18Bold' color='secondary'>
            {free ? 'Basic Tools' : plan.pricebook.pricebookName}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {description}
          </Typography>
          <Link mt={1} variant='p16' onClick={() => setDrawerOpen(true)}>
            See Details
          </Link>
          {actionButton && actionButton}
          <div style={{ flexGrow: 1 }} />
          {descriptionSubtext && (
            <Typography
              variant='p12'
              color={descriptionSubtextColor}
              style={{ paddingBottom: '25px' }}>
              {descriptionSubtext}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction='column'
          style={{ height: '100%', alignItems: 'flex-end', minHeight: '210px' }}
          rowGap='5px'>
          {!orgPays ? (
            free ? (
              <Typography variant='p22Bold' color='primary' style={{ paddingRight: '20px' }}>
                FREE
              </Typography>
            ) : (
              <>
                <Typography variant='p22Bold' color='primary' style={{ paddingRight: '20px' }}>
                  {price}
                  {footnoteIndex && footnoteIndex > 0 ? '*'.repeat(footnoteIndex) : null}&nbsp;
                  {intervalText && (
                    <Typography variant='p12' color='primary' fontWeight='normal'>
                      / {intervalText}
                    </Typography>
                  )}
                </Typography>
                <Typography variant='p12' color='primary' style={{ paddingRight: '20px' }}>
                  {paymentSubtext}
                </Typography>
              </>
            )
          ) : (
            <div />
          )}
          <div style={{ flexGrow: 1 }} />
          {!free && (
            <div
              style={{
                width: '100%',
                paddingBottom: '50%',
                backgroundImage: `url(${nickelsU ? plan.pricebook.artUrl : campingBanner_v1})`,
                backgroundSize: nickelsU ? '155%' : '100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          )}
        </Grid>
      </Grid>
      {isCurrentPlan && (
        <Banner>
          <Typography variant='p14Bold' color='secondary' style={{ padding: '10px 20px' }}>
            Current plan
          </Typography>
        </Banner>
      )}
    </Card>
  );
};

export default PlanCard;
