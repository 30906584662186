import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Grid, Typography, Box } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../assets/svg';
import { GroupEdit } from '../../../components/GroupEdit';
import { formatWholeDollars } from '../../../helpers/utilityFunctions';
import { Modal, Spacer } from '../../../components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MutatorState } from '@aesop-fables/scrinium';
import { useLoading } from '../../../hooks/useLoading';
import { PiggyBankSvg } from '../../../components/images/PiggyBankSvg';
import { usePlaidCredentialRefresh } from '../../../hooks/usePlaidCredentialRefresh';

interface CashAccountViewProps {
  cashAccounts: Domain.CashAccountData[];
  deleteCashAccount: MutatorState<number>;
}

export const CashAccountView: React.FC<CashAccountViewProps> = ({
  cashAccounts,
  deleteCashAccount,
}) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { wizard } = Hooks.useCashAccountWizard();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.CashAccountData>();
  const { setIdAndUpdateLogin } = usePlaidCredentialRefresh();

  const handleEdit = (x: Domain.CashAccountData) => {
    wizard.start({ id: x.id });
    navigate(`/account-details/cash-account/summary/${x.id}`);
  };

  const handleDeleteCashAccount = (account: Domain.CashAccountData) => {
    if (account.id) {
      setLoading(true);
      deleteCashAccount.action(account.id);
    }
  };

  if (cashAccounts.length === 0) return null;

  return (
    <>
      <Grid display='none'>
        <Modal
          title='Remove Account?'
          primaryButtonText='No, Keep Account'
          secondaryButtonText='Yes, Remove Account'
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={() => {
            if (selectedAccount) {
              handleDeleteCashAccount(selectedAccount);
            }
          }}>
          <Typography variant='p16' color='secondary'>
            This will remove all of the information for this account. Are you sure you want to do
            that?
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p16Bold' color='secondary'>
            {selectedAccount?.name}
          </Typography>
          <Typography variant='p16Bold' color='secondary'>
            {t(Data.CashAccounts.mapCashAccountEnumToType(selectedAccount?.accountType))}
          </Typography>
        </Modal>
      </Grid>
      <Grid item xs={12} className='account-view'>
        <Typography
          className='account-type-header'
          display='flex'
          alignItems='center'
          color='primary'
          variant='p18Bold'>
          {PiggyBankSvg({ fontSize: 'large' })}
          {t('CashAccounts', { number: cashAccounts.length })}
        </Typography>
        <Box>
          <GroupEdit<Domain.CashAccountData>
            className='account-list'
            items={cashAccounts}
            keyFn={(x) => `${x.id ?? 0}`}
            mainContentFn={(x) => (
              <Grid container>
                <Grid marginRight='10px'>
                  <Typography
                    className='hover-clickable'
                    color='secondary'
                    fontWeight='bold'
                    onClick={() => handleEdit(x)}>
                    {x.name}
                  </Typography>
                  <Grid direction='row' display='flex' alignItems='center'>
                    <Typography marginTop='5px' color='secondary' variant='p12'>
                      {x.linkedInstitution?.institutionName ?? 'Manually Added'}
                    </Typography>
                    {x.linkedInstitution?.requiresLogin && (
                      <>
                        <Typography ml={'10px'} marginTop='5px' color='secondary' variant='p12'>
                          |
                        </Typography>
                        <Typography
                          ml={'10px'}
                          onClick={() => {
                            setIdAndUpdateLogin(x.linkedInstitution?.accessId ?? 0);
                          }}
                          marginTop='5px'
                          sx={{
                            '&:hover': {
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            },
                          }}
                          color='accent'
                          component='span'
                          variant='p12'>
                          Login Expired
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid className='account-view-status-icon' height='20px'>
                  {x.isLinked && (
                    <Grid display='flex' alignItems='center'>
                      <Svgs.LinkPaperClip />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            summaryFn={(x) => (
              <Grid>
                <Typography color='secondary'>{formatWholeDollars(x.principle ?? 0)}</Typography>
              </Grid>
            )}
            needsAttention={(x) => x.linkedInstitution?.requiresLogin}
            stackEndContent
            onDelete={(x) => {
              setSelectedAccount(x);
              setModalOpen(true);
            }}
            onEdit={(x) => {
              handleEdit(x);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};
