import { type FC } from 'react';
import { useObservable, useProjection } from '@aesop-fables/scrinium';
import { Data } from '@3nickels/data-modules';
import { combineLatest } from 'rxjs';
import ContentView from '../layout/Content';
import DetailsView from './OnboardingDetails';
import { useContentView } from '../layout/ContentViewContext';

export type OnborderingViewProps = Record<string, unknown>;

const OnboardingView: FC<OnborderingViewProps> = () => {
  const onboardingStates = useProjection(Data.Users.Projections.OnboardingStates);
  const { sideNav } = useContentView();

  const [personalDetailsComplete, accountDetailsComplete, budgetDetailsReviewed] = useObservable(
    combineLatest([
      onboardingStates.onboardingComplete$,
      onboardingStates.accountDetailsComplete$,
      onboardingStates.budgetDetailsReviewed$,
    ])
  ) ?? [false, false, false];

  return (
    <ContentView sideNavMenuItems={sideNav('Home')}>
      <DetailsView
        loading={false}
        personalDetailsComplete={personalDetailsComplete}
        accountDetailsComplete={accountDetailsComplete}
        budgetDetailsComplete={budgetDetailsReviewed}
      />
    </ContentView>
  );
};

export default OnboardingView;
