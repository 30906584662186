import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogActions, Grid } from '@mui/material';
import { Domain } from '@3nickels/data-modules';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { PricebookBundle } from '../../../models/PricebookData';
import { Button } from '../../../components/buttons/Button';
import { resolveShowManagePayments } from '../AccountSettingsView';
import UnsubscribeModal from './UnsubscribeModal';

const getPrimaryButtonDetails = (
  plan: PricebookBundle,
  subscription: Domain.SubscriptionInfo | undefined,
  orgPaysPricebook: PricebookBundle | undefined,
  inAdviceFreeTrial: boolean,
  paymentApi: PaymentApi,
  multipleProducts: boolean,
  orgOnlyOffersFreePricebook: boolean,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const navigate = useNavigate();
  const resubscribe =
    subscription?.status !== 'active' && !multipleProducts && !orgOnlyOffersFreePricebook;

  if (multipleProducts) {
    return {
      text: 'Change Plan',
      onClick: () => navigate('/settings/manage-subscription/change-plan'),
    };
  }

  if (subscription?.status === 'active' && !orgPaysPricebook) {
    return { text: 'Unsubscribe', onClick: () => setModalOpen(true) };
  }

  if ((resubscribe || inAdviceFreeTrial) && !orgPaysPricebook) {
    return {
      text: 'Subscribe',
      onClick: async () => {
        const stripeUrl = await paymentApi.createSession(plan.pricebook.id);
        window.location.replace(stripeUrl.data);
      },
    };
  }
};

interface PlanActionGroupButtonsProps {
  plan: PricebookBundle;
  subscriptions: Domain.SubscriptionInfo[] | undefined;
  threeNickelsProducts: PricebookBundle[] | undefined;
  orgPaysPricebook: PricebookBundle | undefined;
  inAdviceFreeTrial: boolean;
  paymentApi: PaymentApi;
  multipleProducts: boolean;
  orgOnlyOffersFreePricebook: boolean;
}

const PlanActionGroupButtons: React.FC<PlanActionGroupButtonsProps> = ({
  plan,
  subscriptions,
  threeNickelsProducts,
  orgPaysPricebook,
  inAdviceFreeTrial,
  paymentApi,
  multipleProducts,
  orgOnlyOffersFreePricebook,
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptions) return [];
    return subscriptions.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptions]);
  const subscription = subscriptionsByStartDateDescending?.[0];
  const addOns = false;
  const primaryButton = getPrimaryButtonDetails(
    plan,
    subscription,
    orgPaysPricebook,
    inAdviceFreeTrial,
    paymentApi,
    multipleProducts,
    orgOnlyOffersFreePricebook,
    setModalOpen
  );
  const showManagePayments = resolveShowManagePayments(
    orgPaysPricebook,
    addOns,
    orgOnlyOffersFreePricebook,
    subscriptions
  );

  const onManagePayments = () => navigate('/settings/manage-payments');

  return (
    <>
      {subscription && (
        <UnsubscribeModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          subscription={subscription}
          threeNickelsProducts={threeNickelsProducts}
        />
      )}
      <Grid container mt={primaryButton || showManagePayments ? 3 : -1}>
        <DialogActions className='browser-dialog'>
          {primaryButton && (
            <Button
              color='primary'
              variant={primaryButton?.text === 'Subscribe' ? undefined : 'outlined'}
              label={primaryButton.text}
              onClick={primaryButton.onClick}></Button>
          )}
          {showManagePayments && (
            <Button
              color='primary'
              variant={primaryButton ? undefined : 'outlined'}
              className={primaryButton ? 'ghostAlt' : undefined}
              label='ManagePayments'
              onClick={onManagePayments}></Button>
          )}
        </DialogActions>
      </Grid>
    </>
  );
};

export default PlanActionGroupButtons;
