/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import FormContent from '../../../../components/form/FormContent';
import { useForm } from 'react-hook-form';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { yupResolver } from '@hookform/resolvers/yup';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useMessage } from '../../../../hooks/useMessage';
import { useLoading } from '../../../../hooks/useLoading';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import RentOrBuyForm from './RentOrBuyForm';
import RentOrBuyResults from './RentOrBuyResults';
import { Svgs } from '../../../../assets/svg';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';

const formSchema = Yup.object({
  monthlyRent: Yup.string()
    .required('Required')
    .test('monthlyRent', 'Required', (amount) => {
      const cleanNumber = cleanWholeNumberStr(amount);
      return cleanNumber > 0;
    }),
  monthlyRentersInsurance: Yup.string().notRequired(),
  homePrice: Yup.string()
    .required('Required')
    .test('homePrice', 'Required', (amount) => {
      const cleanNumber = cleanWholeNumberStr(amount);
      return cleanNumber > 0;
    }),
  downPayment: Yup.string()
    .required('Required')
    .test('downPayment', 'Required', function (amount) {
      const cleanNumber = cleanWholeNumberStr(amount);
      return cleanNumber > 0;
    })
    .test(
      'isGreaterThanHomePrice',
      'Down payment must not exceed home price',
      function (downPayment) {
        const { homePrice } = this.parent;
        const downPaymentValue = cleanWholeNumberStr(downPayment);
        const homePriceValue = cleanWholeNumberStr(homePrice);
        return downPaymentValue <= homePriceValue;
      }
    ),
  mortgageInterestRate: Yup.string()
    .required('Required')
    .test('mortgageInterestRate', 'Required', (amount) => {
      const cleanNumber = cleanWholeNumberStr(amount);
      return cleanNumber > 0;
    }),
  mortgageTermType: Yup.string().required('Required'),
  annualHomeownersInsurance: Yup.string().notRequired(),
  monthlyCondoFees: Yup.string().notRequired(),
  annualMaintenanceCosts: Yup.string().notRequired(),
  annualPropertyTax: Yup.string().notRequired(),
  otherAnnualHousingCosts: Yup.string().notRequired(),
  annualHomeValueIncrease: Yup.string().notRequired(),
});

const RentOrBuyView: React.FC = () => {
  const { showMessage } = useMessage();
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const [pdfParams, setPdfParams] = useState<Domain.AdviceHrobFormData | undefined>(undefined);
  const [results, setResults] = useState<Domain.AdviceHrobResultData | undefined>(undefined);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.AdviceHrobFormData>(SavedFormsKeyEnum.RentOrBuy);
  const defaultValues = formData ?? {
    mortgageTermType: Domain.MortgageTermTypeEnum['30-Year Fixed'],
  };
  const methods = useForm<Domain.AdviceHrobFormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const formatInputs = (values: any): Domain.AdviceHrobFormData => {
    const formData: Domain.AdviceHrobFormData = {
      monthlyRent: cleanWholeNumberStr(values.monthlyRent ?? 0),
      monthlyRentersInsurance: cleanWholeNumberStr(values.monthlyRentersInsurance ?? 0),
      homePrice: cleanWholeNumberStr(values.homePrice ?? 0),
      downPayment: cleanWholeNumberStr(values.downPayment ?? 0),
      mortgageInterestRate: cleanWholeNumberStr(values.mortgageInterestRate ?? 0, {
        float: true,
        precision: 3,
      }),
      mortgageTermType: values.mortgageTermType,
      annualHomeownersInsurance: cleanWholeNumberStr(values.annualHomeownersInsurance ?? 0),
      monthlyCondoFees: cleanWholeNumberStr(values.monthlyCondoFees ?? 0),
      annualMaintenanceCosts: cleanWholeNumberStr(values.annualMaintenanceCosts ?? 0),
      annualPropertyTax: cleanWholeNumberStr(values.annualPropertyTax ?? 0),
      otherAnnualHousingCosts: cleanWholeNumberStr(values.otherAnnualHousingCosts ?? 0),
      annualHomeValueIncrease: cleanWholeNumberStr(values.annualHomeValueIncrease ?? 0, {
        float: true,
        precision: 3,
      }),
    };

    return formData;
  };

  const onSubmit = async (values: Domain.AdviceHrobFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      const resultData = await commands.execute(
        Data.Housing.Commands.CalculateAdviceRentOrBuy,
        cleanedValues
      );
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.RentOrBuy,
        formData: cleanedValues,
      });
      setResults(resultData);
    } catch (err) {
      showMessage('We weren’t banking on that happening...please try again later.', 'info');
    } finally {
      setLoading(false);
    }
  };

  const downloadPdf = async () => {
    if (pdfParams) {
      try {
        setLoading(true);
        const response = await commands.execute(
          Data.Housing.Commands.CalculateAdviceRentOrBuyPdf,
          pdfParams
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'RentOrBuyResults.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const breakEvenPoint = results?.breakEvenPoint ?? 0;
  const cumulativeBuyCostData = results?.cumulativeBuyCost ?? [];
  const breakEvenYear = breakEvenPoint <= 0 ? cumulativeBuyCostData.length - 1 : breakEvenPoint;

  if (typeof formData === 'undefined') return null;

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <ToolTemplate
            header={
              results && (
                <ToolHeader
                  icon={<Svgs.ArtHouseLarge />}
                  title='HeresYourBreakEvenTimeline'
                  onDownload={downloadPdf}
                />
              )
            }
            inputColumn={
              <RentOrBuyForm
                methods={methods}
                defaultValues={defaultValues}
                results={results}
                onSubmit={onSubmit}
              />
            }
            outputColumn={<RentOrBuyResults results={results} />}
            boxItems={[
              {
                boxTitle: 'BreakEvenTimeline',
                boxResult: results ? `Year ${breakEvenYear}` : undefined,
              },
            ]}
          />
        </Box>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(RentOrBuyView, meta);
