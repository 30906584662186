import { useTranslation } from 'react-i18next';
import { Card, Divider, Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Svgs } from '../../../assets/svg';
import { PricebookBundle, PricebookProductTypeEnum } from '../../../models/PricebookData';
import { useState } from 'react';
import { Button } from '../../../components/buttons/Button';
import { Colors } from '../../../themes';
import { Domain } from '@3nickels/data-modules';

const resolveHeaders = (products: PricebookBundle[]) => {
  const headers = [];
  if (
    products.some((p) => p.prices[0].price === 0 && p.pricebook.paidBy === Domain.PaidByEnum.USER)
  ) {
    headers.push('Basic Tools');
  }
  if (
    products.some(
      (p) => p.pricebook.productType === PricebookProductTypeEnum.NICKELS && p.prices[0].price > 0
    )
  ) {
    headers.push('Advice');
  }
  if (products.some((p) => p.pricebook.productType === PricebookProductTypeEnum.NICKELSU)) {
    headers.push('Advice + 3NickelsU');
  }
  return headers;
};

const resolveFeatureIcon = (feature: string) => {
  switch (feature) {
    case 'One-on-one coaching':
      return <Svgs.DisplaySmallCustomMembers style={{ marginRight: '20px' }} />;
    case 'Live Q&A':
      return <Svgs.DisplaySmallCustomAdvice style={{ marginRight: '20px' }} />;
    case '7+ hours of educational videos':
      return <Svgs.DisplaySmallCustomVideoCamera style={{ marginRight: '20px' }} />;
    case 'Financial planning':
      return <Svgs.DisplaySmallCustomGoals style={{ marginRight: '20px' }} />;
    case 'Investment advice':
      return <Svgs.DisplaySmallCustomInvestment style={{ marginRight: '20px' }} />;
    case 'Account linking':
      return <Svgs.DisplaySmallCustomBank style={{ marginRight: '20px' }} />;
    case 'Spending overview':
      return <Svgs.DisplaySmallCustomLoans style={{ marginRight: '20px' }} />;
    case 'Advanced mini-courses':
      return <Svgs.IconColllege style={{ marginRight: '20px' }} />;
    case 'Personalized tools':
      return <Svgs.DisplaySmallCustomCalculator style={{ marginRight: '20px' }} />;
    case 'Budgeting':
      return <Svgs.DisplaySmallCustomBudget style={{ marginRight: '20px' }} />;
    default:
      return <Svgs.DisplaySmallCustomBook style={{ marginRight: '20px' }} />;
  }
};

const BasicTools = ['Personalized tools', 'Budgeting', 'Basic mini-courses'];
const Advice = [
  'Financial planning',
  'Investment advice',
  'Account linking',
  'Spending overview',
  'Advanced mini-courses',
  'Personalized tools',
  'Budgeting',
  'Basic mini-courses',
];
const AdviceAnd3NU = [
  'One-on-one coaching',
  'Live Q&A',
  '7+ hours of educational videos',
  'Financial planning',
  'Investment advice',
  'Account linking',
  'Spending overview',
  'Advanced mini-courses',
  'Personalized tools',
  'Budgeting',
  'Basic mini-courses',
];

type HeadersDataType = {
  'Basic Tools': string[];
  'Advice': string[];
  'Advice + 3NickelsU': string[];
  [key: string]: string[];
};

interface PlanFeaturesTableProps {
  products: PricebookBundle[];
}

const PlanFeaturesTable: React.FC<PlanFeaturesTableProps> = ({ products }) => {
  const { t } = useTranslation();
  const headers = resolveHeaders(products);
  const [expanded, setExpanded] = useState(false);
  const headersData: HeadersDataType = {
    'Basic Tools': BasicTools,
    'Advice': Advice,
    'Advice + 3NickelsU': AdviceAnd3NU,
  };
  const allFeatures = [...new Set([...BasicTools, ...Advice, ...AdviceAnd3NU])];

  allFeatures.sort((featureA, featureB) => {
    const totalChecksA = headers.reduce(
      (total, header) => total + (headersData[header].includes(featureA) ? 1 : 0),
      0
    );
    const totalChecksB = headers.reduce(
      (total, header) => total + (headersData[header].includes(featureB) ? 1 : 0),
      0
    );

    if (totalChecksA > totalChecksB) return 1;
    if (totalChecksA < totalChecksB) return -1;
    return 0;
  });

  return (
    <>
      <Button
        className='ghostAlt'
        sx={{ textDecoration: 'none !important', paddingLeft: '0px !important', marginTop: '15px' }}
        onClick={() => setExpanded(!expanded)}
        disableRipple>
        <Grid item justifySelf='center' alignSelf='center'>
          <Typography variant='p18Bold' color='secondary'>
            {headers.length == 1 ? t('WhatsIncluded') : t('CompareOptions')}
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: '8px', marginTop: '5px' }}>
          {!expanded && <KeyboardArrowDownIcon fontSize='large' color='primary' />}
          {expanded && <KeyboardArrowUpIcon fontSize='large' color='primary' />}
        </Grid>
      </Button>

      {expanded && (
        <Grid
          container
          item
          xs={headers.length > 2 ? 12 : headers.length === 2 ? 8 : 6}
          spacing={3}>
          <Grid item xs={6} sm={6} />
          {headers.map((header, i) => (
            <Grid
              item
              xs={Math.floor(6 / headers.length)}
              sm={Math.floor(6 / headers.length)}
              key={i}
              container
              justifyContent='center'
              alignItems='center'
              style={{ padding: '0px 15px' }}>
              <Typography variant='p18Bold' color='primary'>
                {header}
              </Typography>
            </Grid>
          ))}

          <Grid container item xs={12}>
            <Card className='card-row' style={{ width: '100%', padding: '15px' }}>
              {allFeatures.map((feature, i) => {
                const shouldRenderRow = headers.some((header) =>
                  headersData[header].includes(feature)
                );
                return (
                  shouldRenderRow && (
                    <>
                      <Grid container item xs={12} key={i}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          justifyContent='center'
                          alignItems='center'
                          style={{ padding: '15px' }}>
                          <Typography variant='p16' color='secondary'>
                            {resolveFeatureIcon(feature)}
                            {feature}
                          </Typography>
                        </Grid>
                        {headers.map((header: string, j: number) => (
                          <Grid
                            item
                            xs={Math.floor(6 / headers.length)}
                            sm={Math.floor(6 / headers.length)}
                            key={j}
                            container
                            justifyContent='center'
                            alignItems='center'
                            style={{ padding: '15px' }}>
                            {headersData[header].includes(feature) ? <Svgs.IconCheckmark /> : null}
                          </Grid>
                        ))}
                      </Grid>
                      {i < allFeatures.length - 1 && (
                        <Divider
                          sx={{ borderColor: Colors.primaryLightest }}
                          style={{ margin: '0px 15px' }}
                        />
                      )}
                    </>
                  )
                );
              })}
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PlanFeaturesTable;
