import { ButtonProps } from '../../../../components/buttons/Button';
import StatusCard, { LabelProps } from '../../../../components/StatusCard';
import { Grid } from '@mui/material';
import { Colors, Spacing } from '../../../../themes';
import { PlaidAccountStatusEnum } from '../Types';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { usePlaidAccountWizard } from '../PlaidHooks';
import { useLoading } from '../../../../hooks/useLoading';
import { MutationStatus, useMutation } from '@aesop-fables/scrinium';
import { useEffect, useMemo } from 'react';

export declare type PlaidAccountCategory = '401k' | 'Mortgage' | 'IRA' | 'Checking' | 'CreditCard';

export type PlaidAccount = {
  label: string;
  subLabel?: LabelProps;
  account: Domain.IAutoBindableAccount;
  status: PlaidAccountStatusEnum;
  editable: boolean; // part of card instead?
  disabled: boolean; // part of card instead?
  icon: React.ReactNode;
  onClick?: () => void;
};

type PlaidAccountCardButtonProps = {
  category: string;
  complete: boolean;
  imported: boolean;
  disabled: boolean;
  onClick?: () => void;
};

export const PlaidAccountCardButton = ({
  complete,
  imported,
  disabled,
  onClick,
}: PlaidAccountCardButtonProps): ButtonProps => {
  let buttonProps: Partial<ButtonProps> = {};
  if (!complete || !imported) {
    buttonProps = {
      ...buttonProps,
      label: 'FinishImport',
      color: 'primary',
      variant: 'contained',
    };
  }

  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return {
    ...{ ...buttonProps },
    fullWidth: true,
    disabled: disabled,
    onClick: clickHandler,
  } satisfies ButtonProps;
};

export interface PlaidAccountCardProps {
  accounts: PlaidAccount[];
  institution: Domain.PlaidInstitution;
}

export const PlaidAccountCard: React.FC<PlaidAccountCardProps> = ({ accounts }) => {
  const { setLoading } = useLoading();
  const wizard = usePlaidAccountWizard();
  const plaidWizard = Hooks.usePlaidWizard();
  const homeWizard = Hooks.useHomeWizard();
  const realEstateWizard = Hooks.useRealEstateWizard();
  const deleteDebt = useMutation(new Data.Debt.Mutations.DeleteDebt());
  const accountsList = useMemo(
    () => accounts.filter((account) => account.account.autoLinkStatus !== 'notLinked'),
    [accounts]
  );

  useEffect(() => {
    if (deleteDebt.error) {
      console.error(deleteDebt.error);
      return;
    }
    if (deleteDebt.status === MutationStatus.Complete) {
      finishRemoveAccount();
    }
  }, [deleteDebt.status]);

  const getStatusColor = (status: PlaidAccountStatusEnum) => {
    switch (status) {
      case PlaidAccountStatusEnum.Unlinked:
        return Colors.primaryBase;
      case PlaidAccountStatusEnum.Complete:
        return Colors.secondaryBase;
      case PlaidAccountStatusEnum.NotImported:
      default:
        return Colors.accentBase;
    }
  };

  const getStatusLabel = (account: PlaidAccount) => {
    if (
      account.status === PlaidAccountStatusEnum.Incomplete ||
      account.status === PlaidAccountStatusEnum.NotImported
    ) {
      if (account.account.subType === 'MORTGAGE') {
        return 'Missing property info';
      } else if (
        Domain.PlanTypeEnum.HSA.toString() === account.account.subType ||
        Domain.retirementPlanTypes.find((x) => x.toString() === account.account.subType)
      ) {
        return 'Missing contribution info';
      } else if (Domain.PlanTypeEnum['Brokerage Account'].toString() === account.account.subType) {
        return 'Needs review';
      }
    }

    return PlaidAccountStatusEnum[account.status];
  };

  const handleFinishImport = async (account: PlaidAccount) => {
    if (account.account.subType === 'MORTGAGE') {
      await Promise.all([
        homeWizard.wizard.start({
          bindableAccount: account.account,
          type: Domain.RealEstateTypeEnum.PERSONAL,
        }),
        realEstateWizard.wizard.start({
          bindableAccount: account.account,
          type: Domain.RealEstateTypeEnum.INVESTMENT,
        }),
      ]);
    }

    wizard.start(account);
  };

  const handleRemoveAccount = async (account: PlaidAccount) => {
    setLoading(true);
    await deleteDebt.action({
      id: account.account.financialAccountId,
    });
  };

  const finishRemoveAccount = async () => {
    await plaidWizard.reload();
    setLoading(false);
  };

  return (
    <Grid
      className='plaid-status-card'
      item
      display='flex'
      flexWrap={'inherit'}
      flexDirection={'column'}
      alignItems='stretch'
      gap={Spacing.xxs - 3}>
      {accountsList
        .sort((a, b) => {
          if (a.status === b.status) {
            return 0;
          } else if (a.status === PlaidAccountStatusEnum.Incomplete) {
            return -1;
          } else if (a.status === PlaidAccountStatusEnum.NotImported) {
            return 0;
          }
          return 1;
        })
        .map((account) => {
          const { label, icon, subLabel, status, editable, disabled } = account;
          return (
            <StatusCard
              key={account.account.name}
              label={{
                label,
                color: 'secondary',
              }}
              icon={icon}
              subLabel={{
                label: subLabel ? subLabel.label : getStatusLabel(account),
                color: subLabel ? subLabel.color : getStatusColor(status),
              }}
              endAdornmentVariant={
                editable &&
                status !== PlaidAccountStatusEnum.Unlinked &&
                status !== PlaidAccountStatusEnum.Complete
                  ? 'Button'
                  : undefined
              }
              disabled={disabled}
              containerProps={{
                raised: !disabled,
                style: {
                  boxShadow:
                    '0 4px 7px 1px rgba(30, 41, 105, 0.2), 0 -4px 7px 1px rgba(30, 41, 105, 0.2)',
                },
              }}
              endAdornmentProps={{
                ...PlaidAccountCardButton({
                  category: label,
                  complete: status === PlaidAccountStatusEnum.Complete,
                  imported: status !== PlaidAccountStatusEnum.NotImported,
                  disabled: disabled,
                  onClick: () => handleFinishImport(account),
                }),
              }}
              menuLinks={
                status === PlaidAccountStatusEnum.Incomplete
                  ? [
                      {
                        key: 'FinishImport',
                        onClick: () => handleFinishImport(account),
                      },
                      {
                        key: 'RemoveAccount',
                        onClick: () => handleRemoveAccount(account),
                      },
                    ]
                  : undefined
              }
            />
          );
        })}
    </Grid>
  );
};

export default PlaidAccountCard;
