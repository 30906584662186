import React, { useEffect } from 'react';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { Grid, InputLabel, Stack, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import '../../themes/tippy-light-nickels.css';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../../themes/ThemeWrapper';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormContext } from 'react-hook-form';
import { formatDateForApi } from '@3nickels/data-modules';

export type DatePickerProps<T> = {
  label: string;
  value?: Dayjs;
  name: keyof T;
  format?: string;
  disableFuture?: boolean;
  minDate?: Dayjs;
  helpContext?: React.ReactNode;
  helperText?: string;
  error?: boolean;
  required?: boolean;
};

export default function DefaultDatePicker<T>(props: DatePickerProps<T>) {
  const [value, setValue] = React.useState<Dayjs | undefined | null>(props.value);
  const { t, i18n } = useTranslation();
  const methods = useFormContext();

  useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === props.name) {
        setValue(dayjs(values[name]));
      }
    });
    return () => subscription.unsubscribe();
  });

  return (
    <Grid flexDirection='column'>
      <Grid
        item
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        display='flex'
        mb='6px'>
        <InputLabel
          className='date-picker-input-label'
          shrink={false}
          disableAnimation
          required={props.required}
          htmlFor={props.name.toString()}
          error={props.error}>
          <Typography variant='p16Bold' color='primary'>
            {t(props.label)}
          </Typography>
        </InputLabel>
        {props.helpContext && (
          <Grid item display='flex' justifyContent='flex-end'>
            <Tippy placement='right' content={props.helpContext} theme='light-nickels-theme'>
              <HelpOutline style={{ color: theme.palette.primary.main }} />
            </Tippy>
          </Grid>
        )}
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
        <Stack spacing={3}>
          <DesktopDatePicker
            format='MM/DD/YYYY'
            views={['year', 'month', 'day']}
            value={value}
            reduceAnimations
            disableFuture={props.disableFuture}
            minDate={props.minDate}
            onChange={(newValue: Dayjs | null) => {
              // setValue(newValue);
              methods.setValue(
                props.name.toString(),
                formatDateForApi(newValue?.format('MM/DD/YYYY') ?? ''),
                {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                }
              );
              methods.trigger(props.name.toString());
            }}
            slotProps={{
              textField: {
                placeholder: 'MM/DD/YYYY',
                variant: 'outlined',
                size: 'small',
                error: props.error,
                helperText: props.helperText,
                name: props.name.toString(),
                InputProps: { size: 'small' },
                InputLabelProps: { shrink: true, variant: 'standard' },
              },
              inputAdornment: { variant: 'standard' },
            }}
          />
        </Stack>
      </LocalizationProvider>
    </Grid>
  );
}

DefaultDatePicker.defaultProps = {
  format: 'MM/dd/yyyy',
  disableFuture: false,
};
