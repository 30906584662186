import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface KeyValuePairProps {
  label: string;
  value: string;
}

const KeyValuePair: React.FC<KeyValuePairProps> = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Typography variant='p16' color='secondary' align='left'>
          {t(`${label}`)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='p16Bold' color='primary' fontFamily='Montserrat' align='right'>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default KeyValuePair;
