/* tslint:disable */
import { LoaderFunction } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { commonRoutes } from './CommonRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { wireUpLoaders } from './utils';
import FreeView from '../pages/free/home/FreeView';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';
import { useSubject } from '@aesop-fables/scrinium';
import { AvailablePricebooks } from '../data/pricebook';
import LandingPage from '../dashboard/LandingPage';
import { UserEventLogger } from './UserEventLogger';

export const FreeHomePage = () => {
  const pricebookData = useSubject(AvailablePricebooks);
  const paidOptions = pricebookData?.filter(
    (bundle) => !bundle.pricebook.pricebookName.includes('Free')
  );

  if (!paidOptions) return null;

  return paidOptions.length > 0 ? <FreeView /> : <LandingPage />;
};

export const freeRoutes: RouteObject[] = [
  {
    path: '',
    element: <UserEventLogger />,
    children: [
      {
        path: '/',
        element: <FreeHomePage />,
      },
      ...commonRoutes,
    ],
  },
];

export function createFreeRoutes(container: IServiceContainer) {
  const loaders = new Map<string, LoaderFunction>();
  loaders.set('/', async () => {
    await appReadyLoader(container);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });
  loaders.set('settings/update-password', () => authTokenCookieLoader(container));
  loaders.set('settings/two-factor-authentication', () => authTokenCookieLoader(container));

  return wireUpLoaders(freeRoutes, loaders);
}
