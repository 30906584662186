import { Grid } from '@mui/material';
import React from 'react';
import SideNavigation, { type NickelsMenuItem } from '../../components/toc/SideNavigation';
import { Spacing } from '../../themes';
import { Header } from './Header';
import { AnimatePresence } from 'framer-motion';
import AnimatedTransition from '../../components/AnimatedTransiton';
import { useContentView } from './ContentViewContext';

export type ContentProps = {
  headerChildren?: React.ReactNode;
  leftChildren?: React.ReactNode;
  progressIndicator?: React.ReactNode;
  progressIndicatorPosition?: 'start' | 'end';
  sideNavMenuItems: NickelsMenuItem[];
  background?: React.ReactNode;
  children: React.ReactNode;
};

const ContentView: React.FC<ContentProps> = ({
  headerChildren,
  leftChildren,
  progressIndicator,
  progressIndicatorPosition = 'start',
  sideNavMenuItems,
  background,
  children,
}) => {
  const { expanded } = useContentView();
  return (
    <Grid container flexDirection='row' className='content-wrapper' id={expanded ? 'expanded' : ''}>
      <Header leftChildren={leftChildren}>{headerChildren}</Header>
      <Grid gridArea='sidebar'>
        <SideNavigation menuItems={sideNavMenuItems} />
      </Grid>
      <Grid
        className='content-view'
        gridArea='content'
        sx={{ overflow: 'scroll', maxHeight: '90vh' }}>
        {background}
        <AnimatePresence mode='wait'>
          <AnimatedTransition>
            <Grid className='content' item flexDirection='column'>
              {progressIndicatorPosition === 'start' && (
                <Grid pt={Spacing.xxs} pl={Spacing.xxxs}>
                  {progressIndicator}
                </Grid>
              )}
              <Grid className='children' p={Spacing.xxs}>
                {children}
              </Grid>
              {progressIndicatorPosition === 'end' && (
                <Grid pt={Spacing.xxs} pr={Spacing.xxxs}>
                  {progressIndicator}
                </Grid>
              )}
            </Grid>
          </AnimatedTransition>
        </AnimatePresence>
      </Grid>
    </Grid>
  );
};

export default ContentView;
