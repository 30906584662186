import { Domain } from '@3nickels/data-modules';
import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import { Row } from '../../../../components';
import { Colors } from '../../../../themes';
import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { HelpOutline, Straight } from '@mui/icons-material';
import { theme } from '../../../../themes/ThemeWrapper';
import { AdviceDebtIncomePercentageTypeEnum, getIsAboveRecommended } from './Types';

interface RatioSectionProps {
  results: Domain.DebtIncomePercentageData | undefined;
  noDebts: boolean;
}

export const RatioSection: React.FC<RatioSectionProps> = ({ results, noDebts }) => {
  return (
    <Grid item>
      <Stack spacing='10px'>
        <RatioRow
          icon={<Svgs.DisplaySmallCustomDebt />}
          title='DebtToIncomeRatio'
          ratioValue={results?.debtToIncomeRatio ?? 0}
          recommendedValue={AdviceDebtIncomePercentageTypeEnum.DebtToIncome}
          helpBody='DebtToIncomeRatioHelpText'
          noDebts={noDebts}
          debtSupported={getIsAboveRecommended(
            AdviceDebtIncomePercentageTypeEnum.DebtToIncome,
            results?.debtToIncomeRatio ?? 0
          )}
        />
        <RatioRow
          icon={<Svgs.DisplaySmallCustomHouse />}
          title='HousingCostRatio'
          ratioValue={results?.housingCostRatio ?? 0}
          recommendedValue={AdviceDebtIncomePercentageTypeEnum.HousingCost}
          helpBody='HousingCostRatioHelpText'
          noDebts={noDebts}
          debtSupported={getIsAboveRecommended(
            AdviceDebtIncomePercentageTypeEnum.HousingCost,
            results?.housingCostRatio ?? 0
          )}
        />
        <RatioRow
          icon={<Svgs.DisplaySmallCustomShoppingCart />}
          title='ConsumerDebtRatio'
          ratioValue={results?.consumerDebtRatio ?? 0}
          recommendedValue={AdviceDebtIncomePercentageTypeEnum.ConsumerDebt}
          helpBody='ConsumerDebtRatioHelpText'
          noDebts={noDebts}
          debtSupported={getIsAboveRecommended(
            AdviceDebtIncomePercentageTypeEnum.ConsumerDebt,
            results?.consumerDebtRatio ?? 0
          )}
        />
      </Stack>
    </Grid>
  );
};

interface RatioRowProps {
  icon: JSX.Element;
  title: string;
  ratioValue: number;
  recommendedValue: number;
  helpBody: string;
  noDebts: boolean;
  debtSupported: boolean;
}
const RatioRow: React.FC<RatioRowProps> = ({
  icon,
  title,
  ratioValue,
  recommendedValue,
  helpBody,
  noDebts,
  debtSupported,
}) => {
  const { t } = useTranslation();
  const isEqual = ratioValue === recommendedValue;
  const difference = debtSupported ? recommendedValue - ratioValue : ratioValue - recommendedValue;

  return (
    <Card className='debt-ratio'>
      <Row style={{ justifyContent: 'space-between', width: '100%' }}>
        <Row style={{ justifyContent: 'flex-start', alignItems: 'center', width: '40%' }}>
          {icon}
          <Typography ml={1} mr={1} color='primary' variant='p18Bold'>
            {t(title)}
          </Typography>
          <Tippy
            placement='right'
            content={<HelpContent title={title} helpBody={helpBody} />}
            theme='light-nickels-theme'>
            <HelpOutline style={{ color: theme.palette.primary.main }} />
          </Tippy>
        </Row>

        <Divider
          orientation='vertical'
          sx={{ borderColor: Colors.primaryLightest, margin: '0px 25px' }}
          flexItem
        />

        <Row style={{ justifyContent: 'center', width: '40%', alignItems: 'center' }}>
          {!noDebts && !isEqual && (
            <Row style={{ width: '35%', justifyContent: 'flex-end' }}>
              <Typography
                mr={1}
                variant='p18Bold'
                color={debtSupported ? 'secondary' : Colors.accentBase}>
                {formatPercent(difference)}
              </Typography>
              <Straight
                style={{
                  color: Colors.primaryBase,
                  transform: debtSupported ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </Row>
          )}
          <Typography
            ml={noDebts ? 0 : 1}
            variant='p14'
            color='primary'
            width={!noDebts && !isEqual ? '60%' : undefined}>
            Recommended max is {formatPercent(recommendedValue)}
          </Typography>
        </Row>

        <Divider
          orientation='vertical'
          sx={{ borderColor: Colors.primaryLightest, margin: '0px 25px' }}
          flexItem
        />

        <Row style={{ justifyContent: 'flex-end', width: '20%' }}>
          <Typography variant='p22Bold' color={debtSupported ? 'secondary' : Colors.accentBase}>
            {noDebts ? '– – –' : formatPercent(ratioValue)}
          </Typography>
        </Row>
      </Row>
    </Card>
  );
};

interface HelpContentProps {
  title: string;
  helpBody: string;
}
const HelpContent: React.FC<HelpContentProps> = ({ title, helpBody }) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Typography variant='p16Bold' color='secondary'>
        {t(title)}
      </Typography>
      <Typography variant='p16' color='secondary' mt={1}>
        {t(helpBody)}
      </Typography>
    </Grid>
  );
};
