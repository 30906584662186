/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, FormGroup, Divider, Backdrop, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Column, VerticalFormInput, Row } from '../components';
import { logo3NSmall, artMap, desktopLoginArt } from '../assets/png';
import { Form } from 'react-final-form';
import { SignUpLoginWizard, signUpLoginWizardKey } from '../services/signUpLogin';
import { useDeviceType } from '../hooks/useDeviceType';
import { mobileMargin } from '../App';
import { useService } from '@aesop-fables/containr-react';
import { useAuthenticationStatus } from '../hooks';
import { Loading } from '../hooks/useLoading';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { Colors } from '../themes';
import { useTranslation } from 'react-i18next';
import AppleLogin from './AppleLogin';
import GoogleLogin from './GoogleLogin';
import { useMessage } from '../hooks/useMessage';

interface LoginData {
  username?: string;
  password?: string;
}

const MemberLogin = () => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const { t } = useTranslation();

  return (
    <PageContentWithBackground
      useContainer={false}
      className={
        isMobile || isTablet ? 'mobile-login-with-background' : 'desktop-login-with-background'
      }>
      {(isMobile || isTablet) && (
        <>
          <img src={artMap} style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: '0' }} />
          <Column style={{ margin: mobileMargin, alignItems: 'center' }}>
            <img
              src={logo3NSmall}
              alt='logo'
              style={{ height: '55px', alignSelf: 'center', marginTop: mobileMargin }}
            />
            <LoginComponent />
          </Column>
        </>
      )}
      {isDesktop && (
        <Row style={{ height: '100%', justifyContent: 'space-between' }}>
          <Column
            style={{
              justifyContent: 'center',
              width: '55%',
              textAlign: 'center',
              marginBottom: '20px',
              paddingRight: '40px',
              paddingLeft: '40px',
            }}>
            <img src={desktopLoginArt} />
            <Typography variant='p30Bold' color='secondary' alignSelf='center' padding='10px'>
              {t('FinancialAdvisorInYourPocket')}
              <Typography paddingBottom='20px' paddingLeft='2px'>
                &reg;
              </Typography>
            </Typography>
          </Column>
          <Column
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '45%',
              backgroundColor: 'white',
              rowGap: '40px',
            }}>
            <img src={logo3NSmall} alt='logo' style={{ maxWidth: '400px' }} />
            <LoginComponent />
          </Column>
        </Row>
      )}
    </PageContentWithBackground>
  );
};

const LoginComponent: React.FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);

  const onSubmit = async (values: LoginData) => {
    const { username, password } = values;
    if (username && password) {
      try {
        await signUpLoginWizard.saveLoginDataAndAttemptLogin(username ?? '', password ?? '');
        navigate('/two-factor-auth');
      } catch (err) {
        console.error(err);
        showMessage('We weren’t banking on that happening...please try again later.', 'error');
      }
    }
  };

  const onValidateEmail = (value: string) => {
    return value ? undefined : 'Please enter an email';
  };

  const onValidatePassword = (value: string) => {
    return value ? undefined : 'Please enter a password';
  };

  const onForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Column style={{ zIndex: '1', maxWidth: '400px', width: '100%' }}>
          <FormGroup
            style={{
              alignSelf: 'center',
              width: '100%',
              rowGap: isDesktop ? '30px' : '15px',
              paddingTop: '5px',
              paddingBottom: isDesktop ? '45px' : '25px',
            }}>
            <VerticalFormInput<LoginData>
              fieldName='username'
              name='username'
              label='Email'
              validate={onValidateEmail}
            />
            <VerticalFormInput<LoginData>
              fieldName='password'
              name='password'
              label='Password'
              validate={onValidatePassword}
              helpContent={
                (isMobile || isTablet) && (
                  <Typography
                    className='clickable'
                    variant='p12'
                    color='primary'
                    onClick={onForgotPassword}>
                    Forgot Password?
                  </Typography>
                )
              }
            />
          </FormGroup>

          <Column
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              rowGap: isDesktop ? '20px' : '10px',
            }}>
            <Button type='submit' onClick={handleSubmit} style={{ width: '100%' }} color='primary'>
              Log In
            </Button>
            <AppleLogin />
            <GoogleLogin />
            {isDesktop && (
              <Button onClick={onForgotPassword} className='ghostAlt'>
                Forgot Password?
              </Button>
            )}
          </Column>
          <Divider
            color={Colors.primaryLight}
            style={{ margin: isDesktop ? '30px 0px 40px 0px' : '15px 0px' }}
            flexItem
          />
          <Button
            type='submit'
            onClick={() => navigate('/welcome')}
            style={{ width: '100%' }}
            color='primary'
            variant='outlined'>
            Create an Account
          </Button>
        </Column>
      )}
    </Form>
  );
};

const MemberLoginGuard: React.FC = () => {
  const { isReady } = useAuthenticationStatus();
  if (!isReady) {
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );
  }

  return <MemberLogin />;
};

export default MemberLoginGuard;
