import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { namespacedService } from "./utils";
import { BehaviorSubject, map, Observable } from "rxjs";
import { useService } from "@aesop-fables/containr-react";
import { inject, IServiceRegistry, ServiceCollection } from "@aesop-fables/containr";
import { Predicate } from "@aesop-fables/scrinium";

export const AppStateServices = {
  State: namespacedService('appState/state'),
};

export type AppStateEnum = 'active' | 'inactive';

export class AppState {
  private readonly _value$ = new BehaviorSubject<AppStateEnum>('active');

  get value$() {
    return this._value$.pipe();
  }

  setState(state: AppStateEnum) {
    this._value$.next(state);
  }
}

export const AppStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const state = useService<AppState>(AppStateServices.State);
  const visibilityChangedHandler = useCallback(() => {
    state.setState(document.hidden ? 'inactive' : 'active');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChangedHandler);
    return () => document.removeEventListener('visibilitychange', visibilityChangedHandler);
  }, []);
  return <>{children}</>;
};

export class AppStateRegistry implements IServiceRegistry {
  configureServices(services: ServiceCollection): void {
    services.singleton(AppStateServices.State, new AppState());
  }
}

export class ActiveAppStateLogPredicate implements Predicate {
  constructor(@inject(AppStateServices.State) private readonly state: AppState) {}

  createObservable(): Observable<boolean> {
    return this.state.value$.pipe(map((x) => x === 'active'));
  }
}