import { Box } from '@mui/material';
import React from 'react';
import { useLifestyleProductsByCategory } from '../../../hooks/useLifestyleProductsByCategory';
import { carLandscape } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import LifestyleToolsCard from '../LifestyleToolsCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import FinanceOrPayCashCard from './cards/FinanceOrPayCashCard';
import HowMuchCarCard from './cards/HowMuchCarCard';
import CollisionInsuranceCard from './cards/CollisionInsuranceCard';
import CarBuyingChecklistCard from './cards/CarBuyingChecklistCard';
import MostPopularCarsCard from './cards/MostPopularCarsCard';
import AutoInsuranceNeedToKnowCard from './cards/AutoInsuranceNeedToKnowCard';

const CarToolsStartView: React.FC = () => {
  const lifestyleProducts = useLifestyleProductsByCategory();

  const calculators: Group[] = [
    {
      textKey: 'finance-or-pay-cash',
      url: 'finance-or-pay-cash',
      content: <FinanceOrPayCashCard />,
    },
    {
      textKey: 'how-much-car',
      url: 'how-much-car',
      content: <HowMuchCarCard />,
    },
    {
      textKey: 'collision-insurance-breakeven',
      url: 'collision-insurance-breakeven',
      content: <CollisionInsuranceCard />,
    },
  ];

  const resources: Group[] = [
    {
      textKey: 'car-buying-checklist',
      url: 'car-buying-checklist',
      content: <CarBuyingChecklistCard />,
    },
    {
      textKey: 'most-popular-cars',
      url: 'most-popular-cars',
      content: <MostPopularCarsCard />,
    },
    {
      textKey: 'auto-insurance-need-to-know',
      url: 'auto-insurance-need-to-know',
      content: <AutoInsuranceNeedToKnowCard />,
    },
  ];

  const explore: Group[] =
    (lifestyleProducts?.length ?? 0) > 0
      ? [
          {
            textKey: 'lifestyle',
            url: 'lifestyle',
            content: <LifestyleToolsCard />,
          },
        ]
      : [];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplayMediumCustomCar />}
        label='Car'
        calculators={calculators}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={carLandscape} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CarToolsStartView, meta);
