import { Grid, Typography } from '@mui/material';
import FormContent from '../../../../components/form/FormContent';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import { useLoading } from '../../../../hooks/useLoading';
import { useMessage } from '../../../../hooks/useMessage';
import { Data, Domain, formatWholeDollars, Hooks, Services } from '@3nickels/data-modules';
import { useState } from 'react';
import ToolHeader from '../../../../components/ToolHeader';
import { Svgs } from '../../../../assets/svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TaxBenefitsCalculatorForm from './TaxBenefitsCalculatorForm';
import * as Yup from 'yup';
import TaxBenefitsCalculatorResults from './TaxBenefitsCalculatorResults';
import { useSubject } from '@aesop-fables/scrinium';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';

const formSchema = Yup.object({
  taxYear: Yup.string().required('Required'),
  selfAnnualIncome: Yup.string().required('Required'),
  selfOtherAnnualIncome: Yup.string().notRequired(),
  taxFilingStatus: Yup.string().required('Required'),
  taxFilingState: Yup.string().required('Required'),
  spouseAnnualIncome: Yup.string().notRequired(),
  spouseOtherAnnualIncome: Yup.string().notRequired(),
  itemizeDeductions: Yup.boolean().required('Required'),
  provideDonationDetails: Yup.boolean().when('itemizeDeductions', {
    is: (itemizeDeductions: boolean) => itemizeDeductions === true,
    then: (schema) => schema.required('Required'),
  }),
  estimatedDonationsForYear: Yup.string().when(['provideDonationDetails', 'itemizeDeductions'], {
    is: (provideDonationDetails: boolean, itemizeDeductions: boolean) =>
      !provideDonationDetails || !itemizeDeductions,
    then: (schema) => schema.required('Required'),
  }),
  donatedItemValue: Yup.string().notRequired(),
  cashDonations: Yup.string().notRequired(),
  mileageValue: Yup.string().notRequired(),
  totalOtherItemizedDeduction: Yup.string().when(['provideDonationDetails', 'itemizeDeductions'], {
    is: (provideDonationDetails: boolean, itemizeDeductions: boolean) =>
      provideDonationDetails === false || itemizeDeductions === true,
    then: (schema) =>
      schema
        .required('Required')
        .test('totalOtherItemizedDeduction', 'Required', (totalOtherItemizedDeduction) => {
          const cleanNumber = cleanWholeNumberStr(totalOtherItemizedDeduction);
          return cleanNumber > 0;
        }),
  }),
});

const cleanValues = (values: Domain.GtbdFormData) => {
  return {
    id: values.id,
    taxFilingStatus: Number(values.taxFilingStatus),
    taxFilingState: values.taxFilingState,
    taxYear: Number(values.taxYear),
    itemizeDeductions: values.itemizeDeductions,
    provideDonationDetails: values.provideDonationDetails,
    selfAnnualIncome: Number((values.selfAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')),
    selfOtherAnnualIncome: Number(
      (values.selfOtherAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')
    ),
    spouseAnnualIncome: Number((values.spouseAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')),
    spouseOtherAnnualIncome: Number(
      (values.spouseOtherAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')
    ),
    estimatedDonationsForYear: Number(
      (values.estimatedDonationsForYear?.toString() ?? '').replace(/[$,]/g, '')
    ),
    donatedItemValue: Number((values.donatedItemValue?.toString() ?? '').replace(/[$,]/g, '')),
    cashDonations: Number((values.cashDonations?.toString() ?? '').replace(/[$,]/g, '')),
    mileageValue: Number((values.mileageValue?.toString() ?? '').replace(/[$,]/g, '')),
    totalOtherItemizedDeduction: Number(
      (values.totalOtherItemizedDeduction?.toString() ?? '').replace(/[$,]/g, '')
    ),
  };
};

const TaxBenefitsCalculatorView: React.FC = () => {
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Domain.TaxBenefitsData | undefined>(undefined);
  const [pdfParams, setPdfParams] = useState<Domain.GtbdFormData | undefined>(undefined);
  const [activeTaxId, setActiveTaxId] = useState<number | undefined>(undefined);

  const selfData = Hooks.usePersonData();
  const spouseData = Hooks.useSpouseData();
  const incomes = Hooks.useIncomeData();
  const financialInfo = useSubject(Data.People.Projections.FinancialInfoSubject);

  const participant = useSubject<Services.ParticipantContext>(Services.Services.ParticipantContext);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.GtbdFormData>(
    SavedFormsKeyEnum.TaxBenefitsOfDonations
  );
  const defaultValues = formData ?? {
    personId: selfData?.personId ?? 0,
    spouseId: spouseData?.spouseId,
    taxYear: undefined,
    selfAnnualIncome: incomes?.income,
    selfOtherAnnualIncome: incomes?.otherIncome,
    taxFilingStatus: financialInfo?.taxFilingStatus,
    taxFilingState: financialInfo?.taxFilingState,
    spouseAnnualIncome: incomes?.spouseIncome,
    spouseOtherAnnualIncome: incomes?.spouseOtherIncome,
    itemizeDeductions: undefined,
    provideDonationDetails: undefined,
    estimatedDonationsForYear: undefined,
    donatedItemValue: undefined,
    cashDonations: undefined,
    mileageValue: undefined,
    totalOtherItemizedDeduction: undefined,
  };

  const methods = useForm<Domain.GtbdFormData>({
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: Domain.GtbdFormData) => {
    setLoading(true);
    try {
      const cleanedValues = cleanValues(values);
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.TaxBenefitsOfDonations,
        formData: cleanedValues,
      });
      const results = await commands.execute(
        Data.TaxDeduction.Commands.AddOrUpdateTaxData,
        Domain.mapGtbdFormDataToGtbdTaxData({
          ...cleanedValues,
          id: activeTaxId,
          accountId: selfData?.accountId,
          participantId: participant?.self.participantId,
          personId: selfData?.personId,
          spouseId: spouseData?.spouseId,
        })
      );
      setActiveTaxId(results.id);
      const rest = Domain.mapGtbdTaxDataToTaxBenefitsCalculationsRest(results);
      setPdfParams({ ...rest, id: results.id });
      const taxBenefits = await commands.execute(Data.TaxDeduction.Commands.CalculateTaxBenefits, {
        id: results.id ?? 0,
        taxBenefitsRest: rest,
      });
      setResults(taxBenefits);
    } catch (err) {
      console.error('Error Adding/Updating TaxData', err);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    if (!pdfParams) {
      showMessage('We weren’t banking on that happening...please try again later.', 'error');
      return;
    }

    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.TaxDeduction.Commands.CalculateTaxBenefitsPdf,
        {
          id: pdfParams.id ?? 0,
          taxBenefitsRest: pdfParams,
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'TaxBenefitsOfDonations.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const header = results ? (
    <ToolHeader
      icon={<Svgs.ArtLightbulbLarge />}
      title={
        <Typography variant='p30' color='primary'>
          {pdfParams?.itemizeDeductions ? 'Consider' : 'Consider taking the'}
          <Typography display='inline' variant='p30Bold' color='primary'>
            &nbsp;{pdfParams?.itemizeDeductions ? 'itemization' : 'standard deduction'}
          </Typography>
        </Typography>
      }
      onDownload={onDownload}
      helpContent={<HeaderHelpContent />}
    />
  ) : (
    <ToolHeader title='Start by adding details for a specific tax year' />
  );

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <ToolTemplate
            header={header}
            inputColumn={
              <TaxBenefitsCalculatorForm
                methods={methods}
                onSubmit={onSubmit}
                results={results}
                setResults={setResults}
                activeTaxId={activeTaxId}
                setActiveTaxId={setActiveTaxId}
                defaultValues={defaultValues}
              />
            }
            outputColumn={<TaxBenefitsCalculatorResults results={results} formData={pdfParams} />}
            boxItems={[
              {
                boxTitle: 'YourTaxSavings',
                boxResult: results ? formatWholeDollars(results?.totalTaxSavings ?? 0) : undefined,
              },
            ]}
          />
        </Grid>
      </form>
    </FormContent>
  );
};

const HeaderHelpContent: React.FC = () => {
  return (
    <Grid>
      <Typography variant='p16Bold' color='secondary'>
        Your Tax Strategy
      </Typography>
      <Typography variant='p16' color='secondary' mt={1}>
        Whether taking the standard deduction or itemizing is your strategy, we have calculated the
        amount you save by donating.
      </Typography>
    </Grid>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(TaxBenefitsCalculatorView, meta);
