/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { Api, Domain } from '@3nickels/data-modules';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Button } from '../../../../components/buttons/Button';
import SliderPercent from '../../../../components/form/SliderPercent';
import SelectInput from '../../../../components/form/SelectInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';

type FinanceOrPayCashCalculatorFormProps = {
  methods: UseFormReturn<Api.AdviceCarFinanceOrCashRest, any, undefined>;
  onSubmit: (values: Api.AdviceCarFinanceOrCashRest) => Promise<void>;
  results: Api.AdviceCarFinanceOrCashResultRest | undefined;
  defaultValues: Api.AdviceCarFinanceOrCashRest;
};

const FinanceOrPayCashCalculatorForm: React.FC<FinanceOrPayCashCalculatorFormProps> = ({
  methods,
  onSubmit,
  results,
  defaultValues,
}) => {
  const { formState } = useFormContext();
  const { t } = useTranslation();
  const [showAdvanced, setShowAdvanced] = useState(
    (defaultValues?.yearsToOwn ?? 0) > 0 ? true : false
  );

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const onClick = methods.handleSubmit((data) => {
    if (showAdvanced && Number(data.yearsToOwn) === 0) {
      methods.setError('yearsToOwn', { message: 'Required' });
      return;
    }
    // TODO add loading spinner for calculate button
    onSubmit({
      ...data,
      yearsToOwn: !showAdvanced ? undefined : data.yearsToOwn,
      savingsRate: !showAdvanced ? undefined : data.savingsRate,
    });
  });

  return (
    <Grid className='tool-form-container'>
      <SliderPercent<Api.AdviceCarFinanceOrCashRest>
        error={formState.errors.carPrice !== undefined}
        helperText={formState.errors.carPrice?.message?.toString()}
        label='CarPrice'
        name='carPrice'
        type='dollar'
        max={100000}
        step={1000}
        defaultValue={defaultValues.carPrice}
      />
      <SliderPercent<Api.AdviceCarFinanceOrCashRest>
        error={formState.errors.salesTaxRate !== undefined}
        helperText={formState.errors.salesTaxRate?.message?.toString()}
        label='SalesTaxRate'
        name='salesTaxRate'
        type='percent'
        precision={3}
        defaultValue={defaultValues.salesTaxRate}
      />
      <SliderPercent<Api.AdviceCarFinanceOrCashRest>
        error={formState.errors.downPayment !== undefined}
        helperText={formState.errors.downPayment?.message?.toString()}
        label='DownPayment'
        name='downPayment'
        type='dollar'
        max={100000}
        step={1000}
        defaultValue={defaultValues.downPayment}
      />
      <SliderPercent<Api.AdviceCarFinanceOrCashRest>
        error={formState.errors.interestRate !== undefined}
        helperText={formState.errors.interestRate?.message?.toString()}
        label='InterestRate'
        name='interestRate'
        type='percent'
        precision={3}
        defaultValue={defaultValues.interestRate}
      />
      <SelectInput<Api.AdviceCarFinanceOrCashRest>
        error={formState.errors.loanTerm !== undefined}
        helperText={formState.errors.loanTerm?.message?.toString()}
        label='LoanTerm'
        name='loanTerm'
        placeholder='ChooseLoanTerm'
        defaultValue={defaultValues.loanTerm}>
        {Domain.loanTermMonthsTypes.map((x) => (
          <MenuItem value={x.key}>{x.value}</MenuItem>
        ))}
      </SelectInput>
      <Typography
        className='no-underline-clickable'
        variant='p18Bold'
        color='secondary'
        width='170px'
        display='flex'
        justifyContent='space-between'
        onClick={() => setShowAdvanced((prev) => !prev)}>
        {showAdvanced ? t('HideAdvanced') : t('ShowAdvanced')}
        {showAdvanced ? (
          <KeyboardArrowUpIcon fontSize='large' color='primary' />
        ) : (
          <KeyboardArrowDownIcon fontSize='large' color='primary' />
        )}
      </Typography>

      {showAdvanced && (
        <>
          <SliderPercent<Api.AdviceCarFinanceOrCashRest>
            error={formState.errors.yearsToOwn !== undefined}
            helperText={formState.errors.yearsToOwn?.message?.toString()}
            label='YearsOwnVehicle'
            name='yearsToOwn'
            type='number'
            min={1}
            max={20}
            step={0.5}
            precision={3}
            defaultValue={defaultValues.yearsToOwn}
          />
          <SliderPercent<Api.AdviceCarFinanceOrCashRest>
            error={formState.errors.savingsRate !== undefined}
            helperText={formState.errors.savingsRate?.message?.toString()}
            label='SavingsInterestRate'
            name='savingsRate'
            type='percent'
            precision={3}
            defaultValue={defaultValues.savingsRate}
          />
        </>
      )}
      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default FinanceOrPayCashCalculatorForm;
