import { Box, MenuItem, Stack } from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../../../../../components/form/TextInput';
import { Spacing } from '../../../../../themes';
import { useFormContext } from 'react-hook-form';
import { Data, Domain } from '@3nickels/data-modules';
import SelectInput from '../../../../../components/form/SelectInput';

export type HsaContributionsFormProps = {
  contributions?: Data.InvestmentAccounts.HsaContributionsFormData;
  onSubmit: (values: Data.InvestmentAccounts.HsaContributionsFormData) => Promise<void>;
};

const HsaContributionsForm: React.FC<HsaContributionsFormProps> = (props) => {
  const { formState, setValue, trigger } = useFormContext();
  const [disableContributions, setDisableContributions] = useState(
    props.contributions?.coverageType === Domain.CoverageTypeEnum['No Longer Eligible']
  );

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <SelectInput<Data.InvestmentAccounts.HsaContributionsFormData>
          error={formState.errors.coverageType !== undefined}
          helperText={formState.errors.coverageType?.message?.toString()}
          defaultValue={props.contributions?.coverageType}
          label='MyHDHPCoverageType'
          placeholder='ChooseCoverageType'
          name='coverageType'>
          {Domain.CoverageTypeEnumList.map((x) => (
            <MenuItem
              value={x.key}
              onClick={() => {
                if (x.key === Domain.CoverageTypeEnum['No Longer Eligible']) {
                  setValue('annualContribPreTaxDollar', '', {
                    shouldValidate: true,
                  });
                  trigger('annualContribPreTaxDollar');
                  setDisableContributions(true);
                } else {
                  setDisableContributions(false);
                  if (formState.dirtyFields['annualContribPreTaxDollar']) {
                    trigger('annualContribPreTaxDollar');
                  }
                }
              }}>
              {x.value}
            </MenuItem>
          ))}
        </SelectInput>
        <TextInput<Data.InvestmentAccounts.HsaContributionsFormData>
          disabled={disableContributions}
          error={formState.errors.annualContribPreTaxDollar !== undefined}
          helperText={formState.errors.annualContribPreTaxDollar?.message?.toString()}
          defaultValue={props.contributions?.annualContribPreTaxDollar}
          label='AnnualContributions'
          name='annualContribPreTaxDollar'
          type='dollar'
        />
      </Stack>
    </Box>
  );
};

export default HsaContributionsForm;
