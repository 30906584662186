/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import FormContent from '../../../../components/form/FormContent';
import { useForm } from 'react-hook-form';
import { Api, Data, Domain, Hooks } from '@3nickels/data-modules';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { useMessage } from '../../../../hooks/useMessage';
import { useLoading } from '../../../../hooks/useLoading';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import CostOfCollegeForm from './CostOfCollegeForm';
import CostOfCollegeResults from './CostOfCollegeResults';
import { Svgs } from '../../../../assets/svg';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';

const formSchema = Yup.object({
  state: Yup.string().required('Required'),
  collegeType: Yup.string().required('Required'),
  onCampus: Yup.string().required('Required'),
});

const CostOfCollegeView: React.FC = () => {
  const { showMessage } = useMessage();
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const [pdfParams, setPdfParams] = useState<Domain.CcInfoFormData | undefined>(undefined);
  const [results, setResults] = useState<Api.CollegeCostRest | undefined>(undefined);
  const [noTuition, setNoTuition] = useState<boolean>(false);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.CcInfoFormData>(SavedFormsKeyEnum.CostOfCollege);
  const defaultValues = formData ?? {
    state: '',
    collegeType: undefined as unknown as Domain.CollegeTypesEnum,
    onCampus: undefined as unknown as boolean,
  };
  const methods = useForm<Domain.CcInfoFormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const formatInputs = (values: any): Domain.CcInfoFormData => {
    const formData: Domain.CcInfoFormData = {
      state: values.state,
      collegeType: values.collegeType,
      onCampus: values.onCampus === 'true',
    };

    return formData;
  };

  const onSubmit = async (values: Domain.CcInfoFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      const resultData = await commands.execute(
        Data.College.Commands.CalculateCostOfCollege,
        cleanedValues
      );
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.CostOfCollege,
        formData: cleanedValues,
      });
      setResults(resultData);
      if (resultData.tuition !== 0) {
        setNoTuition(false);
      } else {
        setNoTuition(true);
      }
    } catch (err) {
      showMessage('We weren’t banking on that happening...please try again later.', 'info');
    } finally {
      setLoading(false);
    }
  };

  const downloadPdf = async () => {
    if (pdfParams) {
      try {
        setLoading(true);
        const response = await commands.execute(
          Data.College.Commands.GetCostOfCollegePdf,
          pdfParams
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'CostOfCollegeResults.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const totalCost = results?.tuition === 0 ? '' : formatWholeDollars(results?.totalAnnualCost ?? 0);

  if (typeof formData === 'undefined') return null;

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <ToolTemplate
            header={
              results && (
                <ToolHeader
                  icon={<Svgs.ArtStudyMaterials />}
                  title='HeresYourCostSummary'
                  onDownload={downloadPdf}
                />
              )
            }
            inputColumn={
              <CostOfCollegeForm
                methods={methods}
                defaultValues={defaultValues}
                results={results}
                onSubmit={onSubmit}
              />
            }
            outputColumn={<CostOfCollegeResults results={results} noTuition={noTuition} />}
            boxItems={[
              {
                boxTitle: 'TotalAnnualCost',
                boxResult: results ? totalCost : undefined,
                // Some private tuitions come back with $0 from DB; in that case, we want the cost breakdown to be empty
                noResult: noTuition,
              },
            ]}
          />
        </Box>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CostOfCollegeView, meta);
