import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { Spacing } from '../../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../../hooks/useLoading';
import { useMessage } from '../../../../hooks/useMessage';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import OrgHeader from './OrgHeader';
import OrgMission from './OrgMission';
import OrgIncomeStatement from './OrgIncomeStatement';
import OrgContactInfo from './OrgContactInfo';

const SearchForCharityDetailsView: React.FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { id } = useParams();
  const dispatcher = Hooks.useObservableQueryDispatcher();
  const [charityData, setCharityData] = useState<Domain.CharityData | undefined>(undefined);

  useEffect(() => {
    const getCharityDetails = async (id: number) => {
      try {
        const charity = await dispatcher.execute(Data.Charity.FindCharityDetails, id);
        setCharityData(charity);
      } catch (error) {
        showMessage('We weren’t banking on that happening...please try again later.', 'info');
        navigate(-1);
      }
    };

    if (typeof id !== 'undefined') {
      getCharityDetails(cleanWholeNumberStr(id));
    }
  }, [id]);

  if (typeof charityData === 'undefined') {
    return <Loading />;
  }

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Stack spacing={Spacing.xxxs + 2}>
          <OrgHeader
            charityName={charityData.charityName}
            tagline={charityData.tagline}
            cause={charityData.cause}
            website={charityData.website}
          />
          <OrgMission mission={charityData.mission?.replace(/<br>/g, '\n')} />
          <OrgIncomeStatement
            totalRevenue={charityData?.totalRevenue ?? 0}
            totalExpenses={charityData?.totalExpenses ?? 0}
            fundraisingExpenses={charityData.fundraisingExpenses ?? 0}
          />
          <OrgContactInfo
            telephone={charityData.phone}
            fax={charityData.fax}
            email={charityData.email}
            primaryAddress={charityData.primaryAddress}
            secondaryAddress={charityData.secondaryAddress}
            city={charityData.city}
            state={charityData.state}
            zip={charityData.zip}
          />
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(SearchForCharityDetailsView, meta);
