import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import {
  DefaultPricebook,
  PricebookBundle,
  PricebookProductTypeEnum,
} from '../../../models/PricebookData';
import { useAvailableProducts } from '../../../hooks/pricebook/useAvailableProducts';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import PlanCard, { getFootnoteElement } from './PlanCard';
import PlanActionGroupButtons from './PlanActionGroupButtons';
import PlanFeaturesTable from './PlanFeaturesTable';
import Footnote from './Footnote';

export function getRecentPlan(
  subscription: Domain.SubscriptionInfo | undefined,
  inAdviceFreeTrial: boolean,
  threeNickelsProducts: PricebookBundle[] | undefined
): PricebookBundle {
  const advicePricebook =
    threeNickelsProducts?.find(
      (p) => p.pricebook.productType === PricebookProductTypeEnum.NICKELS && p.prices[0].price > 0
    ) ?? DefaultPricebook;
  const freePricebook =
    threeNickelsProducts?.find(
      (p) =>
        p.pricebook.productType === PricebookProductTypeEnum.NICKELS &&
        p.prices[0].price === 0 &&
        p.pricebook.paidBy === Domain.PaidByEnum.USER
    ) ?? DefaultPricebook;
  if (subscription) {
    if (subscription.origin === Domain.PaymentOriginEnum.STRIPE) {
      const pricebook = threeNickelsProducts?.find(
        (p) => p.pricebook.id === subscription.pricebookId
      );
      return pricebook ?? DefaultPricebook;
    } else {
      return advicePricebook;
    }
  } else if (inAdviceFreeTrial) {
    return advicePricebook;
  } else if (threeNickelsProducts && threeNickelsProducts.length === 1) {
    return threeNickelsProducts[0];
  } else {
    return freePricebook;
  }
}

const ManageSubscriptionView: React.FC = () => {
  const { t } = useTranslation();
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const freeTrialStatus = Hooks.useFreeTrialStatus();
  const legalDocs = Hooks.useTermsAndConditions();
  const {
    threeNickelsProducts,
    multipleProducts,
    orgOnlyOffersFreePricebook,
    orgPaysPricebook,
    orgDoesNotOfferFreePricebook,
  } = useAvailableProducts();
  const organizationMetadata = Hooks.useOrganizationMetadata();
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const termsApi = useService<Api.ITermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const inAdviceFreeTrial = freeTrialStatus ? freeTrialStatus.active : false;
  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptionInfoData) return [];
    return subscriptionInfoData.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptionInfoData]);
  const subscription = subscriptionsByStartDateDescending?.[0];
  const recentPlan = getRecentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);
  const userNeverSubscribedToExclusivePricebook =
    (!subscriptionInfoData || subscriptionInfoData.length === 0) &&
    orgDoesNotOfferFreePricebook &&
    !multipleProducts;

  const planForTable = threeNickelsProducts
    ? threeNickelsProducts.filter(
        (p) => p.pricebook.pricebookName === recentPlan.pricebook.pricebookName
      )
    : [];

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  let footnoteCounter = 0;
  const footnoteTexts: string[] = [];

  const { footnoteCounter: counter, footnoteText } = getFootnoteElement(
    footnoteCounter,
    recentPlan,
    inAdviceFreeTrial,
    freeTrialStatus,
    userNeverSubscribedToExclusivePricebook,
    true
  );
  if (footnoteText) {
    footnoteCounter = counter;
    footnoteTexts.push(footnoteText);
  }

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography mb={3} variant='p30Bold' component='h1' color='primary'>
          {t('ManageSubscription')}
        </Typography>
      </Grid>
      <PlanCard
        plan={recentPlan}
        pricebookMetadata={pricebookMetadata}
        subscriptionInfo={subscription}
        threeNickelsProducts={threeNickelsProducts}
        freeTrialStatus={freeTrialStatus}
        inAdviceFreeTrial={inAdviceFreeTrial}
        userNeverSubscribedToExclusivePricebook={userNeverSubscribedToExclusivePricebook}
        footnoteIndex={footnoteText ? footnoteCounter : undefined}
        manageSubscription
      />
      {footnoteTexts.map((text, index) => (
        <Grid item mt={3} xs={5} key={index}>
          <Footnote index={index} text={text} termsApi={termsApi} legalDocs={legalDocs} />
        </Grid>
      ))}
      <PlanActionGroupButtons
        plan={recentPlan}
        subscriptions={subscriptionInfoData}
        threeNickelsProducts={threeNickelsProducts}
        inAdviceFreeTrial={inAdviceFreeTrial}
        orgPaysPricebook={orgPaysPricebook}
        paymentApi={paymentApi}
        multipleProducts={multipleProducts}
        orgOnlyOffersFreePricebook={orgOnlyOffersFreePricebook}
      />
      {planForTable.length > 0 && <PlanFeaturesTable products={planForTable} />}
    </Box>
  );
};

const meta = {
  showBack: false,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(ManageSubscriptionView, meta);
