import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ContentView from '../layout/Content';
import { Grid, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { Modal } from '../../components';
import { useContentView } from '../layout/ContentViewContext';
import { useTranslation } from 'react-i18next';

export type BudgetDetailsLayout = Record<string, unknown>;

export declare type BudgetDetailsLayoutMeta = {
  headerVariant: 'home' | 'averages';
};

interface LeaveBudgetDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSave: (data?: any) => void;
  text?: string;
}

export const LeaveBudgetDetailsModal: React.FC<LeaveBudgetDetailsModalProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title='Leave Budget Details?'
      primaryButtonText='Leave Budget Details'
      secondaryButtonText='Stay Here'
      {...props}>
      <Typography variant='p16' color='secondary'>
        {t(props.text ?? 'GoToAccountDetails')}
      </Typography>
    </Modal>
  );
};

const BudgetDetailsHeader: React.FC = () => {
  return (
    <Typography variant='p18Bold' color='primary' alignSelf='end'>
      Budget Details
    </Typography>
  );
};

const HowDoesMyBudgetLookButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant='outlined'
      label='How Does My Budget Look?'
      onClick={() => navigate('/budget-details/averages')}
    />
  );
};

const BudgetDetailsLayout: React.FC<BudgetDetailsLayout> = () => {
  const { meta, setMeta } = useLayoutMeta<BudgetDetailsLayoutMeta>();
  const navigate = useNavigate();
  const { sideNav } = useContentView();

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  const headerVariableControl = {
    home: <HowDoesMyBudgetLookButton />,
    averages: <BudgetDetailsHeader />,
  };

  return (
    <ContentView
      sideNavMenuItems={sideNav('Budget')}
      headerChildren={headerVariableControl[meta.headerVariant]}>
      <Outlet context={{ setMeta }} />
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default BudgetDetailsLayout;
