import { AcceptTermsHandler } from './AcceptTermsHandler';
import { MissingPersonalInfoHandler } from './MissingPersonalInfoHandler';
import { RedirectContext, RedirectHandler } from './RedirectHandler';
import { Scopes, createServiceModule, injectArray } from '@aesop-fables/containr';
import { RedirectServices } from './Types';
import { Api, Data, Domain } from '@3nickels/data-modules';
import { Observable, combineLatest, firstValueFrom, map } from 'rxjs';
import { DataCache, injectDataCache, injectSubject, ISubject } from '@aesop-fables/scrinium';
import { namespacedService } from '../utils';
import { logger } from '../Logging';
import { PlaidIncompleteHandler } from './PlaidIncompleteHandler';

class RedirectContextSubject implements ISubject<RedirectContext> {
  constructor(
    @injectDataCache(Data.People.personStorageKey) private readonly personCache: DataCache<Data.People.PersonCompartments>,
    @injectDataCache(Data.Users.userStorageKey) private readonly userCache: DataCache<Data.Users.UserCompartments>
  ) {}
  
  createObservable(): Observable<RedirectContext> {
    return combineLatest([this.personCache.observe$<Api.PersonRest>('person'), this.personCache.observe$<Api.AccountRest>('account'), this.userCache.observe$<Domain.UserConfig>('userConfig')]).pipe(
      map(([person, account, userConfig]) => {
        const context: RedirectContext = {
          accountId: account?.id,
          personId: person?.id,
          birthDate: person?.birthDate,
          firstName: person?.firstName,
          lastName: person?.lastName,
          requireToAcceptTerms: userConfig?.requireToAcceptTerms,
        };

        return context;
      }),
    );
  }
}

export const useRedirectHandlers = createServiceModule(
  namespacedService('redirects'),
  (services) => {
    services.autoResolve(RedirectServices.RedirectContext, RedirectContextSubject, Scopes.Transient);
    services.autoResolve(RedirectServices.RedirectService, RedirectService, Scopes.Transient);
    services.arrayAutoResolve(RedirectServices.AdviceHandlers, MissingPersonalInfoHandler);
    services.arrayAutoResolve(RedirectServices.AdviceHandlers, AcceptTermsHandler);
    services.arrayAutoResolve(RedirectServices.AdviceHandlers, PlaidIncompleteHandler);
    services.arrayAutoResolve(RedirectServices.FreeHandlers, MissingPersonalInfoHandler);
    services.arrayAutoResolve(RedirectServices.FreeHandlers, AcceptTermsHandler);
  }
);

export interface IRedirectService {
  redirect(): Promise<Response>;
}

export class RedirectService implements IRedirectService {
  constructor(
    @injectArray(RedirectServices.AdviceHandlers)
    private readonly adviceHandlers: RedirectHandler[],
    @injectArray(RedirectServices.FreeHandlers) private readonly freeHandlers: RedirectHandler[],
    @injectSubject(RedirectServices.RedirectContext)
    private readonly context$: Observable<RedirectContext>,
  ) {}

  async redirect(): Promise<Response> {
    const context = await firstValueFrom(this.context$);

    const handlers =
      context.productType === Domain.ProductTypeEnum.advice ? this.adviceHandlers : this.freeHandlers;

    logger.debug('[redirect] searching for handler', context);
    let handler = undefined;
    for (let i = 0; i < handlers.length; i++) {
      const h = handlers[i];
      const matches = await h.matches(context);
      if (matches) {
        handler = h;
        break;
      }
    }
    let response: Response | undefined;
    if (handler) {
      logger.debug('[redirect] handler found', handler);
      response = handler.handle(context);
    } else {
      logger.debug('[redirect]', handler);
    }

    return (
      response ??
      new Response(JSON.stringify({}), {
        status: 200,
        headers: {},
      })
    );
  }
}
