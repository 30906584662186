import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useService } from '@aesop-fables/containr-react';
import { Domain, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { PaymentApi, PaymentMethodsData } from '../../../api/apis/PaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { usePaymentMethods } from '../../../hooks/payment/usePaymentMethods';
import { useDefaultPaymentMethod } from '../../../hooks/payment/useDefaultPaymentMethod';
import { usePaymentHistory } from '../../../hooks/payment/usePaymentHistory';
import { usePaymentMutations } from '../../../hooks/useMutations/usePaymentMutations';
import { useAvailableProducts } from '../../../hooks/pricebook/useAvailableProducts';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import PaymentMethodsSection from './PaymentMethodsSection';
import PaymentHistorySection from './PaymentHistorySection';
import SubscriptionSection from './SubscriptionSection';

const ManagePaymentsView: React.FC = () => {
  const { t } = useTranslation();
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const paymentMethodsData = usePaymentMethods();
  const defaultPaymentMethod = useDefaultPaymentMethod();
  const stripeTransactionsData = usePaymentHistory();
  const { threeNickelsProducts } = useAvailableProducts();
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);

  const [activeMethod, setActiveMethod] = useState<PaymentMethodsData>();
  const { setDefaultPaymentMethod, removePaymentMethod } = usePaymentMutations(activeMethod);

  const onAddPayment = async () => {
    const setupIntentUrl = await paymentApi.addPaymentMethod();
    window.location.replace(setupIntentUrl.data);
  };

  const stripeCustomer = subscriptionInfoData?.some(
    (sub) => sub.origin === Domain.PaymentOriginEnum.STRIPE && sub.paidBy === Domain.PaidByEnum.USER
  );

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography mb={4} variant='p30Bold' component='h1' color='primary'>
          {t('ManagePayments')}
        </Typography>
      </Grid>
      <Typography variant='p18Bold' color='secondary'>
        {t('Subscription')}
      </Typography>
      <SubscriptionSection
        subscriptionInfoData={subscriptionInfoData}
        threeNickelsProducts={threeNickelsProducts}
      />
      <Grid container mt={4} flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='p18Bold' color='secondary'>
          {t('PaymentMethods')}
        </Typography>
        {stripeCustomer && (
          <Button
            style={{ minWidth: 'fit-content' }}
            onClick={onAddPayment}
            className='lg'
            color='primary'>
            {t('AddAPaymentMethod')}
          </Button>
        )}
      </Grid>
      <PaymentMethodsSection
        paymentMethodsData={paymentMethodsData}
        defaultPaymentMethod={defaultPaymentMethod}
        setDefaultPaymentMethod={setDefaultPaymentMethod}
        removePaymentMethod={removePaymentMethod}
        setActiveMethod={setActiveMethod}
      />
      <Typography mt={4} variant='p18Bold' color='secondary'>
        {t('PaymentHistory')}
      </Typography>
      <PaymentHistorySection
        stripeTransactionsData={stripeTransactionsData}
        subscriptionInfoData={subscriptionInfoData}
      />
    </Box>
  );
};

const meta = {
  showBack: true,
  onBack: (navigate) => navigate('/settings'),
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(ManagePaymentsView, meta);
