import { AppGateway } from './routes';
import { LoadingProvider } from './hooks/useLoading';
import { MessageProvider } from './hooks/useMessage';
import { AnimationProvider } from './hooks/useControlledAnimation';
import { AppStateProvider } from './AppState';

export const mobileMargin = '15px';

function App() {
  return (
    <LoadingProvider>
      <MessageProvider>
        <AnimationProvider>
          <AppStateProvider>
            <AppGateway />
          </AppStateProvider>
        </AnimationProvider>
      </MessageProvider>
    </LoadingProvider>
  );
}

export default App;
